import { Routes, Route } from 'react-router-dom';
import awsExport from './aws-exports'
import { Amplify, Auth, API } from 'aws-amplify';
import './styles/custom.css'
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from 'react-toastify';

import AdminLayout from './layouts/adminLayout';
import Login from './pages/auth/login';
import Dashboard from './pages/dashboard';
import ActivityFeed from "./pages/activityFeed";
import Schools from "./pages/schools";
import Users from "./pages/users";
import SchoolInfo from "./pages/schools/schoolInfo";
import EditPost from "./pages/activityFeed/editActivityFeed";
import TeamDetails from "./pages/teams/teamDetails";
import SchoolRequests from "./pages/schools/schoolRequests";
import Challenges from "./pages/challenges";
import AddChallenge from "./pages/challenges/addChallenge";
import Campaigns from "./pages/campaigns";
import PitchedCampaigns from "./pages/campaigns/pitchedCampaigns";
import PointsManagement from "./pages/pointsManagement"
import { AuthContext } from './contexts/authContext';
import { useContext } from 'react';
import Teams from './pages/teams';
import TeamRequestDetails from './pages/teams/teamRequestDetails';
import ForgotPassword from './pages/auth/forgotPassword';
import CampaignInfo from './pages/campaigns/campaignInfo';
import Shop from './pages/shop';
import ChallengeInfoPage from './pages/challenges/challengeInfoPage';
import ConfirmUser from './pages/auth/confirmUser';

Amplify.configure(awsExport)
Auth.configure(awsExport)



function App() {


  return (<>
    <Routes>
      <Route exact path='/' element={<Login />}></Route>
      <Route path='/confirm_user' element={<ConfirmUser />}></Route>
      <Route path='/forgot_password' element={<ForgotPassword />}></Route>
      <Route element={<AdminLayout />}>
        <Route path='/dashboard' element={<Dashboard />}></Route>
        <Route path='/activity-feed' element={<ActivityFeed />}></Route>
        <Route path='/activity-feed/edit-post/:id' element={<EditPost />}></Route>
        <Route path='/schools' element={<Schools />}></Route>
        <Route path='/schools/:id' element={<SchoolInfo />}></Route>
        <Route path='/schools/requests' element={<SchoolRequests />}></Route>
        <Route path='/teams' element={<Teams />}></Route>
        <Route path='/teams/requests/:id' element={<TeamRequestDetails />}></Route>
        <Route path='/team/:id' element={<TeamDetails />}></Route>
        <Route path='/users' element={<Users />}></Route>
        <Route path='/challenges' element={<Challenges />}></Route>
        <Route path='/challenges/:id' element={<ChallengeInfoPage />}></Route>
        <Route path='/challenges/add-challenge' element={<AddChallenge />}></Route>
        <Route path='/campaigns' element={<Campaigns />}></Route>
        <Route path='/campaign_info/:id' element={<CampaignInfo />}></Route>
        <Route path='/campaigns/:id' element={<PitchedCampaigns />}></Route>
        <Route path='/points' element={<PointsManagement />}></Route>
        <Route path='/shop' element={<Shop />}></Route>
        <Route path='/reviews' element={<div>Reviews</div>}></Route>
      </Route>
    </Routes >
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
  </>



  );
}

export default App;
