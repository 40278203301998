import React from 'react'

const CustomCarousel = ({ item }) => {

    return (
        <div className='carousel_main '>
            <div id={'a' + item.id} className="carousel carousel-dark slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    {JSON.parse(item.media)?.map((e, i) => {
                        return (<button type="button" data-bs-target={`#a${item.id}`} data-bs-slide-to={i} className={`${!i ? 'active' : ''}`} aria-current="true" aria-label="Slide 1"></button>)
                    })}
                   
                </div>
                <div className="carousel-inner">
                    {JSON.parse(item.media)?.map((e, i) => {
                        return (
                        <div className={`carousel-item ${!i ? 'active' : ''}`} >
                            {e.image && <img src={e.image} className="d-block w-100 rounded-4 carousel_image" alt="..." />}
                            <div className=" d-block p-2">
                                {/* <h5>{e.question}</h5> */}
                                <p>{e.description || e.content}</p>
                            </div>
                        </div>)
                    })}
                  
                </div>
            </div>
            <div>
                <button className="carousel-control-prev" type="button" data-bs-target={`#a${item.id}`} data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target={`#a${item.id}`} data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    )
}

export default CustomCarousel
