import React, { useContext, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Sidebar from '../components/sidebar'
import { AuthContext } from '../contexts/authContext'
import { Auth, API } from 'aws-amplify'
import { getUser } from '../graphql/queries'


const AdminLayout = () => {
    const { user, handleUser } = useContext(AuthContext);
    const navigate = useNavigate()

    useEffect(() => {
        checkUser()
    }, [])

    const checkUser = async () => {
        if (!user.role) {
            let role = await getUserGroup()
            if (role === 'ADMIN') {
                handleUser({ 'role': role })
            } else {
                navigate('/')
            }
        }
    }

    async function getUserGroup() {
        try {
            const user = await Auth.currentAuthenticatedUser();
            // const session = user.getSignInUserSession();
            // const accessToken = session.getAccessToken();
            // const group = accessToken.payload['cognito:groups'][0];
            // console.log('user', user.username)
            // return group
            handleUser({ id: user.username })
            return getUserRole(user.username)
        } catch (error) {
            console.log('Error getting user group: ', error);
        }

    }

    const getUserRole = async (id) => {
        try {
            let res = await API.graphql({
                query: getUser,
                variables: {
                    id
                }
            })

            return res?.data?.getUser?.role[0]
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Sidebar />
            <div className='main-content' >
                <Outlet />
            </div>
        </>
    )
}

export default AdminLayout
