export let SchoolsTableData =
    [
        {
            id: 1, schoolname: 'Knowledgeum Academy ', address: '18th Km National Park Road Bangalore Karnataka, 560083', groups: 5, children: 500, status: true
        },
        { id: 2, schoolname: 'Neetable', address: 'Banglore,Karnataka', groups: 5, children: 500, status: false },
        { id: 3, schoolname: 'Scrappy', address: 'Banglore,Karnataka', groups: 5, children: 500, status: true },
        { id: 4, schoolname: 'Scrappy', address: 'Banglore,Karnataka', groups: 5, children: 500, status: true },
        { id: 5, schoolname: 'Hyderabad Public School', address: 'Hyderabad,Telangana', groups: 5, children: 500, status: true },
        { id: 6, schoolname: 'Scrappy', address: 'Banglore,Karnataka', groups: 5, children: 500, status: true },
        { id: 7, schoolname: 'Scrappy', address: 'Banglore,Karnataka', groups: 5, children: 500, status: true },
        { id: 8, schoolname: 'Scrappy', address: 'Banglore,Karnataka', groups: 5, children: 500, status: false },
        { id: 9, schoolname: 'Scrappy', address: 'Banglore,Karnataka', groups: 5, children: 500, status: true },
        { id: 10, schoolname: 'Scrappy', address: 'Banglore,Karnataka', groups: 5, children: 500, status: true },
        { id: 11, schoolname: 'Scrappy', address: 'Banglore,Karnataka', groups: 5, children: 500, status: true },
        { id: 12, schoolname: 'Scrappy', address: 'Banglore,Karnataka', groups: 5, children: 500, status: true },
        { id: 13, schoolname: 'Some Public School', address: 'hyderabad,Telangana', groups: 5, children: 500, status: true },
        { id: 14, schoolname: 'Scrappy', address: 'Banglore,Karnataka', groups: 5, children: 500, status: true },
        { id: 15, schoolname: 'Scrappy', address: 'Banglore,Karnataka', groups: 5, children: 500, status: true },
        { id: 16, schoolname: 'Scrappy', address: 'Banglore,Karnataka', groups: 5, children: 500, status: true },
        { id: 17, schoolname: 'Scrappy', address: 'Banglore,Karnataka', groups: 5, children: 500, status: true },
        { id: 18, schoolname: 'Scrappy', address: 'Banglore,Karnataka', groups: 5, children: 500, status: true },
        { id: 19, schoolname: 'Scrappy', address: 'Banglore,Karnataka', groups: 5, children: 500, status: true },
        { id: 20, schoolname: 'Scrappy', address: 'Banglore,Karnataka', groups: 5, children: 500, status: true },
    ]