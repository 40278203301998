import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Pagination from '../../components/pagination';
import { API } from 'aws-amplify';
import { listChallengeActivities, listGroupMembers } from '../../graphql/queries';
import { customListChallengeActivities } from '../../customGraphql/queries';
import { createTransaction, updateChallengeActivity } from '../../graphql/mutations';
import { toast } from 'react-toastify';
import { handleGetGroupMembers } from '../../helpers/index';

const ChallengesSubmittedTable = () => {
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const navigate = useNavigate();
    const [apiData, setApiData] = useState([]);



    useEffect(() => {
        handleSearchResult(apiData)
    }, [searchInput, apiData])

    const handleSearchResult = (data) => {
        let result = data
        // .filter(item =>
        //     item.name.toLowerCase().includes(searchInput))
        // ||
        // item.address.toLowerCase().includes(searchInput))
        setFilteredData(result)

    }

    const handleUpdateActivity = async (challenge) => {
       await handleGetGroupMembers(challenge);
       await getChallengeActivities();
    }

    const handleSearchInput = ({ target }) => {
        setSearchInput(target.value.toLowerCase())
    }

    const getChallengeActivities = async () => {
        try {
            let res = await API.graphql({
                query: customListChallengeActivities,
                variables: {
                    filter: { status: { eq: 'SUBMITTED' } }
                }
            })
            for (let activity of res?.data?.listChallengeActivities?.items) {
                if (!activity.challenge) activity.challenge = {}
                activity.challenge.name = activity?.challenge?.name ? JSON.parse(activity?.challenge?.name) : "";

            }

            setApiData(res?.data?.listChallengeActivities?.items)
            handleSearchResult(res?.data?.listChallengeActivities?.items)
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        getChallengeActivities()
    }, [displayData])


    return (
        <div>
            <div className="card" id="contactsList">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <form>
                                <div className="input-group input-group-flush input-group-merge input-group-reverse">
                                    <input className="form-control list-search" type="search" placeholder="Search" onChange={handleSearchInput} />
                                    <span className="input-group-text">
                                        <i className="fe fe-search"></i>
                                    </span>
                                </div>
                            </form>
                        </div>
                        <div className="col-auto">
                            <div className="dropdown">
                                <button className="btn btn-sm btn-white" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
                                    <i className="fe fe-sliders me-1"></i> Filter <span className="badge bg-primary ms-1 d-none">0</span>
                                </button>
                                <form className="dropdown-menu dropdown-menu-end dropdown-menu-card">
                                    <div className="card-header">
                                        <h4 className="card-header-title">
                                            Filters
                                        </h4>
                                        <button className="btn btn-sm btn-link text-reset d-none" type="reset">
                                            <small>Clear filters</small>
                                        </button>
                                    </div>
                                    <div className="card-body">
                                        <div className="list-group list-group-flush mt-n4 mb-4">
                                            <div className="list-group-item">
                                                <div className="row">
                                                    <div className="col">
                                                        <small>Title</small>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="choices" data-type="select-one" tabIndex="0" role="listbox" aria-haspopup="true" aria-expanded="false">
                                                            <select className="form-select form-select-sm form-control" name="item-title" data-choices="{&quot;searchEnabled&quot;: false}" hidden="" tabIndex="-1" data-choice="active">
                                                                <option value="*" data-custom-properties="[object Object]">Any</option>
                                                                <option value="*" data-custom-properties="[object Object]">Designer</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="list-group-item">
                                                <div className="row">
                                                    <div className="col">
                                                        <small>Lead scrore</small>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="choices" data-type="select-one" tabIndex="0" role="listbox" aria-haspopup="true" aria-expanded="false">
                                                            <select className="form-select form-select-sm form-control" name="item-title" data-choices="{&quot;searchEnabled&quot;: false}" hidden="" tabIndex="-1" data-choice="active">
                                                                <option value="*" data-custom-properties="[object Object]">Any</option>
                                                                <option value="*" data-custom-properties="[object Object]">Designer</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="btn w-100 btn-primary" type="submit">
                                            Apply filter
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-sm table-hover card-table">
                        <thead >
                            <tr className='align-middle text-center'>
                                <th >
                                    <div className="text-muted">Challenge Name</div>
                                </th>
                                <th >
                                    <div className="text-muted ">Group Name</div>
                                </th>
                                <th>
                                    <div className="text-muted" >Points</div>
                                </th>
                                <th>
                                    <div className="text-muted" >View</div>
                                </th>
                                <th>
                                    <div className="text-muted" >Approve</div>
                                </th>

                            </tr>
                        </thead>
                        <tbody className="list fs-base ">
                            {displayData.length ?
                                displayData.map((challenge) => {
                                    return (
                                        <tr className='cursor-pointer' key={challenge.id}>
                                            <td className='table-col-fixed-width '>
                                                <div className='text-center '>{challenge?.challenge?.name?.name}</div>
                                            </td>
                                            <td className='table-col-fixed-width'>
                                                <div className='text-center'>
                                                    {challenge?.group?.name}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-center'>
                                                    {challenge?.challenge?.point}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="text-center"  >
                                                    <i className='fe fe-eye' onClick={() => { navigate(`/challenges/${challenge?.id}`) }}></i>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-center'>
                                                    <button className="btn bg-danger-soft btn-sm" onClick={() => { handleUpdateActivity(challenge) }}>
                                                        Approve
                                                    </button>
                                                </div>
                                            </td>

                                        </tr>
                                    )
                                }) :
                                (<tr className=' text-center text-muted'>
                                    <td colSpan={'7'}>
                                        <h3 className='m-0'>
                                            No data found
                                        </h3>
                                    </td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div>
                <Pagination PageData={filteredData} setDisplayData={setDisplayData} />
            </div>
        </div>
    )
}

export default ChallengesSubmittedTable
