import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import UserTable from './userTable'
import AddUser from './addUser';

const Users = () => {
    const [show, setShow] = useState(false);


    const handleClose = () => { setShow(false); }
    const handleShow = () => setShow(true);

    return (
        <div className='container-fluid'>
            <div className='header '>
                <div className='header-body'>
                    <div className='row'>
                        <div className='col'>
                            <h2 className='header-title'>
                                Admin Users
                            </h2>
                        </div>
                        <div className='col-auto'>
                            <button className='btn btn-primary btn-sm' onClick={handleShow}>Invite User</button>

                        </div>
                    </div>
                </div>
            </div>
            <div>
                <UserTable />
            </div>
            <Modal show={show} onHide={handleClose} >
                <Modal.Header>
                    <h2 className='m-0'>Add User</h2>
                </Modal.Header>
                <Modal.Body>
                    <AddUser setShow={setShow} />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Users
