import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "aws-amplify";
import { listStudents } from '../../graphql/queries'
import { customListGroupsWithInvites } from "../../customGraphql/queries";
import { deleteGroup, updateGroup, deleteGroupInvite, createGroupMember, updateStudent } from "../../graphql/mutations";
import { getAvatarUrl } from "../../helpers";
import { toast } from 'react-toastify';



const TeamRequestDetails = () => {
    const [apiData, setApiData] = useState([]);
    const { id } = useParams()
    const navigate = useNavigate()

    const getRequestData = async () => {
        try {
            let response = await API.graphql({
                query: customListGroupsWithInvites,
                variables: {
                    filter: { and: { groupOwnerId: { eq: id }, approved: { eq: false } } }
                }
            })
          
            setApiData(response?.data?.listGroups?.items)

        } catch (error) {
            console.log(error)
        }
    }

    const handleUpadateGroup = (team) => {
        // if (team.invites.items) {
        //     team.invites.items.forEach((invite) => {
        //         sendInvite(invite.id)
        //     })
        //     handleAproveGroupRequest(team.id)
        // } else {
        //     handleAproveGroupRequest(team.id)
        // }
        handleAproveGroup(team)
    }

    const handleDeleteGroupInfo = (team) => {
        if (team?.invites?.items) {
            team?.invites?.items?.forEach((invite) => {
                handleDeleteInvite(invite?.id)
            })
            handleDeleteGroup(team?.id)

        } else {
            handleDeleteGroup(team?.id)
        }
    }

    const handleDeleteInvite = async (id) => {
        try {
            await API.graphql({
                query: deleteGroupInvite,
                variables: {
                    input: {
                        id: id
                    }
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    const handleAproveGroup = async (team) => {
        try {
            await API.graphql({
                query: updateGroup,
                variables: {
                    input: {
                        id: team.id,
                        approved: true
                    }
                }
            })
            toast.success('Group Approved', {
                position: toast.POSITION.TOP_RIGHT
            }
            )
            handleGetStudentIdbyUser(team);
            getRequestData()

        } catch (error) {
            console.log(error)
        }
    }

    const handleGetStudentIdbyUser = async (team) => {
        try {
            let res = await API.graphql({
                query: listStudents,
                variables: {
                    filter: { studentUserId: { eq: team.groupOwnerId } }
                }
            })
            handleGroupMembers(team?.id, res?.data?.listStudents?.items[0]?.id)
        } catch (error) {
            console.log(error)
        }
    }

    const handleGroupMembers = async (id, studentId) => {
        try {
            await API.graphql({
                query: createGroupMember,
                variables: {
                    input: {
                        groupId: id,
                        studentId: studentId,
                    }
                }
            })
            handleUpdateStudent(studentId)
        } catch (error) {
            console.log(error)
        }
    }

    const handleUpdateStudent = async (studentId) => {
        try {
            await API.graphql({
                query: updateStudent,
                variables: {
                    input: {
                        id: studentId,
                        hasGroup: true
                    }
                }
            })

        } catch (error) {
            console.log(error)
        }
    }

    const handleDeleteGroup = async (id) => {
        try {
            await API.graphql({
                query: deleteGroup,
                variables: {
                    input: {
                        id: id
                    }
                }
            })
            toast.success('Group Deleted', {
                position: toast.POSITION.TOP_RIGHT
            }
            )
            getRequestData()
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getRequestData()
    }, [])

    return (
        <div>
            <div className='header'>
                <div className='container-fluid'>
                    <div className='header-body mb-3'>
                        <div className="row">
                            <div className="col">
                                <h2 className="header-title">
                                    <i className='fe fe-arrow-left fw-bold me-3' onClick={() => { navigate(-1) }}></i>
                                    Team Request
                                </h2>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col">
                                <div className='d-flex justify-content-between'>
                                    <h4 >Details</h4>
                                    {/* <p className='fw-bolder text-primary mb-0' onClick={() => { handleShow() }}><i className='fe fe-edit fw-bolder'></i> Edit</p> */}
                                </div>
                                <div className='card '>
                                    <div className='card-body '>
                                        <div className='row'>
                                            <div className='col'>
                                                <h5>
                                                    Student Created
                                                </h5>
                                                <p className='text-muted'>
                                                    {apiData[0]?.owner?.firstName} {apiData[0]?.owner?.lastName}
                                                </p>
                                            </div>
                                            <div className='col'>
                                                <h5>
                                                    Total Teams Created
                                                </h5>
                                                <p className='text-muted'>
                                                    {apiData?.length}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row row-cols-lg-3 g-2 g-lg-3">
                        {apiData?.map((team) => (
                            <div className="col" key={team.id}>
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-header-title">
                                            {team.name}
                                        </h4>
                                    </div>
                                    {(team?.invites?.items?.length !== 0) &&
                                        <>
                                            <div className="m-2 ms-4">
                                                Students Invited</div>
                                            <div className="card-body ">
                                                <div className="list-group list-group-flush my-n3 scrappy-group-request-body">
                                                    {team?.invites?.items.map((invite) => (
                                                        <div className="list-group-item" key={invite?.student?.id}>
                                                            <div className="row align-items-center">
                                                                <div className="col-auto">
                                                                    <div className="avatar ">
                                                                        <img src={getAvatarUrl(invite?.student?.user?.avatar)} alt="..." className="avatar-img rounded-circle" />
                                                                    </div>
                                                                </div>
                                                                <div className="col ms-n2">
                                                                    <h4 className="mb-1">
                                                                        {invite?.student?.firstName + ' ' + invite?.student?.lastName}
                                                                    </h4>
                                                                    <p className="card-text small text-muted">
                                                                        Some Public School
                                                                    </p>
                                                                </div>

                                                            </div>
                                                        </div>))}
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className='card-footer d-flex justify-content-evenly'>
                                        <div className="cursor-pointer" onClick={() => {
                                            handleUpadateGroup(team)
                                        }}>Accept</div>
                                        <div className="cursor-pointer" onClick={() => {
                                            handleDeleteGroupInfo(team)
                                        }}>Reject</div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
            <div>

            </div>
        </div >
    )
}

export default TeamRequestDetails