import React, { useEffect } from 'react'
import { Formik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { useState } from 'react';
import { API } from 'aws-amplify';
import { listSchools, searchSchools } from '../../graphql/queries';
import { Auth } from 'aws-amplify';
import { createSchoolUser, createUser } from '../../graphql/mutations';
import { toast } from 'react-toastify';

const AddUser = ({ setShow }) => {

    const [schoolOptions, setSchoolOptions] = useState([]);

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        role: '',
        schools: []
    });

    const formSchema = Yup.object().shape({
        first_name: Yup.string().required('Required'),
        last_name: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email').required('Required'),
        phone: Yup.string().min(10, "Phone number is not valid").max(10, "Phone number is not valid").required('Required'),
        role: Yup.string().required('Required'),
    })

    const handleListSchools = async () => {
        try {
            let res = await API.graphql({
                query: listSchools,
                variables: {
                    filter: { approved: { eq: true } }
                }
            })
            console.log('res', res.data.listSchools.items)
            let options = res?.data?.listSchools?.items.map((school) => ({ value: school?.id, label: school?.name }));
            setSchoolOptions(options)
        } catch (error) {
            console.log(error)
        }
    }

    const handleSignUp = async (form) => {
        try {
            const attributes = {
                email: form.email,
                given_name: form.first_name,
                family_name: form.last_name
            };
            const result = await Auth.signUp({
                username: form.email,
                password: '1234@Neetable',
                attributes: attributes
            });
            console.log(result);
            handleCreateUser(result.userSub, form)
        } catch (error) {
            toast.error('An account with the given email already exists')
            console.log('err', error);
        }
    }

    const handleCreateUser = async (id, form) => {
        try {
            let res = await API.graphql({
                query: createUser,
                variables: {
                    input: {
                        firstName: form.first_name,
                        lastName: form.last_name,
                        id: id,
                        phone: `+91${form.phone}`,
                        role: form.role === 'ADMIN' ? 'ADMIN' : null
                    }
                }
            })
            console.log('User', res)
            console.log('test', form.schools)
            if (form.role === 'SCHOOL_ADMIN') {
                form.schools.forEach((school) => {
                    handleCreateSchoolUser(id, form, school.value)
                })
            }
            setShow(false)

        } catch (error) {
            console.log('err', error);
        }
    }

    const handleCreateSchoolUser = async (id, form, school) => {
        try {
            await API.graphql({
                query: createSchoolUser,
                variables: {
                    input: {
                        userId: id,
                        firstName: form.first_name,
                        lastName: form.last_name,
                        role: 'ADMIN',
                        schoolId: school

                    }
                }
            })

        } catch (error) {
            console.log('err', error);
        }
    }


    const handleformData = (form) => {
        // setFormData((prev) => ({ ...form, schools: form.schools.map((school) => (school.value)) }))

        handleSignUp(form)
    }


    useEffect(() => {
        handleListSchools()
    }, [])


    return (
        <div>
            <Formik initialValues={formData} validationSchema={formSchema} onSubmit={handleformData}>
                {({ setFieldValue, handleChange, handleSubmit, values, errors }) => (
                    <form onSubmit={handleSubmit}>
                        <div className='row mb-3 form-group'>
                            <div className='col'>
                                <label htmlFor='first_name' className='form-label'>First Name</label>
                                <input type={'text'} className='form-control' id='first_name' value={values.first_name} onChange={handleChange} />
                                <span className='text-danger'>{errors.first_name}</span>
                            </div>
                            <div className='col'>
                                <label htmlFor='last_name' className='form-label'>Last Name</label>
                                <input type={'text'} className='form-control' id='last_name' value={values.last_name} onChange={handleChange} />
                                <span className='text-danger'>{errors.first_name}</span>
                            </div>
                        </div>
                        <div className='mb-3 form-group'>
                            <label htmlFor='email' className='form-label'>Email address</label>
                            <input type={'text'} className='form-control' id='email' name={'email'} value={values.email} onChange={handleChange} />
                            <span className='text-danger'>{errors.email}</span>
                        </div>
                        <div className='mb-3 form-group'>
                            <label htmlFor='phone' className='form-label'>Phone Number</label>
                            <input type={'number'} className='form-control' id='phone' name={'phone'} value={values.phone} onChange={handleChange} />
                            <span className='text-danger'>{errors.phone}</span>
                        </div>
                        <div className='mb-3 form-group'>
                            <label htmlFor='role' className='form-label'>Role</label>
                            <select className='form-control' as='select' name='role' value={values.role} onChange={handleChange}>
                                <option>Select Role</option>
                                <option value='ADMIN'>Admin</option>
                                <option value='SCHOOL_ADMIN'>School Admin</option>
                            </select>
                        </div>
                        {values.role === 'SCHOOL_ADMIN' &&
                            <div className='col'>
                                <label htmlFor='medium' className='form-label'>Schools <span className='text-danger'>*</span></label>
                                <Select
                                    isMulti
                                    id='schools'
                                    options={schoolOptions}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(e) => { setFieldValue('schools', e) }}
                                />
                                <span className='text-danger'>{errors.medium}</span>
                            </div>}
                        <div className='mt-5'>
                            <div className='d-flex justify-content-end gap-3'>
                                <button type='button' className='btn btn-white' onClick={() => setShow(false)}>Cancel</button>
                                <button type='submit' className='btn btn-primary' >Submit</button>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default AddUser
