import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DropdownCard from './DropdownCard'

const CampaignInfo = () => {

    const navigate = useNavigate()

    return (
        <div className='container-fluid pb-5'>
            <div className="header">
                <div className="header-body d-flex align-items-center">
                    <div>
                        <h2 className='m-0'>
                            <i className='fe fe-arrow-left fw-bold me-3' onClick={() => { navigate(-1) }}></i>
                        </h2>
                    </div>
                    <div className='ms-3'>
                        <h6 className="header-pretitle">
                            Campaign
                        </h6>
                        <h3 className="header-title">
                            Campaign Name
                        </h3>
                    </div>
                </div>
            </div>
            <div>
                <DropdownCard heading='Scrappy POA'>
                    <div>
                        <div className='card'>
                            <div className="card-header">
                                <p className='m-0'>Please provide some photos of the map & the problem</p>
                            </div>
                            <div className=' card-body d-flex '>
                                <div className='me-5'>
                                    <div>
                                        <img src='https://via.placeholder.com/200 ' />
                                    </div>
                                    <div className='text-center'>
                                        <p>Photo of map</p>
                                    </div>
                                </div>
                                <div >
                                    <div>
                                        <img src='https://via.placeholder.com/200 ' />
                                    </div>
                                    <div className='text-center'>
                                        <p>Photo of the place</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card'>
                            <div className='card-header'>
                                <p className='m-0'>Time it takes to walk to the location (from your place)?</p>
                            </div>
                            <div className='card-body'>
                                <p className=''>Some Answer</p>
                            </div>
                        </div>
                        <div className='card'>
                            <div className='card-header'>
                                <p className='m-0'>Upload well labeled photos of the problem</p>
                            </div>
                            <div className='card-body row row-cols-lg-3 g-lg-3 text-center'>
                                <div className='col p-3'>
                                    <div>
                                        <img src='https://via.placeholder.com/200 ' />
                                    </div>
                                    <div>
                                        <p>Explaination Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque enim culpa repellat, pariatur, eum reprehenderit neque vero eaque, voluptas accusamus minima molestiae.</p>
                                    </div>
                                </div>
                                <div className='col p-3'>
                                    <div>
                                        <img src='https://via.placeholder.com/200 ' />
                                    </div>
                                    <div>
                                        <p>Explaination</p>
                                    </div>
                                </div><div className='col p-3'>
                                    <div>
                                        <img src='https://via.placeholder.com/200 ' />
                                    </div>
                                    <div>
                                        <p>Explaination</p>
                                    </div>
                                </div><div className='col p-3'>
                                    <div>
                                        <img src='https://via.placeholder.com/200 ' />
                                    </div>
                                    <div>
                                        <p>Explaination</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card'>
                            <div className="card-header">
                                <p className='m-0'>What is the agenda of your meeting?</p>
                            </div>
                            <div className="card-body">
                                <p>1.Sentence one</p>
                                <p>2.Sentence two</p>
                                <p>3.Sentence three</p>
                            </div>
                        </div>
                        <div className='card'>
                            <div className="card-header">
                                <p className='m-0'>What is the goal from your first meeting?</p>
                            </div>
                            <div className="card-body">
                                <p>Answer</p>
                            </div>
                        </div>
                        <div className='card'>
                            <div className="card-header">
                                <p>Propose a date and time for the meeting</p>
                            </div>
                            <div className="card-body">
                                <p>10-03-2023 12:15</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className='list-group list-group-flush'>
                        {[1, 2, 3, 4, 5, 6].map((e) => (
                            <div className='list-group-item'>
                                <p className='text-muted'>Some question?</p>
                                <p className='mb-0'>Some Answer</p>
                            </div>
                        ))}
                    </div> */}
                </DropdownCard>
                <DropdownCard heading='Roles Assigned'>
                    <div className='list-group list-group-flush w-75 mx-auto'>
                        {[1, 2, 3, 4, 5, 6].map((e) => (
                            <div className='list-group-item '>
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <a href="profile-posts.html" className="avatar">
                                            <img src="https://dashkit.goodthemes.co/assets/img/avatars/profiles/avatar-1.jpg" alt="..." className="avatar-img rounded-circle" />
                                        </a>
                                    </div>
                                    <div className="col ms-n2">
                                        <h4 >
                                            Dianna Smiley
                                        </h4>
                                    </div>
                                    <div className="col-auto me-3">
                                        <p>Admin</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </DropdownCard>
                <DropdownCard heading={'Meeting Minutes'}>
                    <div>
                        <DropdownCard heading={'Meeting Minutes - 1'}>
                            <div>
                                <p className='text-muted'>Tell us the details of your meeting</p>
                                <p className='fs-3'>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
                                <hr />
                            </div>
                            <div >
                                <p className='text-muted'>This is what we talked about</p>
                                <div>
                                    <img src='https://via.placeholder.com/200 ' />
                                    <p className='fs-3 py-3'>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
                                </div>
                                <hr />
                            </div>

                            <div >
                                <p className='text-muted'>What are your next three steps?</p>
                                <p className='fs-3'>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
                                <hr />
                            </div>

                            <div >
                                <p className='text-muted'>How will you know you have achieved your goal</p>
                                <p className='fs-3'>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
                                <hr />
                            </div>

                            <div >
                                <p className='text-muted'>We will document our campaign by uploading</p>
                                <p className='fs-3'>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
                                <hr />
                            </div>

                            <div>
                                <p className='text-muted'>Tell us about your next meeting (if planned)</p>
                                <p className='fs-3'>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
                            </div>
                        </DropdownCard>
                    </div>
                </DropdownCard>
                <DropdownCard heading={'Updates'}>
                    <DropdownCard heading={'Update-4'}>
                        <div>
                            <p className='text-muted'>Scrappy Campaign Actioned On?</p>
                            <p className='fs-3'>10-03-2023 12:15</p>
                            <hr />
                        </div>
                        <div>
                            <p className='text-muted'>Upload photos of players and teams present there</p>
                            <div>
                                <img src='https://via.placeholder.com/200 ' />
                            </div>
                            <hr />
                        </div>
                        <div>
                            <p className='text-muted'>The steps we took towards solving the problem</p>
                            <p className='fs-3'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Rerum recusandae, nulla accusantium, neque, </p>
                            <hr />
                        </div>
                        <div>
                            <p className='text-muted'>Please provide some photos of the map & the problem</p>
                            <div className='me-5'>
                                <img src='https://via.placeholder.com/200 ' />
                                <p className='fs-3 py-3'>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
                            </div>
                            <hr />
                        </div>
                        <div>
                            <p className='text-muted'>What we did to solve the not-so-good things that happened</p>
                            <div className='me-5'>
                                <img src='https://via.placeholder.com/200 ' />
                                <p className='fs-3 py-3'>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
                            </div>
                        </div>

                    </DropdownCard>

                </DropdownCard>
            </div >
            {/* <DropdownCard heading='Scrappy POA'>
                <div className='list-group list-group-flush'>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => (
                        <div className='list-group-item'>
                            <p className='text-muted'>Some question?</p>
                            <p>Some Answer</p>
                        </div>
                    ))}
                </div>
            </DropdownCard>
            <DropdownCard /> */}
        </div >
    )
}

export default CampaignInfo
