import React, { useEffect, useState } from 'react'
import GroupCard from '../../components/groupCard';
import { API } from 'aws-amplify';
import { listGroupsWithMembers } from '../../customGraphql/queries'
import { useParams } from 'react-router-dom';

const TeamList = ({ dependency }) => {

    const [apiData, setApiData] = useState([]);

    const { id } = useParams();

    let filter = {}
    if (id) {
        filter = { and: { approved: { eq: true }, schoolId: { eq: id } } }
    } else {
        filter = { approved: { eq: true } }
    }

    const getTeamsData = async () => {
        try {
            let response = await API.graphql({
                query: listGroupsWithMembers,
                variables: {
                    filter: filter
                }
            })
            setApiData(response?.data?.listGroups?.items)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getTeamsData()
    }, [dependency])

    return (
        <div>
            <div className='row row-cols-lg-3 g-2 g-lg-3 '>
                {apiData?.map((group) => (
                    <div className="col" key={group.id} >
                        <GroupCard group={group} />
                    </div>))}
            </div>
        </div>
    )
}

export default TeamList
