import { API } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import PublishedCard from '../../components/publishedCard'
import { PostData } from '../../data/postData'
import { listActivityFeeds } from '../../graphql/queries'
import { customApprovedListActivityFeeds } from '../../customGraphql/queries'
import { updateActivityFeed, deleteActivityFeed } from '../../graphql/mutations';
import { createActivityFeedComment } from '../../graphql/mutations'
import { toast } from 'react-toastify';
import { AuthContext } from '../../contexts/authContext'
import { useContext } from "react";


const Published = () => {
    const { user } = useContext(AuthContext);
    const [publishedData, setPublishedData] = useState([]);

    const getActivity = async () => {
        try {
            const data = await API.graphql({
                query: customApprovedListActivityFeeds
            })
            setPublishedData(data.data.listActivityFeeds.items)

        }
        catch (error) {
            console.log(error)
        }
    }

    const handleCreateActivityFeedComment = async (comment, id) => {
        try {
            await API.graphql({
                query: createActivityFeedComment,
                variables: {
                    input: {
                        activityFeedId: id,
                        comment: comment,
                        userId: user.id
                    }
                }
            })
            getActivity()

        } catch (error) {
            console.log(error)
        }
    }

    const deleteActivity = async (id) => {
        try {
            await API.graphql({
                query: deleteActivityFeed,
                variables: {
                    input: {
                        id: id
                    }
                }
            })
            toast.success('Post Deleted')
            getActivity();
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getActivity()
    }, [])

    return (
        <div className='container'>
            {publishedData?.map((item) => {
                return (<PublishedCard item={item} key={item.id} handleCreateActivityFeedComment={handleCreateActivityFeedComment} deleteActivity={deleteActivity} />)
            })}
        </div>
    )
}

export default Published
