import React from 'react'
import { useState, useEffect } from 'react';
import Pagination from '../../components/pagination';
import { useNavigate } from 'react-router-dom';
import { API } from 'aws-amplify';
import { listProducts } from '../../graphql/queries';
import { listProductsWithCategory } from '../../customGraphql/queries'
import { listProductCategories } from '../../graphql/queries';
import { deleteProduct, updateProduct } from '../../graphql/mutations';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Storage } from 'aws-amplify';

const ProductTable = ({ AddModalShow }) => {
    const [searchInput, setSearchInput] = useState('');
    const [displayData, setDisplayData] = useState([]);
    const [options, setOptions] = useState([]);
    const [prevToken, setPrevToken] = useState(null);
    const [nextToken, setNextToken] = useState(null);

    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false) }
    const handleShow = () => setShow(true);

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        id: '',
        name: '',
        categoryId: '',
        image: '',
    });

    const formSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        categoryId: Yup.string().required('Required'),
        image: Yup.string().required('Required')
    })

    const handleListProducts = async (token) => {
        try {
            let res = await API.graphql({
                query: listProductsWithCategory,
                variables: {
                    limit: 2,
                    nextToken: nextToken,
                }
            })
            let next = res?.data?.listProducts?.nextToken
            if (nextToken) {
                setPrevToken(nextToken)
            }
            setNextToken(next)

            setDisplayData(res?.data?.listProducts?.items)
        } catch (error) {
            console.log(error)
        }
    }

    const handleformData = async (form) => {
        if (typeof form.image !== 'string') {
            let image = await handleUploadImage(form.image)
            form.image = image;
        }
        handleUpdateProduct(form);

    }

    const handleUploadImage = async (file) => {
        try {
            const stored = await Storage.put(`Product-${Math.random().toString(36).substring(2, 15)}.${file.name.split('.')[1]}`, file, { contentType: file.type });
            const url = await Storage.get(stored.key, { level: 'public' })
            return url.split('?')[0]
        } catch (error) {
            console.log(error)
        }
    }

    const handleEdit = (product) => {
        handleListProductCategories()
        setFormData((prev) => ({
            name: product.name, image: product.image, categoryId: product.categoryId, id: product.id
        }))
        handleShow()

    }

    const handleUpdateProduct = async (product) => {
        try {
            await API.graphql({
                query: updateProduct,
                variables: {
                    input: {
                        id: product.id,
                        name: product.name,
                        categoryId: product.categoryId,
                        image: product.image
                    }
                }
            })
            toast.success('Product Updated');
            handleClose();
            handleListProducts();

        } catch (error) {
            console.log(error)
        }
    }

    const handleSearchResult = (data) => {
        let result = data
    }

    const handleSearchInput = ({ target }) => {
        setSearchInput(target.value.toLowerCase())
    }

    const handleListProductCategories = async () => {
        try {
            let res = await API.graphql({
                query: listProductCategories,
            })
            let categories = res?.data?.listProductCategories?.items?.map((category) => ({ label: category.name, value: category.id }));
            setOptions(categories)
        } catch (error) {
            console.log(error)
        }
    }

    const handleDeleteProduct = async (id) => {
        try {
            await API.graphql({
                query: deleteProduct,
                variables: {
                    input: {
                        id
                    }
                }
            })
            toast.success("Product Deleted")
            handleListProducts()
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        handleListProducts()
    }, [AddModalShow])

    return (
        <div>
            <div className="card" id="contactsList">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <form>
                                <div className="input-group input-group-flush input-group-merge input-group-reverse">
                                    <input className="form-control list-search" type="search" placeholder="Search" onChange={handleSearchInput} />
                                    <span className="input-group-text">
                                        <i className="fe fe-search"></i>
                                    </span>
                                </div>
                            </form>
                        </div>
                        <div className="col-auto">
                            <div className="dropdown">
                                <button className="btn btn-sm btn-white" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
                                    <i className="fe fe-sliders me-1"></i> Category <span className="badge bg-primary ms-1 d-none">0</span>
                                </button>
                                <form className="dropdown-menu dropdown-menu-end dropdown-menu-card">
                                    <div className="card-header">
                                        <h4 className="card-header-title">
                                            Filters
                                        </h4>
                                        <button className="btn btn-sm btn-link text-reset d-none" type="reset">
                                            <small>Clear filters</small>
                                        </button>
                                    </div>
                                    <div className="card-body">
                                        <div className="list-group list-group-flush mt-n4 mb-4">
                                            <div className="list-group-item">
                                                <div className="col-auto">
                                                    <div className="choices" data-type="select-one" tabIndex="0" role="listbox" aria-haspopup="true" aria-expanded="false">
                                                        <select className="form-select form-select-sm form-control" name="item-title" data-choices="{&quot;searchEnabled&quot;: false}" hidden="" tabIndex="-1" data-choice="active">
                                                            <option value="*" data-custom-properties="[object Object]">Any</option>
                                                            <option value="*" data-custom-properties="[object Object]">Designer</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <button className="btn w-100 btn-primary" type="submit">
                                            Apply filter
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-sm table-hover card-table">
                        <thead >
                            <tr className='align-middle text-center'>
                                <th >
                                    <div className="text-muted">Product Name</div>
                                </th>
                                <th >
                                    <div className="text-muted ">Image</div>
                                </th>
                                <th>
                                    <div className="text-muted" >Category Name</div>
                                </th>
                                <th>
                                    <div className="text-muted" >Actions</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="list fs-base ">
                            {displayData.length ?
                                displayData.map((product) => {
                                    return (
                                        <tr key={product.id}>
                                            <td className='table-col-fixed-width '>
                                                <div className='text-center '>{product?.name}</div>
                                            </td>
                                            <td className='table-col-fixed-width'>
                                                <div className='text-center'>
                                                    <img src={product?.image} width={'50px'} height={'50px'} />
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-center'>
                                                    {product?.category?.name}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-center'>
                                                    <div className="dropdown">
                                                        <div className="dropdown-ellipses dropdown-toggle text-mute" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="fe fe-more-vertical"></i>
                                                        </div>
                                                        <div className="dropdown-menu dropdown-menu-end">
                                                            <div className="dropdown-item" onClick={() => { handleEdit(product) }}>
                                                                Edit
                                                            </div>
                                                            <div className="dropdown-item" onClick={() => {
                                                                handleDeleteProduct(product.id)
                                                            }}>
                                                                Delete
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }) : (
                                    <tr className=' text-center text-muted'>
                                        <td colSpan={'7'}>
                                            <h3 className='m-0'>
                                                No data found
                                            </h3>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
                <div>
                    <div className="card-footer d-flex justify-content-between">

                        <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
                            <li className="page-item cursor-pointer">
                                <button className="page-link ps-0 pe-4 border-end " disabled={prevToken === null} onClick={() => { handleListProducts(prevToken) }}>
                                    <i className="fe fe-arrow-left me-1"></i> Prev
                                </button>
                            </li>
                        </ul>

                        <ul className="list-pagination pagination pagination-tabs card-pagination ">
                            <li className="page-item">
                                <div className="page-link " >

                                </div>
                            </li>
                        </ul>

                        <ul className="list-pagination-next pagination pagination-tabs card-pagination" >
                            <li className="page-item cursor-pointer">
                                <button className="page-link ps-4 pe-0 border-start" disabled={nextToken === null} onClick={() => { handleListProducts(nextToken) }}>
                                    Next <i className="fe fe-arrow-right ms-1"></i>
                                </button>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} >
                <Modal.Header>
                    <h2 className='m-0'>Edit Product</h2>
                </Modal.Header>
                <Modal.Body>
                    <Formik enableReinitialize={true} validationSchema={formSchema} initialValues={formData} onSubmit={handleformData}>
                        {({ handleChange, handleSubmit, values, errors, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor='name' className='form-label'>Product Name <span className='text-danger'>*</span></label>
                                    <input type={'text'} className='form-control' id='name' defaultValue={values.name} onChange={handleChange} />
                                    <span className='text-danger'>{errors.name}</span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor='image' className='form-label'>Product Image</label>
                                    <div className='d-flex justify-content-between p-1 py-2 form-control' style={{ border: 'dashed 1px #D2DDEC' }}>
                                        <div>{values.image.name || 'Upload Image'}</div>
                                        <label className='btn btn-primary btn-sm' htmlFor='image' >
                                            <input type='file' id='image' accept=' image/*' onChange={(e) => { setFieldValue("image", e.currentTarget.files[0]); }} /> Upload
                                        </label>
                                    </div>
                                    <div className='mt-3'>
                                        {values?.image && <img src={(typeof values?.image === 'string') ? values.image : URL.createObjectURL(values?.image)} width={'50px'} height={'50px'} />}
                                    </div>

                                </div>
                                <div className="form-group">
                                    <label htmlFor='categoryId' className='form-label'>Category <span className='text-danger'>*</span></label>
                                    <select className="form-select" as='select' id='categoryId' defaultValue={values.categoryId} onChange={handleChange}>
                                        {options.map((option) => (
                                            <option value={option.value} key={option.value}>{option.label}</option>))
                                        }
                                    </select>
                                    <span className='text-danger'>{errors.stage}</span>
                                </div>
                                <div className='mt-5'>
                                    <div className='d-flex justify-content-end gap-3'>
                                        <button type='button' className='btn btn-white' onClick={() => setShow(false)}>Cancel</button>
                                        <button type='submit' className='btn btn-primary' >Submit</button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </div >
    )
}

export default ProductTable
