import React from 'react'
import { useEffect, useRef } from 'react'
import { Geo } from "aws-amplify"
import LocationSearch from '../../components/locationSearch'
import AutoComplete from '../../components/autoComplete'




const Dashboard = () => {

    return (
        <div className='container-fluid'>
            <div className="header ">
                <div className="header-body">
                    <div className="row">
                        <div className="col">
                            <h2 className="header-title">
                                Dashboard
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard
