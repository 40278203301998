import React from 'react'

const UploadImageAndFieldNotes = () => {
    return (

        <div >
            <div>
                <h4 className='mt-4'>Heading </h4>
            </div>
            <div className="row">
                <div className="col">
                    <div>
                        <textarea className='form-control' rows='2' placeholder='English'></textarea>
                    </div>
                </div>
                <div className="col">
                    <div>
                        <textarea className='form-control' rows='2' placeholder='Hindi'></textarea>
                    </div>
                </div>
                <div className="col">
                    <div>
                        <textarea className='form-control' rows='2' placeholder='Marathi'></textarea>
                    </div>
                </div>
                <div className="col">
                    <div>
                        <textarea className='form-control' rows='2' placeholder='Kannada'></textarea>
                    </div>
                </div>
            </div>
            <div className=' row mt-5'>
                <div className='col d-flex justify-content-evenly'>
                    <div className='primary'>
                        <h3>Upload Images</h3>
                    </div>
                    <div className="form-check form-switch">
                        <input className="form-check-input bg-danger" type="checkbox" role="switch" />
                    </div>
                </div>
                <div className='col d-flex justify-content-evenly'>
                    <div className='primary'>
                        <h3>Upload Filed Notes</h3>
                    </div>
                    <div className="form-check form-switch">
                        <input className="form-check-input bg-danger" type="checkbox" role="switch" />
                    </div>
                </div>
                <div className='col-2 d-flex justify-content-end'>
                    <div className='d-flex justify-content-center align-items-center badge bg-danger-soft w-25 '>
                        <i className='fe fe-trash-2 fs-2 text-danger'></i>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default UploadImageAndFieldNotes
