import React, { useEffect } from 'react'
import SchoolsTable from './schoolsTable'
import { FaChild, FaUserFriends } from 'react-icons/fa'
import { useNavigate } from 'react-router'
import { useState } from 'react'
import { API } from 'aws-amplify';
import Select from 'react-select';
import { Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { listSchools } from '../../graphql/queries'
import LocationSearch from '../../components/locationSearch'
import { createSchool } from '../../graphql/mutations'
import { toast } from 'react-toastify';
import { customListSchoolswithApprovedGroups } from '../../customGraphql/queries';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/authContext';
import { getSchoolsListWithSchoolUser } from '../../helpers/index';

const Schools = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [addressResult, setAddressResult] = useState({});
    const [apiData, setApiData] = useState([]);
    const [count, setCount] = useState({ teams: 0, students: 0 });
    const { user } = useContext(AuthContext);


    const [formData, setFormData] = useState({
        name: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        postal_code: '',
        zone: '',
        medium: '',
        stage: '',
        geo_tagging: '',
        lat_lng: '',
        place_id: ''
    });

    const handleClose = () => { setShow(false) }
    const handleShow = () => setShow(true);

    const handleLocationChange = () => {
        setFormData((prev) => ({
            ...prev,
            name: addressResult.name,
            zone: addressResult.zone,
            city: addressResult.city,
            state: addressResult.state,
            postal_code: addressResult.postal_code,
            address1: addressResult.address1,
            lat_lng: addressResult.lat_lng,
            place_id: addressResult.place_id
        }))

    }

    const getSchools = async () => {
        try {
            let Schools = [];
            if (user.role === 'ADMIN') {
                const data = await API.graphql({
                    query: customListSchoolswithApprovedGroups,
                    variables: { filter: { approved: { eq: true } } }
                })
                Schools = data?.data?.listSchools?.items;
            } else if (user.role === 'SCHOOL_ADMIN') {
                Schools = await getSchoolsListWithSchoolUser(user.id)
            }
            setApiData(Schools)
            let teamCount = 0;
            let studentCount = 0;
            Schools.forEach((school) => {
                teamCount = teamCount + school?.groups?.items?.length
                studentCount = studentCount + school?.students?.items?.length
            })
            setCount((prev) => ({ ...prev, teams: teamCount, students: studentCount }));

        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        handleLocationChange()
    }, [addressResult])

    useEffect(() => {
        // searchSchool()
        getSchools()
    }, [])

    const formSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        address1: Yup.string().required('Required'),
        address2: Yup.string(),
        type: Yup.string().required('Required'),
        city: Yup.string().required('Required'),
        state: Yup.string().required('Required'),
        postal_code: Yup.number().required('Required'),
        zone: Yup.string(),
        medium: Yup.array().required('Required'),
        stage: Yup.string().required('Required'),
        lat_lng: Yup.string(),
        principal_name: Yup.string(),
        principal_phone: Yup.string(),
        conatact_name: Yup.string(),
        contact_phone: Yup.string(),
        school_email: Yup.string()
    })

    const handleformData = (form) => {
        let address = {};
        let primaryContact = {}
        address.address1 = form.address1;
        address.address2 = form.address2;
        address.city = form.city;
        address.state = form.state;
        address.zone = ''
        address.postal_code = form.postal_code;
        address.place_id = form.place_id;
        address.lat_lng = form.lat_lng;
        address = JSON.stringify(address);

        primaryContact.principal_name = form.principal_name;
        primaryContact.principal_phone = form.principal_phone;
        primaryContact.contact_name = form.contact_name;
        primaryContact.contact_phone = form.contact_phone;
        primaryContact.school_email = form.school_email;

        primaryContact = JSON.stringify(primaryContact);
        form.medium = form.medium.map((language) => (language.value));
        addSchool(address, form, primaryContact)
    }

    const addSchool = async (address, form, primaryContact) => {
        try {
            await API.graphql({
                query: createSchool,
                variables: {
                    input: {
                        id: form.place_id,
                        name: form.name,
                        medium: form.medium,
                        type: form.type,
                        stage: form.stage,
                        address,
                        address1: form.address1,
                        address2: form.address2,
                        city: form.city,
                        state: form.state,
                        zone: form.zone,
                        postal_code: form.postal_code,
                        approved: true,
                        primaryContact

                    }
                }
            })
            handleClose()
            toast.success('School Added')
            getSchools()

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className='container-fluid'>
            <div className="header">
                <div className="header-body">
                    <div className="row">
                        <div className="col">
                            <h2 className="header-title">
                                Schools
                            </h2>
                        </div>
                        {user.role === 'ADMIN' &&
                            <div className='col-auto'>
                                <button className='btn btn-primary btn-sm' onClick={handleShow}>Add School</button>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {user.role === 'ADMIN' &&
                <div className='d-flex justify-content-end mb-3'>
                    <button className='btn btn-info' onClick={() => { navigate('/schools/requests') }}>School Requests <i className='fe fe-arrow-right'></i></button>
                </div>
            }
            <div className="d-flex row row-cols-1 row-cols-sm-2 row-cols-md-3 " >
                <div className="col">
                    <div className="card school-card" >
                        <div className="card-body">
                            <div className="row align-items-center">
                                <div className="col">
                                    {/* <  FaUserFriends className='fs-1 ' /> */}
                                    <img src='/img/school_icon.svg' />
                                </div>
                                <div className="col-auto">
                                    <h6 className="text-uppercase text-muted mb-2">
                                        Schools
                                    </h6>
                                    <span className="h2">
                                        {apiData?.length}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card school-card">
                        <div className="card-body">
                            <div className="row align-items-center">
                                <div className="col">
                                    {/* <  FaUserFriends className='fs-1' /> */}
                                    <img src='/img/group_icon.svg' />
                                </div>
                                <div className="col-auto">
                                    <h6 className="text-uppercase text-muted mb-2">
                                        Groups
                                    </h6>
                                    <span className="h2">
                                        {count?.teams}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card school-card">
                        <div className="card-body">
                            <div className="row align-items-center">
                                <div className="col">
                                    {/* < FaChild className='fs-1' /> */}
                                    <img src='/img/children_icon.svg' />
                                </div>
                                <div className="col-auto">
                                    <h6 className="text-uppercase text-muted mb-2">
                                        Children
                                    </h6>
                                    <span className="h2">
                                        {count?.students}
                                    </span>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div>
                <SchoolsTable apiData={apiData} />
            </div>
            <Modal show={show} onHide={handleClose} >
                <Modal.Header>
                    <h2 className='m-0'>Add School</h2>
                </Modal.Header>
                <Modal.Body>
                    <Formik enableReinitialize={true} validationSchema={formSchema} initialValues={formData} onSubmit={handleformData}>
                        {({ handleChange, handleSubmit, values, errors, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor='school' className='form-label'>Search School <span className='text-danger'>*</span></label>
                                    <LocationSearch setAddressResult={setAddressResult} />
                                    <span className='text-danger'>{errors.name}</span>
                                </div>
                                <div className='from-group row'>
                                    <div className="col form-group">
                                        <label htmlFor='type' className='form-label'>School Type <span className='text-danger'>*</span></label>
                                        <select className="form-select" as='select' id='type' defaultValue={values.type} onChange={handleChange}>
                                            <option selected>Select School Type</option>
                                            <option value="PUBLIC">Public</option>
                                            <option value="PRIVATE">Private</option>
                                        </select>
                                        <span className='text-danger'>{errors.type}</span>
                                    </div>
                                    <div className="col form-group">
                                        <label htmlFor='stage' className='form-label'>Stage <span className='text-danger'>*</span></label>
                                        <select className="form-select" as='select' id='stage' defaultValue={values.stage} onChange={handleChange}>
                                            <option selected>Select School Stage</option>
                                            <option value="PRIMARY">Primary</option>
                                            <option value="SECONDARY">Secondary</option>
                                            <option value="SENIOR_SECONDARY">Senior_Secondary</option>
                                        </select>
                                        <span className='text-danger'>{errors.stage}</span>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor='school_email' className='form-label'>School Email</label>
                                    <input type={'text'} className='form-control' id='school_email' defaultValue={values.school_email} onChange={handleChange} />
                                    <span className='text-danger'>{errors.school_email}</span>
                                </div>
                                <div className='row form-group'>
                                    <div className='col'>
                                        <label htmlFor='zone' className='form-label'>Zone</label>
                                        <input type={'text'} className='form-control' id='zone' defaultValue={values.zone} onChange={handleChange} />
                                        <span className='text-danger'>{errors.zone}</span>
                                    </div>
                                    <div className='col'>
                                        <label htmlFor='medium' className='form-label'>Medium <span className='text-danger'>*</span></label>
                                        <Select
                                            defaultValue={[]}
                                            isMulti
                                            id='medium'
                                            name="colors"
                                            options={[
                                                { value: 'ENGLISH', label: 'English' },
                                                { value: 'HINDI', label: 'Hindi' },
                                                { value: 'MARATHI', label: 'Marathi' },
                                                { value: 'KANNADA', label: 'Kanadda' }
                                            ]}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={(e) => { setFieldValue('medium', e) }}
                                        />
                                        <span className='text-danger'>{errors.medium}</span>
                                    </div>
                                </div>
                                <div className='row form-group'>
                                    <div className='col'>
                                        <label htmlFor='address1' className='form-label'>Address-1 <span className='text-danger'>*</span></label>
                                        <textarea className="form-control" id="address1" defaultValue={values.address1} onChange={handleChange} ></textarea>
                                        <span className='text-danger'>{errors.address1}</span>
                                    </div>
                                    <div className='col'>
                                        <label htmlFor='address2' className='form-label'>Address-2</label>
                                        <textarea className="form-control" id="address2" defaultValue={values.address2} onChange={handleChange} ></textarea>
                                        <span className='text-danger'>{errors.address2}</span>
                                    </div>
                                </div>
                                <div className='row form-group'>
                                    <div className='col'>
                                        <label htmlFor='city' className='form-label'>City <span className='text-danger'>*</span></label>
                                        <input type={'text'} className='form-control' id='city' defaultValue={values.city} onChange={handleChange} />
                                        <span className='text-danger'>{errors.city}</span>
                                    </div>
                                    <div className='col'>
                                        <label htmlFor='state' className='form-label'>State <span className='text-danger'>*</span></label>
                                        <input type={'text'} className='form-control' id='state' defaultValue={values.state} onChange={handleChange} />
                                        <span className='text-danger'>{errors.state}</span>
                                    </div>
                                    <div className='col'>
                                        <label htmlFor='postal_code' className='form-label'>Pincode <span className='text-danger'>*</span></label>
                                        <input type={'number'} className='form-control' id='postal_code' defaultValue={values.postal_code} onChange={handleChange} />
                                        <span className='text-danger'>{errors.postal_code}</span>
                                    </div>
                                </div>
                                <div className='row form-group'>
                                    <div className='col'>
                                        <label htmlFor='principal_name' className='form-label'>Name Of Principal</label>
                                        <input type={'text'} className="form-control" id="principal_name" defaultValue={values.principal_name} onChange={handleChange} />
                                        <span className='text-danger'>{errors.principal_name}</span>
                                    </div>
                                    <div className='col'>
                                        <label htmlFor='principal_phone' className='form-label'>Principal Phone Number</label>
                                        <input type={'number'} className="form-control" id="principal_phone" defaultValue={values.principal_phone} onChange={handleChange} />
                                        <span className='text-danger'>{errors.principal_phone}</span>
                                    </div>
                                </div>
                                <div className='row form-group'>
                                    <div className='col'>
                                        <label htmlFor='contact_name' className='form-label'>Name Of Contact Person</label>
                                        <input type={'text'} className="form-control" id="contact_name" defaultValue={values.contact_name} onChange={handleChange} />
                                        <span className='text-danger'>{errors.contact_name}</span>
                                    </div>
                                    <div className='col'>
                                        <label htmlFor='contact_phone' className='form-label'>Contact Person Phone Number</label>
                                        <input type={'number'} className="form-control" id="contact_phone" defaultValue={values.contact_phone} onChange={handleChange} />
                                        <span className='text-danger'>{errors.contact_phone}</span>
                                    </div>
                                </div>

                                <div className='mt-5'>
                                    <div className='d-flex justify-content-end gap-3'>
                                        <button type='button' className='btn btn-white' onClick={() => setShow(false)}>Cancel</button>
                                        <button type='submit' className='btn btn-primary' >Submit</button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Schools
