export let UserTableData =
    [
        { id: 2, name: 'Anil', email: 'abc@xyz.com', phone: 9988991234, role: 'Admin', actions: false },
        { id: 3, name: 'Vineeth', email: 'abc@xyz.com', phone: 9988991234, role: 'Admin', actions: true },
        { id: 4, name: 'Vineeth', email: 'abc@xyz.com', phone: 9988991234, role: 'Admin', actions: true },
        { id: 6, name: 'Vineeth', email: 'abc@xyz.com', phone: 9988991234, role: 'Admin', actions: true },
        { id: 7, name: 'Vineeth', email: 'abc@xyz.com', phone: 9988991234, role: 'Admin', actions: true },
        { id: 8, name: 'Vineeth', email: 'abc@xyz.com', phone: 9988991234, role: 'Admin', actions: false },
        { id: 9, name: 'Bhavana', email: 'abc@xyz.com', phone: 8888222212, role: 'School Level', actions: true },
        { id: 10, name: 'Bhavana', email: 'abc@xyz.com', phone: 8888222212, role: 'School Level', actions: true },
        { id: 11, name: 'Bhavana', email: 'abc@xyz.com', phone: 8888222212, role: 'School Level', actions: true },
        { id: 12, name: 'Bhavana', email: 'abc@xyz.com', phone: 8888222212, role: 'School Level', actions: true },
        { id: 14, name: 'Bhavana', email: 'abc@xyz.com', phone: 8888222212, role: 'School Level', actions: true },
        { id: 15, name: 'Bhavana', email: 'abc@xyz.com', phone: 8888222212, role: 'School Level', actions: true },
        { id: 16, name: 'Fardeen', email: 'abc@xyz.com', phone: 7777777777, role: 'Owner', actions: true },
        { id: 17, name: 'Fardeen', email: 'abc@xyz.com', phone: 7777777777, role: 'Owner', actions: true },
        { id: 18, name: 'Fardeen', email: 'abc@xyz.com', phone: 7777777777, role: 'Owner', actions: true },
        { id: 19, name: 'Fardeen', email: 'abc@xyz.com', phone: 7777777777, role: 'Owner', actions: true },
        { id: 20, name: 'Fardeen', email: 'abc@xyz.com', phone: 7777777777, role: 'Owner', actions: true },
    ]