import React from 'react'
import { useState } from 'react'
import ProductTable from './productTable'
import { Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { API } from 'aws-amplify';
import { listProductCategories } from '../../graphql/queries';
import { createProduct, createProductCategory } from '../../graphql/mutations';
import { Storage } from 'aws-amplify';
import { Link } from 'react-router-dom';
import ProductCategoryTable from './ProductCategoryTable';

const Shop = () => {
    const [show, setShow] = useState(false);
    const [options, setOptions] = useState([]);
    const [display, setDisplay] = useState(1)
    
    const handleClose = () => { setShow(false) }
    const handleShow = () => setShow(true);

    const [categoryFormData, setCategoryFormData] = useState({
        name: '',
        image: '',
        point: '',
    })

    const categoryFormSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        image: Yup.string().required('Required'),
        point: Yup.string().required('Required'),
    })

    
    const [formData, setFormData] = useState({
        name: '',
        category: '',
        image: '',
    });

    const formSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        category: Yup.string().required('Required'),
        image: Yup.string().required('Required')
    })

    // handleListProductCategories 
    const handleListProductCategories = async () => {
        try {
            let res = await API.graphql({
                query: listProductCategories,
            })
            let categories = res?.data?.listProductCategories?.items?.map((category) => ({ label: category.name, value: category.id }));
            setOptions(categories)
        } catch (error) {
            console.log(error)
        }
    }

    // handleformData 
    const handleformData = async (form) => {
        let image = await handleUploadImage(form.image)
        handleCreateProduct(form, image)
    }

    // handleUploadImage 
    const handleUploadImage = async (file) => {
        try {
            const stored = await Storage.put(`Product-${Math.random().toString(36).substring(2, 15)}.${file.name.split('.')[1]}`, file, { contentType: file.type });
            const url = await Storage.get(stored.key, { level: 'public' })
            return url.split('?')[0]
        } catch (error) {
            console.log(error)
        }
    }

    // handleCreateProduct 
    const handleCreateProduct = async (form, image) => {
        try {
            let res = await API.graphql({
                query: createProduct,
                variables: {
                    input: {
                        categoryId: form.category,
                        image: image,
                        name: form.name,
                        point: form.point
                    }
                }
            })
            console.log('res', res)
            handleClose()
        } catch (error) {
            console.log(error)
        }
    }


    // handleCategoryformData

    const handleCreateCategory = async (form, image) =>{
        try {
            let res = await API.graphql({
                query: createProductCategory,
                variables: {
                    input: {
                        name: form.name,
                        image: image,
                        point: form.point,
                    }
                }
            })
            console.log('res', res)
            handleClose()
        } catch (error) {
            console.log(error)
        }
    }

    const handleCategoryformData = async (form) =>{
        console.log(form, "FORMMM");
        let image = await handleUploadImage(form.image)
        handleCreateCategory(form, image)
    }

    return (
        <div>


    <div className='container-fluid'>
        <div className="header ">
            <div className="header-body">
            <div className="row">
                            <div className="col">
                                <h2 className="header-title">
                                    Scrappy Shop
                                </h2>
                            </div>
                            <div className='col-auto'>
                                {
                                display === 1 ? <button className='btn btn-primary btn-sm' onClick={() => { handleShow(); handleListProductCategories() }}>
                                Add Product</button> :  <button className='btn btn-primary btn-sm' onClick={() => { handleShow() }}>
                                Add Category</button>
                                }
                                
                            </div>
                        </div>
                <div className="row align-items-center">
                    <div className="col">
                        <ul className="nav nav-tabs nav-overflow header-tabs">
                            <li className="nav-item">
                                <Link className={`nav-link ${(display === 1) ? 'active' : ''}`} onClick={() => { setDisplay(1) }}>
                                   Add Product
                                    {/* <span className="badge rounded-pill bg-secondary-soft">10</span> */}
                                </Link>
                            </li>
                            <li className="nav-item ">
                                <Link className={`nav-link ${(display === 2) ? 'active' : ''}`} onClick={() => { setDisplay(2) }}>
                                 Add Category
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className='container-fluid'>
            {(display === 1) ?  <ProductTable AddModalShow={show} /> : <ProductCategoryTable />  }
        </div>
    </div>


{/*             
            <div className='container-fluid'>
                <div className="header">
                    <div className="header-body">
                        <div className="row">
                            <div className="col">
                                <h2 className="header-title">
                                    Scrappy Shop
                                </h2>
                            </div>
                            <div className='col-auto'>
                                <button className='btn btn-primary btn-sm' onClick={() => { handleShow(); handleListProductCategories() }}>
                                    Add Product</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ProductTable AddModalShow={show} />
            </div> */}




             {/* showCategory  */}

             {display !== 1 ?   <Modal show={show} onHide={handleClose} >
                <Modal.Header>
                    <h2 className='m-0'> Add Category </h2>
                </Modal.Header>
                <Modal.Body>
                    <Formik enableReinitialize={true}  initialValues={categoryFormData} onSubmit={handleCategoryformData}>
                        {({ handleChange, handleSubmit, values, errors, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor='name' className='form-label'>Category Name <span className='text-danger'>*</span></label>
                                    <input type={'text'} className='form-control' id='name' defaultValue={values?.name} onChange={handleChange} />
                                    <span className='text-danger'>{errors.name}</span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor='image' className='form-label'>Category Image</label>
                                    <div className='d-flex justify-content-between p-1 py-2 form-control' style={{ border: 'dashed 1px #D2DDEC' }}>
                                        <div>{values.image.name || 'Upload Image'}</div>
                                        <label className='btn btn-primary btn-sm' htmlFor='image' >
                                            <input type='file' id='image' accept=' image/*' onChange={(e) => { setFieldValue("image", e.currentTarget.files[0]); }} /> Upload
                                        </label>
                                    </div>
                                    <div className='mt-3'>
                                        {values?.image && <img src={URL.createObjectURL(values?.image)} width={'50px'} height={'50px'} />}
                                    </div>

                                </div>
                                <div className="form-group">
                                    <label htmlFor='name' className='form-label'>Point <span className='text-danger'>*</span></label>
                                    <input type="number" className='form-control' id='point' defaultValue={values?.point} onChange={handleChange} />
                                    <span className='text-danger'>{errors.point}</span>
                                </div>
                                <div className='mt-5'>
                                    <div className='d-flex justify-content-end gap-3'>
                                        <button type='button' className='btn btn-white' onClick={() => setShow(false)}>Cancel</button>
                                        <button type='submit' className='btn btn-primary' >Submit</button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal> :  <Modal show={show} onHide={handleClose} >
                <Modal.Header>
                    <h2 className='m-0'> Add Product </h2>
                </Modal.Header>
                <Modal.Body>
                    <Formik enableReinitialize={true} validationSchema={formSchema} initialValues={formData} onSubmit={handleformData}>
                        {({ handleChange, handleSubmit, values, errors, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor='name' className='form-label'>Product Name <span className='text-danger'>*</span></label>
                                    <input type={'text'} className='form-control' id='name' defaultValue={values.name} onChange={handleChange} />
                                    <span className='text-danger'>{errors.name}</span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor='image' className='form-label'>Product Image</label>
                                    <div className='d-flex justify-content-between p-1 py-2 form-control' style={{ border: 'dashed 1px #D2DDEC' }}>
                                        <div>{values.image.name || 'Upload Image'}</div>
                                        <label className='btn btn-primary btn-sm' htmlFor='image' >
                                            <input type='file' id='image' accept=' image/*' onChange={(e) => { setFieldValue("image", e.currentTarget.files[0]); }} /> Upload
                                        </label>
                                    </div>
                                    <div className='mt-3'>
                                        {values?.image && <img src={URL.createObjectURL(values?.image)} width={'50px'} height={'50px'} />}
                                    </div>

                                </div>
                                <div className="form-group">
                                    <label htmlFor='category' className='form-label'>Category <span className='text-danger'>*</span></label>
                                    <select className="form-select" as='select' id='category' defaultValue={values.category} onChange={handleChange}>
                                        <option >Select Category</option>
                                        {options.map((option) => (
                                            <option value={option.value} key={option.value}>{option.label}</option>))
                                        }
                                    </select>
                                    <span className='text-danger'>{errors.stage}</span>
                                </div>
                                <div className='mt-5'>
                                    <div className='d-flex justify-content-end gap-3'>
                                        <button type='button' className='btn btn-white' onClick={() => setShow(false)}>Cancel</button>
                                        <button type='submit' className='btn btn-primary' >Submit</button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>}
            









           
        </div >
    )
}

export default Shop
