import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import SchoolPosts from './schoolPosts'
import SchoolTeams from './schoolTeams'
import SchoolStudents from './schoolStudents'
import { customGetSchool } from '../../customGraphql/queries'
import { Modal } from 'react-bootstrap'
import { Formik } from 'formik'
import { API } from 'aws-amplify'
import { getSchool } from '../../graphql/queries'

const EditAddress = ({ setShow }) => {

    return (
        <form>
            <div className="form-group">
                <label htmlFor='school_name' className='form-label'>School Name *</label>
                <input type={'text'} className='form-control' id='school_name' />
                {/* <span className='text-danger'>{errors.school_name}</span> */}
            </div>
            <div className="form-group">
                <label htmlFor='school_email' className='form-label'>School Email</label>
                <input type={'text'} className='form-control' id='school_email' />
                {/* <span className='text-danger'>{errors.school_email}</span> */}
            </div>
            <div className='row form-group'>
                <div className='col'>
                    <label htmlFor='address_1' className='form-label'>Address-1 *</label>
                    <textarea className="form-control" id="address_1"  ></textarea>
                    {/* <span className='text-danger'>{errors.address_1}</span> */}
                </div>
                <div className='col'>
                    <label htmlFor='address_2' className='form-label'>Address-2</label>
                    <textarea className="form-control" id="address_2"  ></textarea>
                    {/* <span className='text-danger'>{errors.address_2}</span> */}
                </div>
            </div>
            <div className='row form-group'>
                <div className='col'>
                    <label htmlFor='city' className='form-label'>City *</label>
                    <input type={'text'} className='form-control' id='city' />
                    {/* <span className='text-danger'>{errors.city}</span> */}
                </div>
                <div className='col'>
                    <label htmlFor='state' className='form-label'>State *</label>
                    <input type={'text'} className='form-control' id='state' />
                    {/* <span className='text-danger'>{errors.state}</span> */}
                </div>
                <div className='col'>
                    <label htmlFor='pincode' className='form-label'>Pincode *</label>
                    <input type={'number'} className='form-control' id='pincode' />
                    {/* <span className='text-danger'>{errors.pincode}</span> */}
                </div>
            </div>
            <div className='row form-group'>
                <div className='col'>
                    <label htmlFor='ward' className='form-label'>Ward *</label>
                    <input type={'text'} className='form-control' id='ward' />
                    {/* <span className='text-danger'>{errors.ward}</span> */}
                </div>
                <div className='col'>
                    <label htmlFor='medium' className='form-label'>Medium *</label>
                    <input type={'text'} className='form-control' id='medium' />
                    {/* <span className='text-danger'>{errors.medium}</span> */}
                </div>
                <div className='col'>
                    <label htmlFor='level' className='form-label'>Level *</label>
                    <input type={'text'} className='form-control' id='level' />
                    {/* <span className='text-danger'>{errors.level}</span> */}
                </div>
            </div>
            <div className='mb-3 form-group'>
                <label htmlFor='geo_tagging' className='form-label'>Geo Tagging</label>
                <input type={'text'} className='form-control' id='geo_tagging' />
                {/* <span className='text-danger'>{errors.geo_tagging}</span> */}
            </div>
            <div className='mb-3 form-group'>
                <label htmlFor='lat_long' className='form-label'>Lat & Long</label>
                <input type={'text'} className='form-control' id='lat_long' />
                {/* <span className='text-danger'>{errors.lat_long}</span> */}
            </div>
            <div className='row form-group'>
                <div className='col'>
                    <label htmlFor='key_person_name' className='form-label'>Name Of Principal/Teacher</label>
                    <input type={'text'} className="form-control" id="key_person_name" />
                    {/* <span className='text-danger'>{errors.key_person_name}</span> */}
                </div>
                <div className='col'>
                    <label htmlFor='key_person_phone' className='form-label'>Principal/Teacher Phone Number</label>
                    <input type={'number'} className="form-control" id="key_person_phone" />
                </div>
            </div>

            <div className='mt-5'>
                <div className='d-flex justify-content-end gap-3'>
                    <button type='button' className='btn btn-white' onClick={() => setShow(false)}>Cancel</button>
                    <button type='submit' className='btn btn-primary' >Save</button>
                </div>
            </div>
        </form>
    )
}

const EditKeyPerson = ({ setShow }) => {
    return (
        <form>
            <div className="form-group">
                <label htmlFor='name' className='form-label'>Name *</label>
                <input type={'text'} className='form-control' id='name' />
                {/* <span className='text-danger'>{errors.name}</span> */}
            </div>
            <div className="form-group">
                <label htmlFor='designation' className='form-label'>Designation</label>
                <input type={'text'} className='form-control' id='designation' />
                {/* <span className='text-danger'>{errors.designation}</span> */}
            </div>
            <div className="form-group">
                <label htmlFor='phone' className='form-label'>Phone</label>
                <input type={'text'} className='form-control' id='phone' />
                {/* <span className='text-danger'>{errors.phone}</span> */}
            </div>
            <div className="form-group">
                <label htmlFor='email' className='form-label'>Email</label>
                <input type={'text'} className='form-control' id='email' />
                {/* <span className='text-danger'>{errors.email}</span> */}
            </div>
            <div className='mt-5'>
                <div className='d-flex justify-content-end gap-3'>
                    <button type='button' className='btn btn-white' onClick={() => setShow(false)}>Cancel</button>
                    <button type='submit' className='btn btn-primary' >Save</button>
                </div>
            </div>
        </form>
    )
}

const SchoolInfo = () => {
    const [display, setDisplay] = useState(1);
    const [show, setShow] = useState(false);
    const [apiData, setApiData] = useState({});

    const handleClose = () => { setShow(false) }
    const handleShow = () => setShow(true);


    const { id } = useParams();

    const navigate = useNavigate();

    const getSchoolData = async () => {
        try {
            let res = await API.graphql({
                query: getSchool,
                variables: {
                    id: id
                }
            })
            res.data.getSchool.primaryContact = JSON.parse(res?.data?.getSchool?.primaryContact)
            console.log('res', res.data.getSchool)
            setApiData(res.data.getSchool)

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getSchoolData()
    }, [])


    return (
        <div>
            <div className='header'>
                <div className='container-fluid'>
                    <div className='header-body'>
                        <div className="row mb-3">
                            <div className="col">
                                <h2 className="header-title">
                                    <i className='fe fe-arrow-left fw-bold me-3' onClick={() => { navigate(-1) }}></i>
                                    {apiData.name}
                                </h2>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col">
                                <div className='d-flex justify-content-between'>
                                    <h4 >Details</h4>
                                    {/* <p className='fw-bolder text-primary mb-0' onClick={() => { handleShow() }}><i className='fe fe-edit fw-bolder'></i> Edit</p> */}
                                </div>
                                <div className='card school-details-card'>
                                    <div className='card-body '>
                                        <div className='row'>
                                            <div className='col'>
                                                <h5>
                                                    Address
                                                </h5>
                                                <p className='text-muted'>
                                                    {apiData.address1}
                                                </p>
                                            </div>
                                            <div className='col'>
                                                <h5>
                                                    Zone
                                                </h5>
                                                <p className='text-muted'>
                                                    {apiData.zone}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                <h5>
                                                    City
                                                </h5>
                                                <p className='text-muted'>
                                                    {apiData.city}
                                                </p>
                                            </div>
                                            <div className='col'>
                                                <h5>
                                                    State
                                                </h5>
                                                <p className='text-muted'>
                                                    {apiData.state}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                <h5>
                                                    Type
                                                </h5>
                                                <p className='text-muted'>
                                                    {apiData.type}
                                                </p>
                                            </div>
                                            <div className='col'>
                                                <h5>
                                                    Stage
                                                </h5>
                                                <p className='text-muted'>
                                                    {apiData.stage}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className='d-flex justify-content-between'>
                                    <h4>Primary Contact</h4>
                                    <p className='fw-bolder text-primary mb-0 cursor-pointer' onClick={() => { handleShow() }}><i className='fe fe-plus-circle fw-bolder'></i> Add Primary Contact</p>
                                </div>
                                <div className='card'>
                                    <div className='card-body school-keyPerson-card'>

                                        {/* {[1, 2].map((e) => {
                                            return (
                                                <div className="list-group-item mb-3 ">
                                                    <div className="row align-items-center">
                                                        <div className="col-auto">
                                                            <div className="avatar ">
                                                                <span className="avatar-title rounded-circle">PR</span>
                                                            </div>
                                                        </div>
                                                        <div className="col ms-n2">
                                                            <h4 className="mb-1">
                                                                Prakash Raj
                                                            </h4>
                                                            <p className="card-text ">
                                                                Principal
                                                            </p>
                                                        </div>
                                                        <div className="col-auto justify-content-evenly">
                                                            <div className="col ms-n2">
                                                                <p className="mb-1">
                                                                    +91 7799757848
                                                                </p>
                                                                <p className="card-text ">
                                                                    principal@abc.com
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>)
                                        })} */}
                                        <div className="list-group-item mb-3 ">
                                            <div className="row align-items-center py-2 border-bottom">
                                                <div className="col">
                                                    <p className="card-text ">
                                                        Principal
                                                    </p>
                                                </div>
                                                <div className="col">
                                                    <h4 className="mb-1">
                                                        {apiData?.primaryContact?.principal_name || '-'}
                                                    </h4>
                                                </div>
                                                <div className="col-auto justify-content-evenly">
                                                    <p className="mb-1">
                                                        {apiData?.primaryContact?.principal_phone || '-'}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row align-items-center py-2">
                                                <div className="col">
                                                    <p className="card-text ">
                                                        Contact Person
                                                    </p>
                                                </div>
                                                <div className="col">
                                                    <h4 className="mb-1">
                                                        {apiData?.primaryContact?.contact_name || '-'}
                                                    </h4>
                                                </div>
                                                <div className="col-auto justify-content-evenly">
                                                    <p className="mb-1">
                                                        {apiData?.primaryContact?.contact_phone || '-'}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row align-items-center'>
                            <div className='col'>

                                <ul className='nav nav-tabs nav-overflow header-tabs'>
                                    <li className='nav-item'>
                                        <Link className={`nav-link ${(display === 1) ? 'active' : ''}`} onClick={() => { setDisplay(1) }}>
                                            Posts
                                        </Link>
                                    </li>
                                    <li className='nav-item '>
                                        <Link className={`nav-link ${(display === 2) ? 'active' : ''}`} onClick={() => { setDisplay(2) }}>
                                            Teams
                                        </Link>
                                    </li>
                                    <li className='nav-item '>
                                        <Link className={`nav-link ${(display === 3) ? 'active' : ''}`} onClick={() => { setDisplay(3) }}>
                                            Students
                                        </Link>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                    <div className='container-fluid mt-3'>
                        {(display === 1) ? <SchoolPosts /> : (display === 2) ? <SchoolTeams /> : <SchoolStudents schoolId={id} />}
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} >
                <Modal.Header>
                    <h2 className='m-0'>Add Key Person</h2>
                </Modal.Header>
                <Modal.Body>
                    <EditKeyPerson setShow={setShow} />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default SchoolInfo
