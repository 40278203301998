import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Pagination from '../../components/pagination';
import { SchoolsTableData } from '../../data/SchoolsTabledata';

const LeaderBoardTable = () => {
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        handleSearchResult(SchoolsTableData)
    }, [searchInput])

    const handleSearchResult = (data) => {
        let result = data.filter(item =>
            item.schoolname.toLowerCase().includes(searchInput) ||
            item.address.toLowerCase().includes(searchInput))
        setFilteredData(result)

    }

    const handleSearchInput = ({ target }) => {
        setSearchInput(target.value.toLowerCase())
    }


    return (
        <div>
            <div className="card" id="contactsList">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <form>
                                <div className="input-group input-group-flush input-group-merge input-group-reverse">
                                    <input className="form-control list-search" type="search" placeholder="Search" onChange={handleSearchInput} />
                                    <span className="input-group-text">
                                        <i className="fe fe-search"></i>
                                    </span>
                                </div>
                            </form>
                        </div>
                        <div className="col-auto">
                            <div className="dropdown">
                                <button className="btn btn-sm btn-white" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
                                    <i className="fe fe-sliders me-1"></i> Filter <span className="badge bg-primary ms-1 d-none">0</span>
                                </button>
                                <form className="dropdown-menu dropdown-menu-end dropdown-menu-card">
                                    <div className="card-header">
                                        <h4 className="card-header-title">
                                            Filters
                                        </h4>
                                        <button className="btn btn-sm btn-link text-reset d-none" type="reset">
                                            <small>Clear filters</small>
                                        </button>
                                    </div>
                                    <div className="card-body">
                                        <div className="list-group list-group-flush mt-n4 mb-4">
                                            <div className="list-group-item">
                                                <div className="row">
                                                    <div className="col">
                                                        <small>Title</small>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="choices" data-type="select-one" tabIndex="0" role="listbox" aria-haspopup="true" aria-expanded="false">
                                                            <select className="form-select form-select-sm form-control" name="item-title" data-choices="{&quot;searchEnabled&quot;: false}" hidden="" tabIndex="-1" data-choice="active">
                                                                <option value="*" data-custom-properties="[object Object]">Any</option>
                                                                <option value="*" data-custom-properties="[object Object]">Designer</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="list-group-item">
                                                <div className="row">
                                                    <div className="col">
                                                        <small>Lead scrore</small>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="choices" data-type="select-one" tabIndex="0" role="listbox" aria-haspopup="true" aria-expanded="false">
                                                            <select className="form-select form-select-sm form-control" name="item-title" data-choices="{&quot;searchEnabled&quot;: false}" hidden="" tabIndex="-1" data-choice="active">
                                                                <option value="*" data-custom-properties="[object Object]">Any</option>
                                                                <option value="*" data-custom-properties="[object Object]">Designer</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="btn w-100 btn-primary" type="submit">
                                            Apply filter
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-sm table-hover card-table">
                        <thead >
                            <tr className='align-middle text-center'>
                                <th >
                                    <div className="text-muted">Rank</div>
                                </th>
                                <th >
                                    <div className="text-muted ">Student Name</div>
                                </th>
                                <th>
                                    <div className="text-muted" >School Name</div>
                                </th>
                                <th>
                                    <div className="text-muted" >Team Name</div>
                                </th>
                                <th>
                                    <div className="text-muted" >Scrappy Stars</div>
                                </th>
                                {/* <th>
                                    <div className="text-muted" >Actions</div>
                                </th> */}

                            </tr>
                        </thead>
                        <tbody className="list fs-base ">
                            {displayData.map((e) => {
                                return (
                                    <tr key={e.id}>
                                        <td>
                                            <div className='text-center'>{e.id}</div>
                                        </td>
                                        <td className='text-center '>
                                            {'Vineeth'}
                                        </td>
                                        <td>
                                            <div className='text-center'>
                                                {e.schoolname}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='text-center'>
                                                {'Team ABC'}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='text-center'>
                                                {50}
                                            </div>
                                        </td>

                                        {/* <td>
                                            <div className='text-center'>
                                                <div className="dropdown">
                                                    <div className="dropdown-ellipses dropdown-toggle text-mute" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="fe fe-more-vertical"></i>
                                                    </div>
                                                    <div className="dropdown-menu dropdown-menu-end">
                                                        <div className="dropdown-item" onClick={() => navigate(`/campaigns/${e.id}`)}>
                                                            View
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td> */}

                                    </tr>

                                )
                            })}

                        </tbody>
                    </table>
                </div>
                <Pagination PageData={filteredData} setDisplayData={setDisplayData} />
            </div>
        </div>)
}

export default LeaderBoardTable