export const customListSchoolswithApprovedGroups = /* GraphQL */ `
  query ListSchools(
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        stage
        medium
        approved
        address
        address1
        address2
        zone
        city
        state
        postal_code
        primaryContact
        createdAt
        updatedAt
        students {
          items {
            user {
              avatar
              firstName
              lastName
              id
            }
          }
        }
        groups(filter: {approved: {eq: true}}) {
          items {
            id
            name
          }
        }
      }
      nextToken
    }
  }
`;

export const customListGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        schoolId
        name
        approved
        createdAt
        updatedAt
        groupOwnerId
        owner {
          lastName
          firstName
          avatar
          id
        }
      }
      nextToken
    }
  }
`;

export const listGroupsWithMembers = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        schoolId
        name
        approved
        createdAt
        updatedAt
        groupOwnerId
        members {
          items {
            student {
              grade
              id
              schoolId
               firstName
               lastName
              user {
                avatar
                firstName
                id
                lastName
                role
                updatedAt
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const customApprovedListActivityFeeds = /* GraphQL */ `
 query ListActivityFeeds(
    $filter: ModelActivityFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivityFeeds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        media
        active
        createdAt
        updatedAt
        activityFeedOwnerId
        activityFeedGroupId
        owner {
          avatar
          firstName
          lastName
          role
        }
        comments {
          items {
            activityFeedId
            comment
            createdAt
            id
            updatedAt
            user {
              avatar
              firstName
              createdAt
              id
              lastName
              role
            }
          }
        }
        likes {
          items {
            id
            activityFeedId
            user {
              avatar
              firstName
              lastName
              id
              schools {
                items {
                  school {
                    name
                    id
                  }
                }
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const customListGroupsWithInvites = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        approved
        name
        schoolId
        groupOwnerId
        createdAt
        id
        owner {
          id
          avatar
          firstName
          lastName
        }
       invites {
        items {
          student {
            firstName
            lastName
            id
             user {
                avatar
                id
            }
          }
        }
      }
    }
      nextToken
    }
  }
`;



export const getGroupWithInvitesAndMembers = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      schoolId
      name
      approved
      owner {
        id
        firstName
        lastName
        role
        avatar
        createdAt
        updatedAt
        userStudentId
      }
      invites {
          items {
            student {
              firstName
              grade
              lastName
              schoolId
              studentUserId
              id
            }
          }
      }
      members {
        items {
          student {
            firstName
            grade
            lastName
            schoolId
            studentUserId
            id
             school {
              name
            }
            user {
                avatar
                id
                lastName
                role
              }
          }
        }
      }
      createdAt
      updatedAt
      groupOwnerId
    }
  }
`;


export const listStudentsWithGroup = /* GraphQL */ `
  query ListStudents(
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
      id
      firstName
      lastName
      hasGroup
      studentUserId
      grade
        user {
          id
          avatar
        }
      groups {
        items {
          group{
            id
            name
          }
        }
      }
    }
      nextToken
    }
  }
`;

export const customListCampaigns = /* GraphQL */ `
  query ListCampaigns(
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        category
        otherCategory
        name
        description
        approved
        schoolId
        studentId
        plan
        roles
        notes
        schedule
        createdAt
        updatedAt
        student {
          firstName
          lastName
        }
      }
      nextToken
    }
  }
`;

export const getSchoolMediumEnum =/* GraphQL */
  ` query GetMediumEnum 
  { __type(name: "SchoolMedium") 
  { enumValues { name } } } `;

export const customListChallengeActivities = /* GraphQL */ `
  query ListChallengeActivities(
    $filter: ModelChallengeActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChallengeActivities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        studentId
        challengeId
        research
        design
        build
        report
        status
        createdAt
        updatedAt
        challenge {
          point
          name
          id
        }
         group {
          name
          id
        }
      }
      nextToken
    }
  }
`;

export const listProductsWithCategory = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        image
        point
        categoryId
        createdAt
        updatedAt
        category {
          id
          image
          name
        }
      }
      nextToken
    }
  }
`;

export const listUsersWithSchools = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        phone
        role
        avatar
        createdAt
        updatedAt
        userStudentId
        schools {
          items {
            school {
              name
              id
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const listSchoolUsersWithSchools = /* GraphQL */ `
  query ListSchoolUsers(
    $filter: ModelSchoolUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchoolUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        role
        schoolId
        userId
        school {
          id
          name
        }
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listSchoolWithSchoolUsers = /* GraphQL */ `
  query ListSchoolUsers(
    $filter: ModelSchoolUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchoolUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        role
        schoolId
        userId
        createdAt
        updatedAt
        school  {
          id
          name
          type
          stage
          medium
          approved
          address
          address1
          address2
          zone
          city
          state
          postal_code
          primaryContact
          createdAt
          updatedAt
          students {
            items {
              user {
                avatar
                firstName
                lastName
                id
              }
            }
          }
          groups(filter: {approved: {eq: true}}) {
            items {
              id
              name
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const listGroupsWithSchoolUsers = /* GraphQL */ `
  query ListSchoolUsers(
    $filter: ModelSchoolUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchoolUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        role
        schoolId
        userId
        school {
        groups(filter: {approved: {eq: false}}) {
        items {
          id
          schoolId
          name
          approved
          createdAt
          updatedAt
          groupOwnerId
            owner {
              lastName
              firstName
              avatar
              id
            }
          }
          nextToken
            }
          }
      nextToken
    }
  }
`;