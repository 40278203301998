import React, { useEffect } from 'react'
import RequestTable from './schoolRequestTable';
import { useNavigate } from 'react-router-dom';


const SchoolRequests = () => {
    const navigate = useNavigate();

   

    return (
        <div>
            <div className='container-fluid'>
                <div className="header">
                    <div className="header-body">
                        <div className="row">
                            <div className="col">
                                <h2 className="header-title">
                                    <i className='fe fe-arrow-left fw-bold me-3' onClick={() => { navigate(-1) }}></i>
                                    School Requests
                                </h2>
                            </div>
                            {/* <div className='col-auto'>
                                <button className='btn btn-primary btn-sm'>Add School</button>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div>
                    <RequestTable />
                </div>
            </div>
        </div>
    )
}

export default SchoolRequests
