import React from 'react';
import Pagination from '../../components/pagination';
import { SchoolsTableData } from '../../data/SchoolsTabledata';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { API } from 'aws-amplify';
import { customListCampaigns } from '../../customGraphql/queries';


const CampaignApprovedTable = () => {
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [apiData, setApiData] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        handleSearchResult(apiData)
    }, [searchInput])

    const handleSearchResult = (data) => {
        let result = data
        // .filter(item =>
        //     item.schoolname.toLowerCase().includes(searchInput) ||
        //     item.address.toLowerCase().includes(searchInput))
        setFilteredData(result)

    }

    const handleSearchInput = ({ target }) => {
        setSearchInput(target.value.toLowerCase())
    }


    const handleGetData = async () => {
        try {
            let res = await API.graphql({
                query: customListCampaigns,
                variables: {
                    filter: {
                        approved: { eq: true }
                    }
                }
            })
            setApiData(res?.data?.listCampaigns?.items)
            handleSearchResult(res.data.listCampaigns.items)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        handleGetData()
    }, [])


    return (
        <div>
            <div className="card" id="contactsList">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <form>
                                <div className="input-group input-group-flush input-group-merge input-group-reverse">
                                    <input className="form-control list-search" type="search" placeholder="Search" onChange={handleSearchInput} />
                                    <span className="input-group-text">
                                        <i className="fe fe-search"></i>
                                    </span>
                                </div>
                            </form>
                        </div>
                        {/* <div className="col-auto">
                            <div className="dropdown">
                                <button className="btn btn-sm btn-white" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
                                    <i className="fe fe-sliders me-1"></i> Filter <span className="badge bg-primary ms-1 d-none">0</span>
                                </button>
                                <form className="dropdown-menu dropdown-menu-end dropdown-menu-card">
                                    <div className="card-header">
                                        <h4 className="card-header-title">
                                            Filters
                                        </h4>
                                        <button className="btn btn-sm btn-link text-reset d-none" type="reset">
                                            <small>Clear filters</small>
                                        </button>
                                    </div>
                                    <div className="card-body">
                                        <div className="list-group list-group-flush mt-n4 mb-4">
                                            <div className="list-group-item">
                                                <div className="row">
                                                    <div className="col">
                                                        <small>Title</small>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="choices" data-type="select-one" tabIndex="0" role="listbox" aria-haspopup="true" aria-expanded="false">
                                                            <select className="form-select form-select-sm form-control" name="item-title" data-choices="{&quot;searchEnabled&quot;: false}" hidden="" tabIndex="-1" data-choice="active">
                                                                <option value="*" data-custom-properties="[object Object]">Any</option>
                                                                <option value="*" data-custom-properties="[object Object]">Designer</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="list-group-item">
                                                <div className="row">
                                                    <div className="col">
                                                        <small>Lead scrore</small>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="choices" data-type="select-one" tabIndex="0" role="listbox" aria-haspopup="true" aria-expanded="false">
                                                            <select className="form-select form-select-sm form-control" name="item-title" data-choices="{&quot;searchEnabled&quot;: false}" hidden="" tabIndex="-1" data-choice="active">
                                                                <option value="*" data-custom-properties="[object Object]">Any</option>
                                                                <option value="*" data-custom-properties="[object Object]">Designer</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="btn w-100 btn-primary" type="submit">
                                            Apply filter
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-sm table-hover card-table">
                        <thead >
                            <tr className='align-middle text-center'>
                                <th >
                                    <div className="text-muted">Student Name</div>
                                </th>
                                <th >
                                    <div className="text-muted ">Campaign Name</div>
                                </th>
                                {/* <th >
                                    <div className="text-muted ">Team Name</div>
                                </th> */}
                                <th>
                                    <div className="text-muted" >Catogery</div>
                                </th>
                                <th>
                                    <div className="text-muted" >Start Date</div>
                                </th>
                                <th>
                                    <div className="text-muted" >Action</div>
                                </th>

                            </tr>
                        </thead>
                        <tbody className="list fs-base ">
                            {displayData.map((campaign) => {
                                return (
                                    <tr key={campaign.id}>
                                        <td>
                                            <div className='text-center'>{campaign?.student?.firstName} {campaign?.student?.lastName}</div>
                                        </td>
                                        <td className='text-center'>
                                            {campaign.name}
                                        </td>
                                        {/* <td className='text-center'>
                                            {'Team ABC'}
                                        </td> */}

                                        <td>
                                            <div className='text-center'>
                                                {campaign?.category || campaign?.otherCategory}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='text-center'>
                                                {JSON.parse(campaign?.description)?.startedOn?.split(' ')[0]}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='text-center'>
                                                <div className="dropdown">
                                                    <div className="dropdown-ellipses dropdown-toggle text-mute" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="fe fe-more-vertical"></i>
                                                    </div>
                                                    <div className="dropdown-menu dropdown-menu-end">
                                                        <div className="dropdown-item" onClick={() => navigate(`/campaigns/${campaign?.id}`)}>
                                                            View
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>


                                        {/* <td onClick={(e) => e.stopPropagation()}>
                                                <div className='text-center form-switch' >
                                                    <input className="form-check-input" type="checkbox" role="switch" checked={e.status} />
                                                </div>
                                            </td> */}
                                    </tr>

                                )
                            })}

                        </tbody>
                    </table>
                </div>
                <Pagination PageData={filteredData} setDisplayData={setDisplayData} />
                {/* <div>
                    <div className="card-footer d-flex justify-content-between">
                        <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
                            <li className="page-item cursor-pointer">
                                <div className="page-link ps-0 pe-4 border-end ">
                                    <i className="fe fe-arrow-left me-1"></i> Prev
                                </div>
                            </li>
                        </ul>
                        <ul className="list-pagination pagination pagination-tabs card-pagination ">
                            <li className="page-item">
                                <div className="page-link" >
                                    1
                                </div>
                            </li>
                        </ul>

                        <ul className="list-pagination-next pagination pagination-tabs card-pagination">
                            <li className="page-item cursor-pointer" >
                                <div className="page-link ps-4 pe-0 border-start">
                                    Next <i className="fe fe-arrow-right ms-1"></i>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div> */}
            </div>
        </div>)
}

export default CampaignApprovedTable
