import React from 'react'
import { useContext, useEffect, useState } from 'react'
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage, NumberFormat } from 'formik';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ConfirmUser = () => {
    const [step, setStep] = useState(1);
    const [resetIntialValues, setResetIntialValues] = useState({ email: '', new_password: '', confirm_password: '', code: '' })
    const [passwordType, setPasswordType] = useState('password');

    const handlePasswordType = () => {
        if (passwordType === 'password') {
            setPasswordType('text');
        } else {
            setPasswordType('password');
        }
    }

    const navigate = useNavigate();

    const formSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email'),
        code: Yup.string().matches(/^[0-9]*$/, "Code must be number").required('Required'),
        new_password: Yup.string().required('Required'),
        confirm_password: Yup.string().required('Required').oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
    });

    const sendCode = (username) => {
        setResetIntialValues((prev) => ({ ...prev, email: username }))
        Auth.forgotPassword(username)
            .then((data) => {
                toast.success('Code sent to your mail id');
                setStep(2)
            })
            .catch((err) => console.log(err));
    }

    const submitPassword = (values) => {

        Auth.forgotPasswordSubmit(values.email, values.code, values.new_password)
            .then((data) => {
                toast.success('Password Updated', {
                    position: toast.POSITION.TOP_RIGHT
                }
                );
                navigate('/')
            })
            .catch((err) => { console.log(err) });

    }



    return (
        <div className="d-flex bg-auth " style={{ display: 'block' }}>
            <div className="container">
                <div className="row vh-100 align-items-center">
                    <div className="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0 d-none d-lg-block">
                        <div className="text-center">
                            <img src="img/login.svg" alt="..." className="img-fluid" />
                        </div>
                    </div>
                    {step == 1 ? (<div className="col-12 col-md-5 col-xl-4 order-md-1 my-5">
                        <h2 className="display-4 text-center mb-5">
                            Confirm User
                        </h2>
                        <Formik
                            initialValues={resetIntialValues}
                            validate={values => {
                                const errors = {};
                                if (!values.email) {
                                    errors.email = 'Required';
                                } else if (
                                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                ) {
                                    errors.email = 'Invalid email address';
                                }
                                return errors;
                            }}
                            onSubmit=
                            {(values) => {
                                sendCode(values.email)

                            }}
                        >
                            {({ handleChange, handleSubmit, values, errors }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className='form-group'>
                                        <label htmlFor='email' className='form-label'>Email address</label>
                                        <input type={'text'} className='form-control' id='email' name={'email'} value={values.email} onChange={handleChange} placeholder="name@address.com" />
                                        <span className='text-danger'>{errors.email}</span>
                                    </div>
                                    <div className=' form-group'>
                                        <button type='submit' className='btn btn-primary btn-lg w-100' >Send Code</button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>) : (
                        <div className="col-12 col-md-5 col-xl-4 order-md-1 my-5">
                            <h2 className="display-4 text-center mb-5">
                                Set Password
                            </h2>
                            <Formik
                                initialValues={resetIntialValues}
                                validationSchema={formSchema}
                                onSubmit=
                                {(values) => {
                                    submitPassword(values)
                                }}
                            >
                                {({ handleChange, handleSubmit, values, errors }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className='form-group'>
                                            <label htmlFor='code' className='form-label'>Code</label>
                                            <input type={'text'} id='code' className='form-control mfa-code' format='######' mask='_' placeholder='0 0 0 0 0 0' value={values.code} maxLength="6" onChange={handleChange} disabled={values.code.length > 6} />
                                            {/* <input type={'number'} className='form-control' id='code' value={values.code} onChange={handleChange} placeholder="" /> */}
                                            <span className='text-danger'>{errors.code}</span>
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='new_password' className='form-label'>New Password</label>
                                            <div className="input-group input-group-merge">
                                                <Field className="form-control" type={`${passwordType}`} id='new_password' value={values.new_password} placeholder="Enter new password" onChange={handleChange} />
                                                <span className="input-group-text">
                                                    <i className={(passwordType === 'password') ? `fe fe-eye` : 'fe fe-eye-off'} onClick={handlePasswordType}></i>
                                                </span>
                                            </div>
                                            <span className='text-danger'>{errors.new_password}</span>
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='confirm_password' className='form-label'>Confirm  Password</label>
                                            <div className="input-group input-group-merge">
                                                <Field className="form-control" type={`${passwordType}`} id='confirm_password' value={values.confirm_password} placeholder="Confirm password" onChange={handleChange} />
                                                <span className="input-group-text">
                                                    <i className={(passwordType === 'password') ? `fe fe-eye` : 'fe fe-eye-off'} onClick={handlePasswordType}></i>
                                                </span>
                                            </div>
                                            <span className='text-danger'>{errors.confirm_password}</span>
                                        </div>
                                        <div className=' form-group'>
                                            <button type='submit' className='btn btn-primary btn-lg w-100' >Set Password
                                            </button>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                            <div className=' form-group'>
                                <button type='submit' className='btn btn-primary btn-lg w-100' onClick={() => { sendCode(resetIntialValues.email) }}>Resend Code
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ConfirmUser
