import React from 'react'
import CustomCarousel from './customCarousel'
import { useState } from 'react';
import { Modal } from 'react-bootstrap';

const PublishedCard = ({ item, handleCreateActivityFeedComment, deleteActivity }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false) }
    const handleShow = () => setShow(true);
    const [addcomment, setAddComment] = useState('');

    const handleComments = (item) => {
        handleCreateActivityFeedComment(addcomment, item.id)
    }


    const setBg = () => {
        let backgroundImage = ['card_header-1.png', 'card_header-2.png']
        const randomImage = Math.floor(Math.random() * backgroundImage.length)
        return backgroundImage[randomImage]
    }

    return (
        <>
            <div className="card">
                {/* {item.type !== 'ADMIN' ? <div className='card-header text-center bg-cover' style={{ backgroundImage: `url(/img/${setBg()})` }}>
                    <h3 className='mb-0'>Challenge Name</h3 >
                </div> : ''} */}

                <div className="card-body">
                    <div className="mb-3">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <a href="#!" className="avatar">
                                    <img src="https://cdn2.psychologytoday.com/assets/styles/manual_crop_1_91_1_1528x800/public/2020-08/shutterstock_1731284125_0.jpg?itok=89UrdUt_" alt="..." className="avatar-img rounded-circle" />
                                </a>

                            </div>
                            {item?.owner?.role[0] !== 'ADMIN' ?
                                <div className="col ms-n2">
                                    <h4 className="mb-1">
                                        Team A
                                    </h4>
                                    <p className="card-text small text-muted">
                                        Some public School
                                        {/* <span className="fe fe-clock"></span> */}
                                        {/* <time dateTime="2018-05-24">4hr ago</time> */}
                                    </p>
                                </div> :
                                <div className="col ms-n2">
                                    <h3 className="mb-2">
                                        Scrappy Team
                                    </h3>
                                </div>}

                            <div className="col-auto">
                                <div className="dropdown">
                                    <div className="dropdown-ellipses dropdown-toggle text-mute" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fe fe-more-vertical"></i>
                                    </div>
                                    <div className="dropdown-menu dropdown-menu-end">
                                        <div className="dropdown-item" onClick={() => {
                                            deleteActivity(item.id)
                                        }}>
                                            Delete Post
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    {/* <p className="mb-3">
                    I've been working on shipping the latest version of Launchday. The story I'm trying to focus on is something like "You're launching soon and need to be 100% focused on your product. Don't lose precious days designing, coding, and testing a product site. Instead, build one in minutes."
                </p>

                <p className="mb-4">
                    What do you y'all think? Would love some feedback from <a href="#!" className="badge bg-primary-soft">@Ab</a> or <a href="#!" className="badge bg-primary-soft">@Adolfo</a>?
                </p>
                <p className="text-center mb-3">
                    <img src="https://dashkit.goodthemes.co/assets/img/posts/post-1.jpg" alt="..." className="img-fluid rounded" />
                </p> */}
                    <CustomCarousel item={item} />
                    <div className="mb-3">
                        <div className="row">
                            <div className="col">
                                <div className="btn btn-sm btn-white" onClick={() => { (item?.likes?.items?.length) && handleShow() }}>
                                    {/* 👍 2 */}
                                    💛 {item?.likes?.items?.length}
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="btn btn-sm btn-white">
                                    Share
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    {item?.comments?.items?.length ?
                        (<div className="comment mb-3">
                            <div className="row">
                                <div className="col-auto">
                                    <a className="avatar avatar-sm" href="profile-posts.html">
                                        <img src="https://dashkit.goodthemes.co/assets/img/avatars/profiles/avatar-1.jpg" alt="..." className="avatar-img rounded-circle" />
                                    </a>
                                </div>
                                <div className="col ms-n2">
                                    <div className="comment-body">
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="comment-title">
                                                    Admin
                                                </h5>
                                            </div>
                                            {/* <div className="col-auto">
                                                <time className="comment-time">
                                                    11:12
                                                </time>
                                            </div> */}
                                        </div>
                                        <p className="comment-text">
                                            {item?.comments?.items[0]?.comment}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>) :
                        (<div className="row p-2">
                            {/* <div className="col-auto">
                                <div className="avatar avatar-sm">
                                    <img src="https://dashkit.goodthemes.co/assets/img/avatars/profiles/avatar-1.jpg" alt="..." className="avatar-img rounded-circle" />
                                </div>
                            </div> */}
                            <div className="col ms-n2">
                                <form className="mt-1">
                                    <label className="visually-hidden">Leave a comment...</label>
                                    <textarea className="form-control form-control-flush" data-autosize="" rows="1" placeholder="Leave a comment" style={{ overflow: 'hidden', overflowWrap: 'break-word', height: '41px' }} value={addcomment} onChange={(e) => {
                                        setAddComment(e.target.value)
                                    }}></textarea>
                                </form>
                            </div>
                            <div className="col-auto align-self-end">
                                <div className="text-muted mb-2">
                                    <button className='btn btn-primary btn-sm ms-3' onClick={() => handleComments(item)}>Send</button>
                                </div>
                            </div>
                        </div>)
                    }
                </div>
            </div >
            <Modal show={show} onHide={handleClose} >
                <Modal.Header>
                    <h2 className='m-0'>Likes</h2>
                </Modal.Header>
                <Modal.Body className='modal-scroll'>
                    <div className="list-group list-group-flush my-n3">
                        {item?.likes?.items?.map((like) => (
                            <div className="list-group-item" key={like.id}>
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <div className="avatar ">
                                            <img src="https://dashkit.goodthemes.co/assets/img/avatars/profiles/avatar-4.jpg" alt="..." className="avatar-img rounded-circle" />
                                        </div>
                                    </div>
                                    <div className="col ms-n2">
                                        <h4 className="mb-1">
                                            {like?.user?.firstName} {like?.user?.lastName}
                                        </h4>
                                        <p className="card-text small text-muted">
                                            {like?.user?.schools?.items?.school?.name}
                                        </p>
                                    </div>

                                </div>
                            </div>))}
                    </div>
                </Modal.Body>
                <Modal.Footer className='p-3'>
                    <div className='d-flex justify-content-end gap-3 '>
                        <button type='button' className='btn btn-white' onClick={() => setShow(false)}>Close</button>
                        {/* <button type='submit' className='btn btn-primary' >Save</button> */}
                    </div>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default PublishedCard;
