import React from 'react'

const Report = () => {
    return (
        <div>
            <div className='card p-4'>
                <div className='d-flex justify-content-between'>
                    <div className='d-flex justify-content-between mb-3'>
                        <h3 className='m-0'>Scrappy Make Build/Report </h3>
                    </div>
                    <div className="form-check form-switch">
                        <input className="form-check-input bg-danger" type="checkbox" role="switch" />
                    </div>
                </div>
                <div className='mt-3'>
                    <div className='d-flex justify-content-between mb-3'>
                        <h3 className='m-0'>Title </h3>
                    </div>
                    <div className="row">
                        <div className="col">
                            <span className='fw-semibold'>
                                English
                            </span>
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <span className='fw-semibold'>
                                Hindi
                            </span>
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <span className='fw-semibold'>
                                Marathi
                            </span>
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <span className='fw-semibold'>
                                Kannada
                            </span>
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            </div>
                        </div>
                    </div>
                </div>

                <div className=' row mt-5'>
                    <div className='col d-flex justify-content-evenly'>
                        <div className='primary'>
                            <h3>Upload Images</h3>
                        </div>
                        <div className="form-check form-switch">
                            <input className="form-check-input bg-danger" type="checkbox" role="switch" />
                        </div>
                    </div>
                    <div className='col d-flex justify-content-evenly'>
                        <div className='primary'>
                            <h3>Upload Filed Notes</h3>
                        </div>
                        <div className="form-check form-switch">
                            <input className="form-check-input bg-danger" type="checkbox" role="switch" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Report
