import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getAvatarUrl } from '../helpers'

const GroupCard = ({ group }) => {

    const navigate = useNavigate();

    return (
        <div>
            <div className="card ">
                <div className="card-body text-center cursor-pointer" onClick={() => { navigate(`/team/${group.id}`) }}>
                    {/* <div className="dropdown card-dropdown" onClick={(event) => { event.stopPropagation() }}>
                        <div className="dropdown-ellipses dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fe fe-more-vertical"></i>
                        </div>
                        <div className="dropdown-menu dropdown-menu-end">
                            <div className="dropdown-item">
                                Edit
                            </div>
                            <div className="dropdown-item">
                                Delete
                            </div>
                        </div>
                    </div> */}
                    <div className="card-avatar avatar avatar-lg mx-auto">
                        <img src="https://cdn2.psychologytoday.com/assets/styles/manual_crop_1_91_1_1528x800/public/2020-08/shutterstock_1731284125_0.jpg?itok=89UrdUt_" alt="" className="avatar-img rounded" />
                    </div>
                    <h2 className="card-title cursor-pointer">
                        {group.name}
                    </h2>
                    <p className="card-text text-muted">
                        Group Members :{group?.members?.items?.length}
                    </p>
                </div>
                <div className="card-footer card-footer-boxed">
                    <div className="row align-items-center">
                        <div className="col">
                        </div>
                        <div className="col-auto">
                            <div className="avatar-group">
                                {group?.members?.items?.slice(0, 3).map((member) => (
                                    <div div className="avatar avatar-sm cursor-pointer" key={member.id} data-bs-toggle="tooltip" data-bs-placement="top" title={`${member?.student?.firstName} ${member?.student?.lastName}`}>
                                        <img src={getAvatarUrl(member?.student?.user?.avatar) || ''} alt="..." className="avatar-img rounded-circle border" />
                                    </div>))}
                                {(group?.members?.items?.length > 3) &&
                                    <div className="avatar avatar-sm">
                                        <span className="avatar-title rounded-circle">+ {group?.members?.items.length - 3}</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default GroupCard
