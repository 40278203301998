import React, { useEffect } from 'react'
import { useState } from 'react'
import { Modal } from 'react-bootstrap';
import UnPublishedCard from '../../components/unPublishedCard';
import { Storage } from 'aws-amplify';
import { createActivity } from '../../helpers';
import { customListChallengeActivities } from '../../customGraphql/queries';
import { API } from "aws-amplify";
import { toast } from 'react-toastify';
import { AuthContext } from '../../contexts/authContext'
import { useContext } from "react";



const MediaPreview = ({ mediaInput, setMediaInput }) => {
    const [show, setShow] = useState(false);
    const [modalData, setModalData] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const removeMediaInput = (e, key) => {
        e.stopPropagation()
        let mediaCopy = [...mediaInput];
        mediaCopy.splice(key, 1)
        setMediaInput(mediaCopy);
    }

    return (
        <>
            <div className='d-flex '>
                {mediaInput ? Object.keys(mediaInput).map((key) => {
                    // if (mediaInput[key].type == 'video/mp4') {
                    //     return (
                    //         <div className='m-2 d-flex position-relative' onClick={() => {
                    //             setModalData(
                    //                 <>
                    //                     <video width={'100%'} controls src={URL.createObjectURL(mediaInput[key])} ></video>
                    //                 </>
                    //             )
                    //             handleShow();
                    //         }}>
                    //             <video width='60px' src={URL.createObjectURL(mediaInput[key])} ></video>
                    //             <div className='position-absolute top-0 start-100 translate-middle'>
                    //                 <i className='fe fe-x-circle text-danger' />
                    //             </div>
                    //         </div>)
                    // }
                    // else {
                    let url = URL.createObjectURL(mediaInput[key]);
                    return (
                        <div className='m-2 d-flex position-relative' onClick={() => {
                            setModalData(
                                <>
                                    <img key={key} width={'100%'} src={URL.createObjectURL(mediaInput[key])} />
                                </>
                            );
                            handleShow();
                        }}>
                            <img key={key} src={url} width={'70px'} height={'50px'} className='cursor-pointer' />
                            <div className='position-absolute top-0 start-100 translate-middle' onClick={(e) => { removeMediaInput(e, key) }}>
                                <i className='fe fe-x-circle text-danger' />
                            </div>
                        </div>
                    )
                    // }
                }) : ''}
            </div>
            <Modal show={show} onHide={handleClose} centered>
                <div className='p-5 w-90 align-self-center'>
                    {modalData}
                </div>
            </Modal>

        </>
    )
}



const AddActivity = () => {
    const [mediaInput, setMediaInput] = useState([]);
    const [uploadContent, setUploadContent] = useState([]);
    const [inputText, setInputText] = useState('');
    const [unPublishedData, setUnPublishedData] = useState([]);
    const { user } = useContext(AuthContext);



    const handleMediaInput = (e) => {
        var filenames = Array.from(e.target.files).map(function (file) {
            return file;
        });

        setMediaInput(mediaInput.concat(filenames))
    }



    const handleText = ({ target }) => {
        setInputText(target.value)
    }

    const handlePost = async () => {
        if (!mediaInput.length) {
            toast.info('Please Attach Images', {
                position: toast.POSITION.TOP_RIGHT
            }
            )
        } else {
            const images = []
            for (let i = 0; i < mediaInput.length; i++) {
                const stored = await Storage.put(`activity-${Math.random().toString(36).substring(2, 15)}`, mediaInput[i], { contentType: mediaInput[i].type, });
                const url = await Storage.get(stored.key, { level: 'public' })
                images.push({
                    description: inputText,
                    image: url.split('?')[0]
                })
            }
            createActivity(JSON.stringify(images), user.id)
            setInputText('');
            setMediaInput([]);
            // setUploadContent([]);
            toast.success('Posted', {
                position: toast.POSITION.TOP_RIGHT
            }
            )
            // setUploadContent(images)
        }
    }


    const getChallenge = async () => {
        try {
            let data = await API.graphql({
                query: customListChallengeActivities,
                variables: { filter: { status: { eq: 'IN_REVIEW' } } }
            })
            changeData(data.data.listChallengeActivities.items)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => { getChallenge() }, [])


    const changeData = (data) => {
        let changedData = data.map((item) => {
            item.content = item.responses
            delete item.responses;
            return item
        })
        setUnPublishedData(changedData)

    }



    useEffect(() => {
        // if (uploadContent.length === mediaInput.length) {
        //     // let images = [];
        //     // for (let item of uploadContent) {
        //     //     images.push(JSON.stringify(item))
        //     // }
        //     createActivity(JSON.stringify(uploadContent))
        //     setInputText('');
        //     setMediaInput({});
        //     setUploadContent([]);
        //     toast.success('Posted', {
        //         position: toast.POSITION.TOP_RIGHT
        //     }
        //     )
        // }
    }, [uploadContent])



    return (
        <div>
            <div>
                <div className='card'>
                    <div className='card-body'>
                        <form>
                            <div className='form-group'>
                                <textarea className='form-control form-control-flush form-control-auto' data-autosize='' rows='3' placeholder='Start a post...' onChange={handleText} style={{ overflow: 'hidden', overflowWrap: 'break-word', height: '70px' }} value={inputText}></textarea>
                            </div>
                        </form>
                        <div className='row align-items-center'>
                            <div className='col'>
                                <MediaPreview mediaInput={mediaInput} setMediaInput={setMediaInput} />
                            </div>
                            <div className='col-auto'>
                                <div className='text-muted'>
                                    <label className='text-reset me-3 align-middle' data-bs-toggle='tooltip' htmlFor='image' aria-label='Add photo' data-bs-original-title='Add photo'>
                                        <i className='fe fe-paperclip'>
                                            <input type='file' multiple={true} id='image' name='image[]' accept=' image/*' onChange={handleMediaInput} />
                                        </i>
                                    </label>
                                    {/* <a className='text-reset me-3' href='#' data-bs-toggle='tooltip' aria-label='Attach file' data-bs-original-title='Attach file'>
                                        <i className='fe fe-paperclip'></i>
                                    </a>
                                    <i className='fe fe-camera '>
                                            <input type='file' id='image' name='image' accept='image/gif,image/jpeg,image/jpg,image/png' />
                                        </i>
                                    <a className='text-reset' href='#' data-bs-toggle='tooltip' aria-label='Record audio' data-bs-original-title='Record audio'>
                                        <i className='fe fe-mic'></i>
                                    </a> */}
                                    <button className='btn btn-primary btn-sm ms-2' onClick={() => { handlePost() }}>Post</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {unPublishedData.map((item) => {
                return (<UnPublishedCard item={item} key={item.id} />)
            })}
        </div>

    )
}

export default AddActivity
