import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Pagination from '../../components/pagination';
import { RequestData } from '../../data/requestData';
import { listSchools } from '../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { updateSchool } from '../../graphql/mutations';
import { getSchoolMediumEnum } from '../../customGraphql/queries'
import { toast } from 'react-toastify';
import Select from 'react-select';


const RequestTable = () => {
    const [searchInput, setSearchInput] = useState('');
    const [apiData, setApiData] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [show, setShow] = useState(false);

    const handleClose = () => { setShow(false); setFormData({}); }
    const handleShow = () => setShow(true);
    const [mediumOptions, setMediumOptions] = useState([]);

    const [formData, setFormData] = useState({});


    const formSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        address1: Yup.string().required('Required'),
        address2: Yup.string(),
        type: Yup.string().required('Required'),
        city: Yup.string().required('Required'),
        state: Yup.string().required('Required'),
        postal_code: Yup.number().required('Required'),
        zone: Yup.string(),
        medium: Yup.array().required('Required'),
        stage: Yup.string().required('Required'),
        lat_lng: Yup.string(),
        principal_name: Yup.string(),
        principal_phone: Yup.string(),
        conatact_name: Yup.string(),
        contact_phone: Yup.string(),
        school_email: Yup.string()
    })

    // const handleformData = (e) => {
    //     // let data = { ...e }
    //     // data.city = e.address.city
    //     // data.state = e.address.state
    //     // data.pincode = e.address.pincode
    //     // data.address_1 = e.address.address_1
    //     // data.address_2 = e.address.address_2
    //     // data.zone = e.address.zone

    //     console.log('test', e)
    //     setFormData(e);
    // }

    const handleSubmitData = (values) => {
        let data = { ...values };
        data.address.city = values.city;
        data.address.state = values.state;
        data.address.postal_code = values.postal_code;
        data.address.address_1 = values.address_1;
        data.address.address_2 = values.address_2;
        data.address.zone = values.zone;
        data.address = JSON.stringify(data.address);
        data.medium = data.medium.map((language) => (language.value));

        let primaryContact = {}
        primaryContact.principal_name = data.principal_name;
        delete data.principal_name
        primaryContact.principal_phone = data.principal_phone;
        delete data.principal_phone;
        primaryContact.contact_name = data.contact_name;
        delete data.contact_name;
        primaryContact.contact_phone = data.contact_phone;
        delete data.contact_phone
        primaryContact.school_email = data.school_email;
        delete data.school_email;
        data.primaryContact = JSON.stringify(primaryContact);

        handleUpdateSchool(data)
    }


    const getRequestedSchools = async () => {
        try {
            const data = await API.graphql({
                query: listSchools,
                variables: { filter: { approved: { eq: false } } }
            })
            let resultData = data?.data?.listSchools?.items;
            for (let school of resultData) {
                school.address = JSON.parse(school.address)
            }
            setApiData(resultData)
            handleSearchResult(resultData)
        } catch (error) {
            console.log(error)
        }
    }

    const handleUpdateSchool = async (school) => {
        try {
            let data = await API.graphql({
                query: updateSchool,
                variables: {
                    input: {
                        id: school.id,
                        name: school.name,
                        medium: school.medium,
                        stage: school.stage,
                        type: school.type,
                        address: school.address,
                        approved: true,
                        address1: school.address1,
                        address2: school.address2,
                        city: school.city,
                        state: school.state,
                        zone: school.zone,
                        postal_code: school.postal_code,
                        primaryContact: school.primaryContact
                    }
                }
            })
            toast.success('School Approved')
            handleClose()
            getRequestedSchools()
        } catch (error) {
            console.log(error)
        }
    }

    const getMediumEnum = async () => {
        API.graphql(graphqlOperation(getSchoolMediumEnum)).
            then((response) => {
                let temp = response.data.__type.enumValues
                    .map((enumValue) => ({ value: enumValue.name, label: enumValue.name }));
                setMediumOptions(temp)
            });
    }


    useEffect(() => {
        getRequestedSchools()
        getMediumEnum()
    }, [])


    useEffect(() => {
        handleSearchResult(apiData)
    }, [searchInput])


    const handleSearchResult = (data) => {
        let result = data
            .filter(item =>
                item.name.toLowerCase().includes(searchInput)
                ||
                item.address.toLowerCase().includes(searchInput)
                ||
                item.students?.items[0]?.firstName.toLowerCase().includes(searchInput)
                ||
                item.students?.items[0]?.lastName.toLowerCase().includes(searchInput)
            )
        setFilteredData(result)

    }

    const handleSearchInput = ({ target }) => {
        setSearchInput(target.value.toLowerCase())
    }

    return (
        <div>
            <div className="card" id="contactsList">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <form>
                                <div className="input-group input-group-flush input-group-merge input-group-reverse">
                                    <input className="form-control list-search" type="search" placeholder="Search" onChange={handleSearchInput} />
                                    <span className="input-group-text">
                                        <i className="fe fe-search"></i>
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-sm table-hover card-table">
                        <thead >
                            <tr className='align-middle text-center'>
                                {/* <th >
                                    <div className="text-muted">Student Requested</div>
                                </th> */}
                                <th >
                                    <div className="text-muted ">School Name</div>
                                </th>
                                <th>
                                    <div className="text-muted" >Address</div>
                                </th>
                                <th>
                                    <div className="text-muted" >Ward</div>
                                </th>
                                <th>
                                    <div className="text-muted" >Info</div>
                                </th>
                                <th>
                                    <div className="text-muted" >Action</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="list fs-base">
                            {displayData.length ?
                                displayData.map((e) => {
                                    console.log("test", e)
                                    return (
                                        <tr key={e.id}>
                                            {/* <td>
                                                <div className='text-center'>{e.students?.items[0]?.firstName} {e.students?.items[0]?.lastName}</div>
                                            </td> */}
                                            <td className='table-col-fixed-width'>
                                                <div className='text-center'>
                                                    {e.name}
                                                </div>
                                            </td>
                                            <td className='text-center table-col-fixed-width'>
                                                <div >
                                                    {e.address1}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-center'>
                                                    ABC
                                                </div>
                                            </td>
                                            <td className='text-center text-muted'>
                                                <div className="dropdown">
                                                    <div className="dropdown-ellipses dropdown-toggle text-mute" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className='fe fe-eye'></i>
                                                    </div>
                                                    <div className="dropdown-menu dropdown-menu-end">
                                                        <div className="dropdown-item">
                                                            <div className='col'>
                                                                <h5>
                                                                    School Email:
                                                                </h5>
                                                                <p className='text-muted'>
                                                                    123@gmail.com
                                                                </p>
                                                            </div>
                                                            <div className='col'>
                                                                <h5>
                                                                    Contact Person Name
                                                                </h5>
                                                                <p className='text-muted'>
                                                                    Some Name
                                                                </p>
                                                            </div>
                                                            <div className='col'>
                                                                <h5>
                                                                    Contact Person Phone
                                                                </h5>
                                                                <p className='text-muted'>
                                                                    9988998899
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td >
                                                <div className='text-center' >
                                                    <button type="button" className="btn bg-success-soft btn-sm m-3" onClick={() => { setFormData(e); handleShow() }}>
                                                        Approve
                                                    </button>
                                                    {/* <button type="button" className="btn bg-danger-soft btn-sm">
                                                        <i className='fe fe-x'></i>
                                                    </button> */}
                                                </div>
                                            </td>
                                        </tr>

                                    )
                                })
                                : (
                                    <tr className=' text-center text-muted'>
                                        <td colSpan={'6'}>
                                            <h3 className='m-0'>
                                                No data found
                                            </h3>
                                        </td>
                                    </tr>
                                )

                            }


                        </tbody>
                    </table>
                </div>
                <Pagination PageData={filteredData} setDisplayData={setDisplayData} />
            </div>
            <Modal show={show} onHide={handleClose} >
                <Modal.Header>
                    <h2 className='m-0'>Add School</h2>
                </Modal.Header>
                <Modal.Body>
                    <Formik enableReinitialize={true} initialValues={formData} validationSchema={formSchema} onSubmit={handleSubmitData}>
                        {({ handleChange, setFieldValue, handleSubmit, values, errors }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor='name' className='form-label'>School Name *</label>
                                    <input type={'text'} className='form-control' id='name' value={values.name} onChange={handleChange} />
                                    <span className='text-danger'>{errors.name}</span>
                                </div>
                                <div className='from-group row'>
                                    <div className="col form-group">
                                        <label htmlFor='type' className='form-label'>School Type <span className='text-danger'>*</span></label>
                                        <select className="form-select" as='select' id='type' defaultValue={values.type} onChange={handleChange}>
                                            <option selected>Select School Type</option>
                                            <option value="PUBLIC">Public</option>
                                            <option value="PRIVATE">Private</option>
                                        </select>
                                        <span className='text-danger'>{errors.type}</span>
                                    </div>
                                    <div className="col form-group">
                                        <label htmlFor='stage' className='form-label'>Stage <span className='text-danger'>*</span></label>
                                        <select className="form-select" as='select' id='stage' defaultValue={values.stage} onChange={handleChange}>
                                            <option value=''>Select School Stage</option>
                                            <option value="PRIMARY">Primary</option>
                                            <option value="SECONDARY">Secondary</option>
                                            <option value="SENIOR_SECONDARY">Senior_Secondary</option>
                                        </select>
                                        <span className='text-danger'>{errors.stage}</span>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor='school_email' className='form-label'>School Email</label>
                                    <input type={'text'} className='form-control' id='school_email' defaultValue={values.school_email} onChange={handleChange} />
                                    <span className='text-danger'>{errors.school_email}</span>
                                </div>
                                <div className='row form-group'>
                                    <div className='col'>
                                        <label htmlFor='zone' className='form-label'>Zone</label>
                                        <input type={'text'} className='form-control' id='zone' defaultValue={values.zone} onChange={handleChange} />
                                        <span className='text-danger'>{errors.zone}</span>
                                    </div>
                                    <div className='col'>
                                        <label htmlFor='medium' className='form-label'>Medium <span className='text-danger'>*</span></label>
                                        <Select
                                            defaultValue={mediumOptions.filter((medium) => (values?.medium?.find((e) => (e === medium?.value))))}
                                            isMulti
                                            id='medium'
                                            name="colors"
                                            options={mediumOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={(e) => { setFieldValue('medium', e) }}
                                        />
                                        <span className='text-danger'>{errors.medium}</span>
                                    </div>
                                </div>
                                <div className='row form-group'>
                                    <div className='col'>
                                        <label htmlFor='address1' className='form-label'>Address-1 <span className='text-danger'>*</span></label>
                                        <textarea className="form-control" id="address1" defaultValue={values.address1} onChange={handleChange} ></textarea>
                                        <span className='text-danger'>{errors.address1}</span>
                                    </div>
                                    <div className='col'>
                                        <label htmlFor='address2' className='form-label'>Address-2</label>
                                        <textarea className="form-control" id="address2" defaultValue={values.address2} onChange={handleChange} ></textarea>
                                        <span className='text-danger'>{errors.address2}</span>
                                    </div>
                                </div>
                                <div className='row form-group'>
                                    <div className='col'>
                                        <label htmlFor='city' className='form-label'>City <span className='text-danger'>*</span></label>
                                        <input type={'text'} className='form-control' id='city' defaultValue={values.city} onChange={handleChange} />
                                        <span className='text-danger'>{errors.city}</span>
                                    </div>
                                    <div className='col'>
                                        <label htmlFor='state' className='form-label'>State <span className='text-danger'>*</span></label>
                                        <input type={'text'} className='form-control' id='state' defaultValue={values.state} onChange={handleChange} />
                                        <span className='text-danger'>{errors.state}</span>
                                    </div>
                                    <div className='col'>
                                        <label htmlFor='postal_code' className='form-label'>Pincode <span className='text-danger'>*</span></label>
                                        <input type={'number'} className='form-control' id='postal_code' defaultValue={values.postal_code} onChange={handleChange} />
                                        <span className='text-danger'>{errors.postal_code}</span>
                                    </div>
                                </div>
                                <div className='row form-group'>
                                    <div className='col'>
                                        <label htmlFor='principal_name' className='form-label'>Name Of Principal</label>
                                        <input type={'text'} className="form-control" id="principal_name" defaultValue={values.principal_name} onChange={handleChange} />
                                        <span className='text-danger'>{errors.principal_name}</span>
                                    </div>
                                    <div className='col'>
                                        <label htmlFor='principal_phone' className='form-label'>Principal Phone Number</label>
                                        <input type={'number'} className="form-control" id="principal_phone" defaultValue={values.principal_phone} onChange={handleChange} />
                                        <span className='text-danger'>{errors.principal_phone}</span>
                                    </div>
                                </div>
                                <div className='row form-group'>
                                    <div className='col'>
                                        <label htmlFor='contact_name' className='form-label'>Name Of Contact Person</label>
                                        <input type={'text'} className="form-control" id="contact_name" defaultValue={values.contact_name} onChange={handleChange} />
                                        <span className='text-danger'>{errors.contact_name}</span>
                                    </div>
                                    <div className='col'>
                                        <label htmlFor='contact_phone' className='form-label'>Contact Person Phone Number</label>
                                        <input type={'number'} className="form-control" id="contact_phone" defaultValue={values.contact_phone} onChange={handleChange} />
                                        <span className='text-danger'>{errors.contact_phone}</span>
                                    </div>
                                </div>

                                <div className='mt-5'>
                                    <div className='d-flex justify-content-end gap-3'>
                                        <button type='button' className='btn btn-white' onClick={() => setShow(false)}>Cancel</button>
                                        <button type='submit' className='btn btn-primary' >Submit</button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </div >
    )
}

export default RequestTable



