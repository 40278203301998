import { API } from "aws-amplify";
import { createActivityFeed } from "../graphql/mutations";
import { listChallengeActivities, listGroupMembers, } from '../graphql/queries';
import { customListChallengeActivities, listSchoolWithSchoolUsers } from '../customGraphql/queries';
import { createTransaction, updateChallengeActivity } from '../graphql/mutations';
import { toast } from 'react-toastify';



export const createActivity = async (media, id) => {
    try {
        const data = await API.graphql({
            query: createActivityFeed,
            variables: {
                input: {
                    media,
                    activityFeedOwnerId: id,
                    active: 'true'
                }
            }
        })
    } catch (error) {
        console.log('create', error)
    }
}

export const handleGetGroupMembers = async (challenge) => {
    try {
        let res = await API.graphql({
            query: listGroupMembers,
            variables: {
                filter: { groupId: { eq: challenge.groupId } }
            }
        })
        let memberPoints = Math.floor(challenge.challenge.point / res.data.listGroupMembers.items.length);
        for (const member of res.data.listGroupMembers.items) {
            handleTransaction(challenge, member, memberPoints)
        }
        handleUpdateChallengeActivity(challenge.id)
    } catch (error) {
        console.log(error)
    }
}

const handleTransaction = async (challenge, member, point) => {
    try {
        await API.graphql({
            query: createTransaction,
            variables: {
                input: {
                    challengeId: challenge.challengeId,
                    studentId: member.studentId,
                    point: point
                }
            }
        })
    } catch (error) {
        console.log(error);
    }
}

const handleUpdateChallengeActivity = async (id) => {
    try {
        await API.graphql({
            query: updateChallengeActivity,
            variables: {
                input: {
                    id,
                    status: "APPROVED"
                }
            }
        })
        toast.success("Approved");

    } catch (error) {
        console.log(error)
    }
}

export const getSchoolsListWithSchoolUser = async (schoolUserId) => {
    try {
        let res = await API.graphql({
            query: listSchoolWithSchoolUsers,
            variables: {
                filter: { userId: { eq: schoolUserId } }
            }
        })
        let Schools = [];
        for (let school of res?.data?.listSchoolUsers?.items) {
            Schools.push(school.school)
        }
        return Schools
    } catch (error) {
        console.log(error)
    }
}

export const getListGroupsWithSchoolUser = async (schoolUserId) => {
    try {
        let res = await API.graphql({
            // query:
        })

    } catch (error) {
        console.log(error)
    }
}

export const getAvatarUrl = (avatar) => {
    switch (avatar) {
        case 'UPCYCLE':
            return ('https://scrappy-afe2cd64144028-staging.s3.ap-south-1.amazonaws.com/public/avatar/UPCYCLE.png')
            break;
        case 'TOTTO_KUMARI':
            return ('https://scrappy-afe2cd64144028-staging.s3.ap-south-1.amazonaws.com/public/avatar/TOTTO_KUMARI.png')
            break;
        case 'THINKER':
            return ('https://scrappy-afe2cd64144028-staging.s3.ap-south-1.amazonaws.com/public/avatar/THINKER.png')
            break;
        case 'Avatar.SCRAAP_1':
            return ('https://scrappy-afe2cd64144028-staging.s3.ap-south-1.amazonaws.com/public/avatar/SCRAAP_1.png')
            break;
        case 'MOUNTAIN_CLIMBER':
            return ('https://scrappy-afe2cd64144028-staging.s3.ap-south-1.amazonaws.com/public/avatar/MOUNTAIN_CLIMBER.png')
            break;
        case 'GARBAGE_COLLECTOR':
            return ('https://scrappy-afe2cd64144028-staging.s3.ap-south-1.amazonaws.com/public/avatar/GARBAGE_COLLECTOR.png')
            break;
        case 'FIXER':
            return ('https://scrappy-afe2cd64144028-staging.s3.ap-south-1.amazonaws.com/public/avatar/FIXER.png')
            break;
        case 'CLEAN_ENERGY_DESIGNER':
            return ('https://scrappy-afe2cd64144028-staging.s3.ap-south-1.amazonaws.com/public/avatar/CLEAN_ENERGY_DESIGNER.png')
            break;
        default:
            break;
    }
}



