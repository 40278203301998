import React from 'react'
import UploadImageAndFieldNotes from './uploadImageAndFieldNotes'
import Question from './question'

const Build = () => {
    return (
        <div>
            <div className='card p-4'>
                <div>
                    <div className='d-flex justify-content-between mb-3'>
                        <h3 className='m-0'>Scrappy Make Build </h3>
                    </div>

                </div>
                <div className='mt-3'>
                    <div className='d-flex justify-content-between mb-3'>
                        <h3 className='m-0'>Title </h3>
                    </div>
                    <div className="row">
                        <div className="col">
                            <span className='fw-semibold'>
                                English
                            </span>
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <span className='fw-semibold'>
                                Hindi
                            </span>
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <span className='fw-semibold'>
                                Marathi
                            </span>
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <span className='fw-semibold'>
                                Kannada
                            </span>
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-5'>
                    <div className='d-flex justify-content-between mb-3'>
                        <h3 className='m-0'>Description </h3>
                    </div>
                    <div className="row">
                        <div className="col">
                            <span className='fw-semibold'>
                                English
                            </span>
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <span className='fw-semibold'>
                                Hindi
                            </span>
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <span className='fw-semibold'>
                                Marathi
                            </span>
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <span className='fw-semibold'>
                                Kannada
                            </span>
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=' row mt-5'>
                    <div className='col d-flex justify-content-evenly'>
                        <div className='primary'>
                            <h3>Upload Images</h3>
                        </div>
                        <div className="form-check form-switch">
                            <input className="form-check-input bg-danger" type="checkbox" role="switch" />
                        </div>
                    </div>
                    <div className='col d-flex justify-content-evenly'>
                        <div className='primary'>
                            <h3>Upload Filed Notes</h3>
                        </div>
                        <div className="form-check form-switch">
                            <input className="form-check-input bg-danger" type="checkbox" role="switch" />
                        </div>
                    </div>

                </div>
            </div>
            <div className='card'>
                <div className="card-header" style={{ backgroundColor: '#F0F6FD' }}>
                    <div className='d-flex justify-content-between' >
                        <h3>Upload Image & Field Note</h3>
                        <p className='fw-bolder text-primary mb-0 cursor-pointer'>
                            <i className='fe fe-plus fw-bolder'></i> Add more
                        </p>
                    </div>
                </div>
                <div className="card-body">
                    < UploadImageAndFieldNotes />
                </div>
            </div>
            <div className='card p-4'>
                <div >
                    <div className='d-flex justify-content-between mb-3'>
                        <h3 className='m-0'>Description </h3>
                    </div>
                    <div className="row">
                        <div className="col">
                            <span className='fw-semibold'>
                                English
                            </span>
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <span className='fw-semibold'>
                                Hindi
                            </span>
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <span className='fw-semibold'>
                                Marathi
                            </span>
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <span className='fw-semibold'>
                                Kannada
                            </span>
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className=' row mt-5'>
                        <div className='col d-flex justify-content-evenly'>
                            <div className='primary'>
                                <h3>Table 1</h3>
                            </div>
                            <div className="form-check form-switch">
                                <input className="form-check-input bg-danger" type="checkbox" role="switch" />
                            </div>
                        </div>
                        <div className='col d-flex justify-content-evenly'>
                            <div className='primary'>
                                <h3>Table 2</h3>
                            </div>
                            <div className="form-check form-switch">
                                <input className="form-check-input bg-danger" type="checkbox" role="switch" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='card'>
                <div className='p-4'>
                    <div className='d-flex justify-content-between mb-3'>
                        <h3 className='m-0'>Title </h3>
                    </div>
                    <div className="row">
                        <div className="col">
                            <span className='fw-semibold'>
                                English
                            </span>
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <span className='fw-semibold'>
                                Hindi
                            </span>
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <span className='fw-semibold'>
                                Marathi
                            </span>
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <span className='fw-semibold'>
                                Kannada
                            </span>
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='p-4' style={{ backgroundColor: '#F0F6FD' }}>
                    <div className='d-flex justify-content-between' >
                        <h3>Questions</h3>
                        <p className='fw-bolder text-primary mb-0 cursor-pointer'>
                            <i className='fe fe-plus fw-bolder'></i> Add questions
                        </p>
                    </div>
                </div>
                <div className='p-4'>
                    <Question />
                </div>
            </div>
        </div>
    )
}

export default Build
