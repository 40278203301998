import React from 'react'
import { SchoolMembersData } from '../../data/schoolMembersData';
import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Pagination from '../../components/pagination';
import { API } from 'aws-amplify';
import { listStudentsWithGroup } from '../../customGraphql/queries'


const SchoolStudents = ({ schoolId }) => {
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [apiData, setApiData] = useState([]);

    // Modal
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false) }
    const handleShow = () => setShow(true);
    let something = false;


    const handleSearchInput = ({ target }) => {
        setSearchInput(target.value.toLowerCase())
    }

    useEffect(() => {
        handleSearchResult(apiData)
    }, [searchInput])

    const handleSearchResult = (data) => {
        let result = data
        // .filter(item =>
        //     item.name.toLowerCase().includes(searchInput) ||
        //     item.school_name.toLowerCase().includes(searchInput))
        setFilteredData(result)

    }

    const getStudents = async () => {
        try {
            let res = await API.graphql({
                query: listStudentsWithGroup,
                variables: {
                    filter: {
                        schoolId: { eq: schoolId }
                    }
                }
            })
            handleSearchResult(res.data.listStudents.items)
            setApiData(res.data.listStudents.items)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getStudents()
    }, [])


    return (
        <div className='mt-5'>
            <div className='card' data-list='{&quot;valueNames&quot;: [&quot;item-name&quot;, &quot;item-title&quot;, &quot;item-email&quot;, &quot;item-phone&quot;, &quot;item-score&quot;, &quot;item-company&quot;], &quot;page&quot;: 10, &quot;pagination&quot;: {&quot;paginationClass&quot;: &quot;list-pagination&quot;}}' id='contactsList'>
                <div className='card-header'>
                    <div className='row align-items-center'>
                        <div className='col'>
                            <form>
                                <div className='input-group input-group-flush input-group-merge input-group-reverse'>
                                    <input className='form-control list-search' type='search' placeholder='Search' onChange={handleSearchInput} />
                                    <span className='input-group-text'>
                                        <i className='fe fe-search'></i>
                                    </span>
                                </div>
                            </form>
                        </div>
                        <div className='col-auto'>
                            <div className='dropdown'>
                                <button className='btn btn-sm btn-white' type='button' data-bs-toggle='dropdown' data-bs-auto-close='outside' aria-haspopup='true' aria-expanded='false'>
                                    <i className='fe fe-sliders me-1'></i> Filter <span className='badge bg-primary ms-1 d-none'>0</span>
                                </button>
                                <form className='dropdown-menu dropdown-menu-end dropdown-menu-card'>
                                    <div className='card-header'>
                                        <h4 className='card-header-title'>
                                            Filters
                                        </h4>
                                        <button className='btn btn-sm btn-link text-reset d-none' type='reset'>
                                            <small>Clear filters</small>
                                        </button>
                                    </div>
                                    <div className='card-body'>
                                        <div className='list-group list-group-flush mt-n4 mb-4'>
                                            <div className='list-group-item'>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <small>Title</small>
                                                    </div>
                                                    <div className='col-auto'>
                                                        <div className='choices' data-type='select-one' tabIndex='0' role='listbox' aria-haspopup='true' aria-expanded='false'>
                                                            <select className='form-select form-select-sm form-control' name='item-title' data-choices='{&quot;searchEnabled&quot;: false}' hidden='' tabIndex='-1' data-choice='active'>
                                                                <option value='*' data-custom-properties='[object Object]'>Any</option>
                                                                <option value='*' data-custom-properties='[object Object]'>Designer</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='list-group-item'>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <small>Lead scrore</small>
                                                    </div>
                                                    <div className='col-auto'>
                                                        <div className='choices' data-type='select-one' tabIndex='0' role='listbox' aria-haspopup='true' aria-expanded='false'>
                                                            <select className='form-select form-select-sm form-control' name='item-title' data-choices='{&quot;searchEnabled&quot;: false}' hidden='' tabIndex='-1' data-choice='active'>
                                                                <option value='*' data-custom-properties='[object Object]'>Any</option>
                                                                <option value='*' data-custom-properties='[object Object]'>Designer</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button className='btn w-100 btn-primary' type='submit'>
                                            Apply filter
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='table-responsive'>
                    <table className='table table-sm table-hover table-nowrap card-table'>
                        <thead >
                            <tr className='align-middle text-center'>
                                {/* <th >
                                    <div className='text-muted '>Id</div>
                                </th> */}
                                <th >
                                    <div className='text-muted '>Student Name</div>
                                </th>
                                <th>
                                    <div className='text-muted' >Phone</div>
                                </th>
                                <th>
                                    <div className='text-muted' >Grade</div>
                                </th>
                                <th>
                                    <div className='text-muted' >Team Name</div>
                                </th>
                                <th>
                                    <div className='text-muted' >Action</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className='list fs-base'>
                            {displayData.map((student) => {
                                return (
                                    <tr key={student.id}>

                                        <td>
                                            <div className='text-center'>
                                                {student.firstName}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='text-center'>
                                                {9988998899}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='text-center'>
                                                {student.grade}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='text-center'>
                                                {student.hasGroup ? (student?.groups?.items?.group?.name) : `Not Assigned`}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='text-center'>
                                                <div className="dropdown">
                                                    <div className="dropdown-ellipses dropdown-toggle text-mute" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="fe fe-more-vertical"></i>
                                                    </div>

                                                    <div className="dropdown-menu dropdown-menu-end cursor-pointer">
                                                        {(!student.hasGroup) ? <div className="dropdown-item" onClick={handleShow}>
                                                            Assign Team
                                                        </div> : <div className="dropdown-item">
                                                            Reassign Team
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </div>
                <Pagination PageData={filteredData} setDisplayData={setDisplayData} />
            </div>
            <Modal size="xl" show={show} onHide={handleClose} >

                <Modal.Header>
                    <h2 className='m-0'>Map To A Group</h2>
                </Modal.Header>
                <Modal.Body className='modal-scroll'>
                    <div className='row row-cols-1 row-cols-md-2 row-cols-lg-5 g-3'>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 21].map((e) => (
                            <div className="col">
                                <div className='card'>
                                    <div className="card-body">
                                        <div>
                                            <p className='text-muted mb-1 fs-6'>TEAM NAME</p>
                                            <h4>Some Team Name</h4>
                                        </div>
                                        <div>
                                            <p>Total Members: {7}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>))}
                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <div className='d-flex justify-content-end gap-3'>
                        <button type='button' className='btn btn-white' onClick={() => setShow(false)}>Cancel</button>
                        <button type='submit' className='btn btn-primary' >Map</button>
                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

export default SchoolStudents
