import { useState } from "react"
import LeaderBoardTable from "./leaderBoardTable"
import UpcomingStars from "./upcomingStars"

const PointsManagement = () => {

    const [display, setDisplay] = useState(1)

    return (
        <div className='container-fluid'>
            <div className="header ">
                <div className="header-body">
                    <div className="row align-items-center">
                        <div className="col">
                            <h2 className="header-title">
                                Points Management
                            </h2>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col">
                            <ul className="nav nav-tabs nav-overflow header-tabs">
                                <li className="nav-item">
                                    <div className={`nav-link ${(display === 1) ? 'active' : ''}`} onClick={() => { setDisplay(1) }}>
                                        Leaderboard
                                    </div>
                                </li>
                                <li className="nav-item ">
                                    <div className={`nav-link ${(display === 2) ? 'active' : ''}`} onClick={() => { setDisplay(2) }}>
                                        Upcoming Scrappy Stars
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {(display === 1) ? <LeaderBoardTable /> : <UpcomingStars />}
            </div>
        </div>
    )
}

export default PointsManagement