import React from 'react'
import { MdOutlineAutoAwesomeMosaic, MdOutlineCampaign, MdOutlineTimeline, MdOutlineSchool, MdPersonOutline, MdOutlineGamepad, MdOutlineStarBorder, MdOutlineShoppingBag, MdOutlineArticle, MdOutlineLogout, MdOutlineGroups } from 'react-icons/md'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useContext } from 'react';
import { AuthContext } from '../contexts/authContext';

const Sidebar = () => {
    const location = useLocation();
    const { user } = useContext(AuthContext)
    const navigate = useNavigate();


    async function signOut() {
        try {
            await Auth.signOut();
            navigate('/')
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    return (
        <nav className='navbar navbar-vertical fixed-start navbar-expand-md navbar-light' id='sidebar' >
            <div className='container-fluid'>
                <button className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#sidebarCollapse' aria-controls='sidebarCollapse' aria-expanded='false' aria-label='Toggle navigation'>
                    <span className='navbar-toggler-icon'></span>
                </button>
                <Link className='navbar-brand p-2' to='/'>
                    <h1>Scrappy</h1>
                </Link>
                <div className='navbar-user d-md-none'>
                </div>
                <div className='collapse navbar-collapse' id='sidebarCollapse'>
                    <ul className='navbar-nav'>
                        <li className='nav-item'>
                            <Link className={`nav-link ${location.pathname.includes('dashboard') ? 'active' : ''}`} to='/dashboard' role='button' aria-expanded='false'>
                                <i className='fe fs-2 pb-2'> < MdOutlineAutoAwesomeMosaic /></i> Dashboards
                            </Link>

                        </li>
                        <li className='nav-item'>
                            <Link className={`nav-link ${location.pathname.includes('activity') ? 'active' : ''}`} to={'/activity-feed'} role='button' aria-expanded='false' aria-controls='sidebarPages'>
                                <i className='fe fs-2 pb-2'><MdOutlineTimeline /></i> Activity Feeds
                            </Link>

                        </li>
                        <li className='nav-item'>
                            <Link className={`nav-link ${location.pathname.includes('school') ? 'active' : ''}`} to={'/schools'} role='button' aria-expanded='false' aria-controls='sidebarAuth'>
                                <i className='fe fs-2 pb-2'><MdOutlineSchool /></i> Schools
                            </Link>
                        </li>
                        {user.role === 'ADMIN' &&
                            <li className='nav-item'>
                                <Link className={`nav-link ${location.pathname.includes('users') ? 'active' : ''}`} to={'/users'} role='button' aria-expanded='false' aria-controls='sidebarAuth'>
                                    <i className='fe fs-2 pb-2'><MdPersonOutline /></i> Users
                                </Link>
                            </li>
                        }
                        <li className='nav-item'>
                            <Link className={`nav-link ${location.pathname.includes('team') ? 'active' : ''}`} to={'/teams'} role='button' aria-expanded='false' aria-controls='sidebarAuth'>
                                <i className='fe fs-2 pb-2'><MdOutlineGroups /></i> Teams
                            </Link>
                        </li>
                        {/* <li className='nav-item'>
                            <a className='nav-link  collapsed' href='#Challenge-Management' data-bs-toggle='collapse' role='button' aria-expanded='false' aria-controls='Challenge-Management'>
                                <i className='fe fs-2'> < MdOutlineGamepad /></i> Challenge Management
                            </a>
                            <div className='collapse ' id='Challenge-Management'>
                                <ul className='nav nav-sm flex-column'>
                                    <li className='nav-item'>
                                        <Link to={'/challenge-management/challenges'} className={`nav-link ${location.pathname === '/login' ? 'active' : ''}`}>
                                            Manage Challenges
                                        </Link>
                                    </li>
                                    <li className='nav-item'>
                                        <Link to={'/challenge-management/campaigns'} className={`nav-link ${location.pathname === '/login' ? 'active' : ''}`}>
                                            Manage Campaigns
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li> */}
                        <li className='nav-item'>
                            <Link className={`nav-link ${location.pathname.includes('challenge') ? 'active' : ''}`} to={'/challenges'} role='button' aria-expanded='false' aria-controls='sidebarAuth'>
                                <i className='fe fs-2 pb-2'>< MdOutlineGamepad /></i> Challenges
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link className={`nav-link ${location.pathname.includes('campaigns') ? 'active' : ''}`} to={'/campaigns'} role='button' aria-expanded='false' aria-controls='sidebarAuth'>
                                <i className='fe fs-2 pb-2'>< MdOutlineCampaign /></i> Campaigns
                            </Link>
                        </li>
                        {/* <li className='nav-item'>
                            <Link className={`nav-link ${location.pathname.includes('points') ? 'active' : ''}`} to={'/points'} role='button' aria-expanded='false' aria-controls='sidebarAuth'>
                                <i className='fe fs-2 pb-2'><MdOutlineStarBorder /></i> Points Management
                            </Link>
                        </li> */}
                        <li className='nav-item'>
                            <Link className={`nav-link ${location.pathname.includes('shop') ? 'active' : ''}`} to={'/shop'} role='button' aria-expanded='false' aria-controls='sidebarAuth'>
                                <i className='fe fs-2 pb-2'><MdOutlineShoppingBag /></i> Scrappy Shop
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link className={`nav-link ${location.pathname.includes('reviews') ? 'active' : ''}`} to={'/reviews'} role='button' aria-expanded='false' aria-controls='sidebarAuth'>
                                <i className='fe fs-2 pb-2'><MdOutlineArticle /></i> Reviews
                            </Link>
                        </li>
                    </ul>
                    <hr className='navbar-divider my-3' />
                    <ul className='navbar-nav mb-md-4'>
                        <li className='nav-item'>
                            <Link className={`nav-link`} to={'/'} role='button' aria-expanded='false' aria-controls='sidebarBasics' onClick={signOut}>
                                <i className='fe fs-2 pb-2'><MdOutlineLogout /></i> Logout
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>

        </nav>
    )
}

export default Sidebar
