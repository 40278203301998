import React from 'react'
import Question from './question'
import { useState } from 'react';
import { uniqueId } from 'lodash';

const ScrappyDesign = () => {
    const [inputImage, setInputImage] = useState({});

    const [design, setDesign] = useState({
        preview: false,
        questions: [{
            id: uniqueId(),
            question_no: 1,
            question_type: '0',
            answer_type: '0',
            question: {}
        }]
    })


    const handleChange = (item, index) => {
        const copy = [...design.questions]
        copy.splice(index, 1, item)
        setDesign((prev) => {
            return ({ ...prev, questions: copy })
        })
    }

    const handleAddQuestions = () => {
        const copy = [...design.questions]
        copy.push({
            id: uniqueId(),
            question_no: null,
            question_type: '0',
            answer_type: '0',
            question: {}
        })
        copy.forEach((x, i) => (x.question_no = i + 1))
        setDesign((prev) => ({ ...prev, questions: copy }))
    }

    const handleRemoveQuestion = (id) => {
        const copy = [...design.questions]
        let index = copy.findIndex(x => x.id === id)
        copy.splice(index, 1)
        copy.forEach((x, i) => (x.question_no = i + 1))
        setDesign((prev) => ({ ...prev, questions: copy }))
    }

    return (
        <div>
            <div className='card p-4'>
                <div>
                    <div className='d-flex justify-content-between mb-3'>
                        <h3 className='m-0'>Scrappy Design </h3>
                    </div>
                    <div className='d-flex justify-content-between mt-4 p-1 py-2' style={{ border: 'dashed 1px #D2DDEC' }}>
                        <div>{inputImage[0]?.name || 'Upload Image'}</div>
                        <label className='btn btn-primary btn-sm' htmlFor='image' >
                            <input type='file' multiple={true} id='image' name='image' accept=' image/*' onChange={(e) => { setInputImage(e.target.files) }} /> Upload
                        </label>

                    </div>
                </div>
                <div className='mt-5'>
                    <div className='d-flex justify-content-between mb-3'>
                        <h3 className='m-0'>Description </h3>
                    </div>
                    <div className="row">
                        <div className="col">
                            <span className='fw-semibold'>
                                English
                            </span>
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <span className='fw-semibold'>
                                Hindi
                            </span>
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <span className='fw-semibold'>
                                Marathi
                            </span>
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <span className='fw-semibold'>
                                Kannada
                            </span>
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card'>
                <div className="card-header" style={{ backgroundColor: '#F0F6FD' }}>
                    <div className='d-flex justify-content-between' >
                        <h3>Questions</h3>
                        <p className='fw-bolder text-primary mb-0 cursor-pointer'>
                            <i className='fe fe-plus fw-bolder'></i> Add questions
                        </p>
                    </div>
                </div>
                <div className="card-body">
                    {
                        design.questions?.map((item, index) => {
                            return (
                                <Question item={item} handleChange={(val) => handleChange(val, index)} key={item.id} handleRemoveQuestion={() => { handleRemoveQuestion(item.id) }} />
                            )
                        })
                    }
                </div>
            </div>
            <div className='card'>
                <div className='p-4'>
                    <div className='d-flex justify-content-between mb-3'>
                        <h3 className='m-0'>Title </h3>
                    </div>
                    <div className="row">
                        <div className="col">
                            <span className='fw-semibold'>
                                English
                            </span>
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <span className='fw-semibold'>
                                Hindi
                            </span>
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <span className='fw-semibold'>
                                Marathi
                            </span>
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <span className='fw-semibold'>
                                Kannada
                            </span>
                            <div className="form-floating">
                                <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='p-4' style={{ backgroundColor: '#F0F6FD' }}>
                    <div className='d-flex justify-content-between' >
                        <h3>Questions</h3>
                        <p className='fw-bolder text-primary mb-0 cursor-pointer'>
                            <i className='fe fe-plus fw-bolder'></i> Add questions
                        </p>
                    </div>
                </div>
                <div className='p-4'>
                    {/* <Question /> */}
                </div>
            </div>
            {/* <div className="card">
                <div className="card-header" style={{ backgroundColor: '#F0F6FD' }}>
                    <div className='d-flex justify-content-between' >
                        <h3>Questions</h3>
                        <p className='fw-bolder text-primary mb-0 cursor-pointer'>
                            <i className='fe fe-plus fw-bolder'></i> Add questions
                        </p>
                    </div>
                </div>
                <div className="card-body">
                    <Question />
                </div>
            </div> */}
            <div className="card">
                <div className="card-header" style={{ backgroundColor: '#F0F6FD' }}>
                    <div className='d-flex justify-content-between' >
                        <h3>Table Content</h3>
                        <p className='fw-bolder text-primary mb-0 cursor-pointer'>
                            <i className='fe fe-plus fw-bolder'></i> Add Table
                        </p>
                    </div>
                </div>
                <div className="card-body">
                    <div className='d-flex justify-content-between mb-3'>
                        <h3 className='m-0'>Table 1 </h3>
                        <div className='d-flex '>
                            <div className='primary me-4'>
                                <p className='fw-bolder text-primary mb-0 cursor-pointer'>Preview</p>
                            </div>
                            <div className='d-flex justify-content-center align-items-center badge bg-danger-soft'>
                                <i className='fe fe-trash-2 fs-2 text-danger'></i>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h4 className='mt-4'>Title </h4>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div>
                                    <textarea className='form-control' rows='2' placeholder='English'></textarea>
                                </div>
                            </div>
                            <div className="col">
                                <div>
                                    <textarea className='form-control' rows='2' placeholder='Hindi'></textarea>
                                </div>
                            </div>
                            <div className="col">
                                <div>
                                    <textarea className='form-control' rows='2' placeholder='Marathi'></textarea>
                                </div>
                            </div>
                            <div className="col">
                                <div>
                                    <textarea className='form-control' rows='2' placeholder='Kannada'></textarea>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h4 className='mt-4'>Description </h4>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div>
                                    <textarea className='form-control' rows='2' placeholder='English'></textarea>
                                </div>
                            </div>
                            <div className="col">
                                <div>
                                    <textarea className='form-control' rows='2' placeholder='Hindi'></textarea>
                                </div>
                            </div>
                            <div className="col">
                                <div>
                                    <textarea className='form-control' rows='2' placeholder='Marathi'></textarea>
                                </div>
                            </div>
                            <div className="col">
                                <div>
                                    <textarea className='form-control' rows='2' placeholder='Kannada'></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='p-4' style={{ backgroundColor: '#F0F6FD' }}>
                    <div className='d-flex justify-content-between' >
                        <h4>Table Column Content</h4>
                        <p className='fw-bolder text-primary mb-0 cursor-pointer'>
                            <i className='fe fe-plus fw-bolder'></i> Add Column
                        </p>
                    </div>
                </div>
                <div>
                    <div className='row'>
                        <div className="col-2 m-4" >
                            <div className='mb-3 text-center'>
                                <h4>Column-1</h4>
                            </div>
                            <div className='mb-3'>
                                <textarea className='form-control' rows='2' placeholder='English'></textarea>
                            </div>
                            <div className='mb-3'>
                                <textarea className='form-control' rows='2' placeholder='Hindi'></textarea>
                            </div>
                            <div className='mb-3'>
                                <textarea className='form-control' rows='2' placeholder='Marathi'></textarea>
                            </div>
                            <div className='mb-3'>
                                <textarea className='form-control' rows='2' placeholder='Kannada'></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='p-4'>
                    <div>
                        <h4 className='mt-4'>Table Button </h4>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div>
                                <textarea className='form-control' rows='2' placeholder='English'></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <div>
                                <textarea className='form-control' rows='2' placeholder='Hindi'></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <div>
                                <textarea className='form-control' rows='2' placeholder='Marathi'></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <div>
                                <textarea className='form-control' rows='2' placeholder='Kannada'></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ScrappyDesign
