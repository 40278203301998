import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { PostData } from '../../data/postData';
import { saveAs } from 'file-saver';
import { API } from 'aws-amplify';
import { getChallengeActivity } from '../../graphql/queries';
import { createActivityFeed, updateChallengeActivity } from '../../graphql/mutations';
import { toast } from 'react-toastify';
import { Storage } from 'aws-amplify';


const EditPost = () => {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [key, setKey] = useState(Math.random())
    const [fetchData, setFetchData] = useState([]);

    const navigate = useNavigate();

    const getChallenge = async () => {
        try {
            let response = await API.graphql({
                query: getChallengeActivity,
                variables: {
                    id
                }
            })
            setFetchData(response.data.getChallengeActivity)
            let parsedData = response?.data.getChallengeActivity.responses?.map((e) =>
                JSON.parse(e)
            )
            setData(parsedData)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getChallenge()
    }, [])

    const handleEditContent = (e, id) => {
        setData((prev) => {
            for (let i = 0; i < prev.length; i++) {
                if (i === id) {
                    prev[i].content = e.target.value
                }
            }
            return prev
        })
    }

    const handleUploadImage = async (e, id) => {
        const file = e.target.files[0]
        const stored = await Storage.put(`activity-${Math.random().toString(36).substring(2, 15)}.${file.name.split('.')[1]}`, file, { contentType: file.type });
        const url = await Storage.get(stored.key, { level: 'public' })
        setKey(Math.random())
        setData((prev) => {
            for (let i = 0; i < prev.length; i++) {
                if (i === id) {
                    prev[i].image = url
                }
            }
            return prev
        })

    }
    const handleUpdateChallengeActivity = async () => {
        try {
            let response = await API.graphql({
                query: updateChallengeActivity,
                variables: {
                    input: {
                        id: fetchData.id,
                        status: 'APPROVED'
                    }
                }
            })

        } catch (error) {
            console.log(error);
        }
    }

    const handleCreateActivity = async () => {
        try {
            let StringifyContent = data.map((post) =>
                JSON.stringify(post))
            let response = await API.graphql({
                query: createActivityFeed,
                variables: {
                    input: {
                        challengeId: fetchData.challengeId,
                        studentgroupId: fetchData.studentgroupId,
                        userId: '63d0ac3e-aedf-48ce-856e-d82a737a62f9',
                        type: 'STUDENT',
                        content: StringifyContent

                    }
                }
            });
            toast.success('Posted', {
                position: toast.POSITION.TOP_RIGHT
            }
            )
            handleUpdateChallengeActivity()
        } catch (error) {
            console.log(error)
            toast.error('Posted', {
                position: toast.POSITION.TOP_RIGHT
            }
            )
        }
    }

    const handleDownload = (link, id) => {
        saveAs(link, `image-${id}.jpg`)
    }

    const uniqueId = () => {
        let id = 'id' + (new Date()).getTime()
        return id
    }

    return (
        <div className='container-fluid'>
            <div className='header'>
                <div className="header-body d-flex align-items-center">
                    <div>
                        <h2 >
                            <i className='fe fe-arrow-left fw-bold me-3' onClick={() => { navigate(-1) }}></i>
                        </h2>
                    </div>
                    <div className="row align-items-end">
                        <div className="col">
                            <h6 className="header-pretitle">
                                Edit Post
                            </h6>
                            <h2 className="header-title">
                                {fetchData?.challenge?.name}

                            </h2>
                        </div>
                        {/* <div className="col-auto">
                            <button className="btn btn-primary btn-md">Publish Post</button>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className='row' >
                {data?.map((post, i) => {
                    return (
                        <div className='col-12 col-md-6 col-xl-4' key={`${i}-${key}`} >
                            <div className="card" >
                                <div>
                                    <img src={post.image} alt="..." className="card-img-top edit-image-prev" style={{ maxHeight: '40vh' }} />
                                </div>
                                <div className='row m-3'>
                                    <div className="col">
                                        <button className='btn btn-primary btn-sm' onClick={() => handleDownload(post.image, i)}><i className='fe fe-download'></i> Download Image</button>
                                    </div>
                                    <div className="col-auto">
                                        <label className='btn btn-outline-primary btn-sm'>
                                            <input type='file' id='image' name='image' accept=' image/*' onChange={(e) => handleUploadImage(e, i)} />
                                            <i className='fe fe-upload'></i> Upload Image</label>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row ">
                                        <div className="col">
                                            <h4 className="mb-2 name">
                                                Description
                                            </h4>
                                            <textarea className="form-control" aria-label="With textarea" onChange={(e) => handleEditContent(e, i)}>{post.content}</textarea>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className='d-flex justify-content-end'>
                <button className='btn btn-primary btn-md m-3' onClick={handleCreateActivity}>Publish Post</button>
            </div>


        </div>
    )
}

export default EditPost
