import React, { useState } from 'react'
import Question from './question';
import { uniqueId } from 'lodash';

const Research = () => {

    const [research, setResearch] = useState({
        preview: false,
        questions: [{
            id: uniqueId(),
            question_no: 1,
            question_type: '0',
            answer_type: '0',
            question: {}
        }]
    })

    const handleChange = (item, index) => {
        const copy = [...research.questions]
        copy.splice(index, 1, item)
        setResearch((prev) => {
            return ({ ...prev, questions: copy })
        })
    }

    const handleAddQuestions = () => {
        const copy = [...research.questions]
        copy.push({
            id: uniqueId(),
            question_no: null,
            question_type: '0',
            answer_type: '0',
            question: {}
        })
        copy.forEach((x, i) => (x.question_no = i + 1))
        setResearch((prev) => ({ ...prev, questions: copy }))
    }

    const handleRemoveQuestion = (id) => {
        const copy = [...research.questions]
        let index = copy.findIndex(x => x.id === id)
        copy.splice(index, 1)
        copy.forEach((x, i) => (x.question_no = i + 1))
        setResearch((prev) => ({ ...prev, questions: copy }))
    }

    const handlePreview = () => {
        setResearch((prev) => ({ ...prev, preview: !prev.preview }))
    }



    return (
        <div>
            <div className='card py-3'>
                <div className='row m-3'>
                    <div className='col p-0'>
                        <h3 className='m-0 '>Scrappy Research</h3>
                    </div>
                    <div className='col-auto d-flex'>
                        <div className="form-check form-switch">
                            <input className="form-check-input bg-danger" type="checkbox" role="switch" value={research.preview} onChange={handlePreview} />
                        </div>
                        <div className='primary'>
                            <p className='fw-bolder text-primary mb-0 cursor-pointer'>Preview</p>
                        </div>
                    </div>
                </div>
            </div>
            {console.log('res', research)}
            <div className='card'>
                <div className='py-3 m-3'>
                    <h3 className='m-0'>Field Research Notes</h3>
                </div>
                <div className='p-4' style={{ backgroundColor: '#F0F6FD' }}>
                    <div className='d-flex justify-content-between' >
                        <h3>Questions</h3>
                        <p className='fw-bolder text-primary mb-0 cursor-pointer' onClick={handleAddQuestions}>
                            <i className='fe fe-plus fw-bolder'></i> Add questions
                        </p>
                    </div>
                    <hr />
                    <div className='row'>
                        <div className='col'><span className='text-muted fw-bold'>English</span></div>
                        <div className='col'><span className='text-muted fw-bold'>Hindi</span></div>
                        <div className='col'><span className='text-muted fw-bold'>Marathi</span></div>
                        <div className='col'><span className='text-muted fw-bold'>Kannada</span></div>
                    </div>
                </div>
                <div className='p-4'>
                    {
                        research.questions?.map((item, index) => {
                            return (
                                <Question item={item} handleChange={(val) => handleChange(val, index)} key={item.id} handleRemoveQuestion={() => { handleRemoveQuestion(item.id) }} />
                            )
                        })
                    }
                </div>
            </div>

        </div >
    )
}

export default Research
