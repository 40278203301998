import React, { useEffect } from 'react';
import { PostData } from '../../data/postData';
import PublishedCard from '../../components/publishedCard';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import { getAvatarUrl } from '../../helpers'
import { getGroupWithInvitesAndMembers, listStudentsWithGroup } from '../../customGraphql/queries';
import { createGroupMember } from '../../graphql/mutations';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';

const TeamDetails = () => {
    const { id } = useParams();
    const [show, setShow] = useState(false);
    const [avalibleStudents, setAvalibleStudents] = useState([]);
    const handleClose = () => { setShow(false) };
    const handleShow = () => setShow(true);

    const [apiData, setApiData] = useState({});

    useEffect(() => {
        getGroupInfo(id);
    }, [])

    const getGroupInfo = async (id) => {
        try {
            let res = await API.graphql({
                query: getGroupWithInvitesAndMembers,
                variables: {
                    id
                }
            })
            setApiData(res?.data?.getGroup)
        } catch (error) {
            console.log(error)
        }
    }

    const getStudentsBySchoolId = async (id) => {
        try {
            let res = await API.graphql({
                query: listStudentsWithGroup,
                variables: {
                    filter: { schoolId: { eq: id } }
                }
            })
            let data = [];
            res.data.listStudents.items.forEach((student) => {
                if (!student.groups.items.length) {
                    data.push(student)
                }
            })
            setAvalibleStudents(data)
        } catch (error) {
            console.log(error)
        }
    }

    const addToGroup = async (studentId) => {
        try {
            await API.graphql({
                query: createGroupMember,
                variables: {
                    input: {
                        groupId: id,
                        studentId
                    }
                }
            })
            toast.success('Student Added to Group')
            getStudentsBySchoolId(apiData.schoolId)
            getGroupInfo(id);
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <>
            <div className='container-fluid'>
                <div className="header">
                    <div className="header-body">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <div className="avatar avatar-lg avatar-4by3">
                                    <img src="https://cdn2.psychologytoday.com/assets/styles/manual_crop_1_91_1_1528x800/public/2020-08/shutterstock_1731284125_0.jpg?itok=89UrdUt_" alt="..." className="avatar-img rounded" />
                                </div>
                            </div>
                            <div className="col ms-n3 ms-md-n2">
                                <h6 className="header-pretitle">
                                    Group
                                </h6>
                                <h2 className="header-title">
                                    {apiData?.name}
                                </h2>
                            </div>
                            <div className="col-12 col-md-auto mt-3 mt-md-0">
                                <div className="avatar-group">
                                    {apiData?.members?.items?.map((member) => (
                                        <div className="avatar" key={member.id} >
                                            <img src={getAvatarUrl(member?.student?.user?.avatar)} alt="..." className="avatar-img fs-1 rounded-circle border" />
                                        </div>))}
                                    {apiData?.members?.items?.length < 7 &&
                                        (<div className="avatar">
                                            <span className="avatar-title rounded-circle fs-1 cursor-pointer" onClick={() => { getStudentsBySchoolId(apiData.schoolId); handleShow() }}>+</span>
                                        </div>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid ">
                <div className="row">
                    <div className="col-12 col-xl-8">
                        {/* {PostData.map((post) => {
                            return (<PublishedCard post={post} key={post.id} />)
                        })} */}
                    </div>
                    <div className="col-12 col-xl-4 ">
                        <div className="card ">
                            <div className="card-header">
                                <h4 className="card-header-title">
                                    Members
                                </h4>
                            </div>
                            <div className="card-body scrappy-group-request-body">
                                <div className="list-group list-group-flush my-n3">
                                    {apiData?.members?.items?.map((member) => (
                                        <div className="list-group-item" key={member.id}>
                                            <div className="row align-items-center">
                                                <div className="col-auto">
                                                    <div className="avatar" >
                                                        <img src={getAvatarUrl(member?.student?.user?.avatar)} alt="..." className="avatar-img fs-1 rounded-circle border" />
                                                    </div>
                                                </div>
                                                <div className="col ms-n2">
                                                    <h4 className="mb-1">
                                                        {member?.student?.firstName} {member?.student?.lastName}
                                                    </h4>
                                                    <p className="card-text small text-muted">
                                                        {member?.student?.school?.name}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>))}
                                </div>
                            </div>
                            <div className='card-footer'></div>
                        </div>
                        <div className="card ">
                            <div className="card-header">
                                <h4 className="card-header-title">
                                    Requests
                                </h4>
                            </div>
                            <div className="card-body scrappy-group-request-body">
                                <div className="list-group list-group-flush my-n3">
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((e) => (
                                        <div className="list-group-item">
                                            <div className="row align-items-center">
                                                <div className="col-auto">
                                                    <div className="avatar ">
                                                        <img src="https://dashkit.goodthemes.co/assets/img/avatars/profiles/avatar-4.jpg" alt="..." className="avatar-img rounded-circle" />
                                                    </div>
                                                </div>
                                                <div className="col ms-n2">
                                                    <h4 className="mb-1">
                                                        Dianna Smiley
                                                    </h4>
                                                    <p className="card-text small text-muted">
                                                        Some Public School
                                                    </p>
                                                </div>
                                                <div className="col-auto text-center me-3">
                                                    <div className='dropdown'>
                                                        <div className='dropdown-ellipses dropdown-toggle text-mute' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                                                            <i className='fe fe-more-vertical'></i>
                                                        </div>
                                                        <div className='dropdown-menu dropdown-menu-end'>
                                                            <div className='dropdown-item'>
                                                                Edit
                                                            </div>
                                                            <div className='dropdown-item' >
                                                                Resend Invite
                                                            </div>
                                                            <div className='dropdown-item'>
                                                                Delete
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>))}
                                </div>
                            </div>
                            <div className='card-footer'></div>
                        </div>
                    </div>
                </div>
                <Modal show={show} onHide={handleClose} >
                    <Modal.Header>
                        <h2 className='m-0'>Map To A Team</h2>
                    </Modal.Header>
                    <Modal.Body className='modal-scroll'>
                        <div className="list-group list-group-flush my-n3">
                            {avalibleStudents.map((student) => (
                                <div className="list-group-item" key={student.id}>
                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                            <div className="avatar ">
                                                <img src={getAvatarUrl(student?.user?.avatar)} alt="..." className="avatar-img rounded-circle" />
                                            </div>
                                        </div>
                                        <div className="col ms-n2">
                                            <h4 className="mb-1">
                                                {student.firstName}
                                            </h4>
                                            <p className="card-text small text-muted">
                                                {student.grade} Grade
                                            </p>
                                        </div>
                                        <div className="col-auto justify-content-evenly">
                                            <button type="button" className="btn btn-primary btn-sm m-3" onClick={() => {
                                                addToGroup(student.id)
                                            }}>
                                                Map
                                            </button>
                                        </div>
                                    </div>
                                </div>))}
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='p-3'>
                        <div className='d-flex justify-content-end gap-3 '>
                            <button type='button' className='btn btn-white' onClick={() => setShow(false)}>Cancel</button>
                            <button type='submit' className='btn btn-primary' >Save</button>
                        </div>
                    </Modal.Footer>

                </Modal>

            </div>

        </>
    )
}

export default TeamDetails
