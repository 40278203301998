import React, { useEffect, useState } from 'react'


const UpcomingStars = () => {


    return (
        <div>
            <div>
                <div className='d-flex justify-content-between'>
                    <h3>Milestone</h3>
                    <p className='fw-bolder text-primary mb-0'>
                        <i className='fe fe-plus-circle fw-bolder'></i>
                        Add Milestone</p>
                </div>
                <div className='row row-cols-1 row-cols-md-3 row-cols-lg-5'>

                    <div className="col card p-4 ">
                        <div className='d-flex align-items-center'>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
                                    <g id="milestone" transform="translate(-454 -226)">
                                        <rect id="Rectangle_2352" data-name="Rectangle 2352" width="60" height="60" rx="30" transform="translate(454 226)" fill="#e52e79" />
                                        <g id="Group_10274" data-name="Group 10274" transform="translate(-1.792 -2.845)">
                                            <g id="Layer_1-2" transform="translate(473.792 242.845)">
                                                <path id="Path_3950" data-name="Path 3950" d="M6.062,0c.149.059.3.108.445.179A1.837,1.837,0,0,1,7.6,1.776c.014.189,0,.379,0,.592H15c.653,0,.9.246.9.9s0,1.315,0,2.019h.378q3.535,0,7.071,0c.563,0,.919.35.815.84a1.05,1.05,0,0,1-.333.517C22.42,7.887,21,9.116,19.584,10.349c-.114.1-.227.2-.363.322l1.589,1.384,3.01,2.618a.753.753,0,0,1-.514,1.388q-3.993,0-7.986,0c-.614,0-.884-.275-.887-.9,0-.659,0-1.317,0-2H7.639V24.7c.547.187,1.091.348,1.615.559a3.928,3.928,0,0,1,1.619,1.155,2.179,2.179,0,0,1-.055,2.965A4.717,4.717,0,0,1,8.49,30.75a9.1,9.1,0,0,1-6.535-.4,4.458,4.458,0,0,1-1.293-.939A2.18,2.18,0,0,1,.7,26.247a5.129,5.129,0,0,1,2.276-1.293c.261-.081.528-.141.837-.223V2.336A2.016,2.016,0,0,1,5.391,0h.671Zm8.349,11.666V3.845H7.655v7.822H14.41Zm7.073-4.9h-5.56v7.806h5.553c-.11-.1-.176-.159-.245-.219q-1.734-1.51-3.468-3.019a.757.757,0,0,1-.009-1.321l3.729-3.247ZM5.276,14.58V26.958a1.683,1.683,0,0,0,.012.333.409.409,0,0,0,.451.364.4.4,0,0,0,.4-.385c.013-.131.01-.263.01-.4Q6.139,15.243,6.131,3.61c0-.486.014-.973.016-1.459s-.137-.7-.444-.688-.428.22-.428.677V14.579ZM3.814,26.235a4.3,4.3,0,0,0-1.958.92.838.838,0,0,0,.015,1.421,2.961,2.961,0,0,0,.762.493A7.661,7.661,0,0,0,8.81,29.06a3.045,3.045,0,0,0,.939-.7.684.684,0,0,0,0-1.015,3.912,3.912,0,0,0-2.139-1.115c0,.31.008.572,0,.834a3.084,3.084,0,0,1-.063.632A1.879,1.879,0,0,1,5.54,29.112a1.9,1.9,0,0,1-1.723-1.85c-.012-.333,0-.666,0-1.027Z" transform="translate(0 0)" fill="#fff" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div className='ms-3 ps-3' style={{ borderLeft: 'double #e52e79' }}>
                                <p className='my-1'> Milestone 1</p>
                                <h3 className='mb-1'>50 Starts</h3>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div>
                <div className='d-flex justify-content-between'>
                    <h3>Scrappy Stars</h3>
                    <p className='fw-bolder text-primary mb-0'>
                        <i className='fe fe-plus-circle fw-bolder'></i>
                        Add Scrappy Stars</p>
                </div>
                <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3'>
                    {[1, 2, 3, 4, 5, 6].map((e) => {
                        return (<div className='col'>
                            <div className='card p-4'>
                                <div className='d-flex justify-content-end'>
                                    <div className="dropdown">
                                        <div className="dropdown-ellipses dropdown-toggle text-mute" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fe fe-more-vertical"></i>
                                        </div>
                                        <div className="dropdown-menu dropdown-menu-end">
                                            <div className="dropdown-item">
                                                View
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='d-flex justify-content-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
                                            <g id="star" transform="translate(-454 -226)">
                                                <rect id="Rectangle_2352" data-name="Rectangle 2352" width="60" height="60" rx="30" transform="translate(454 226)" fill="#e52e79" />
                                                <path id="Icon_awesome-star" data-name="Icon awesome-star" d="M13.711.914l-3.355,6.8L2.849,8.812a1.645,1.645,0,0,0-.909,2.805l5.431,5.292L6.086,24.386A1.643,1.643,0,0,0,8.47,26.117l6.716-3.53,6.716,3.53a1.645,1.645,0,0,0,2.384-1.732L23,16.909l5.431-5.292a1.645,1.645,0,0,0-.909-2.805L20.016,7.717,16.661.914a1.646,1.646,0,0,0-2.949,0Z" transform="translate(468.814 242.846)" fill="#fff" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className='d-flex justify-content-center align-items-center flex-column'>
                                        <div className='m-3'>
                                            <p className='fw-bolder m-0' style={{ color: '#e52e79' }}>14 Scrappy Stars</p>
                                        </div>
                                        <div className='fw-semibold text-center w-50'>
                                            <p className=''>Complete 18 challenges to win 14 scrappy stars</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)
                    })}
                </div>
            </div>
        </div>
    )
}

export default UpcomingStars
