import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router'
import GroupCard from '../../components/groupCard'
import { useState } from 'react';
import { API } from 'aws-amplify';
import { listGroups, searchGroups } from '../../graphql/queries';
import TeamList from '../teams/teamList'
import { createGroup } from '../../graphql/mutations';
import { toast } from 'react-toastify';
import { AuthContext } from '../../contexts/authContext'
import { useContext } from "react";

const SchoolTeams = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); setInput('') }
    const handleShow = () => setShow(true);
    const [checkResult, setCheckResult] = useState('');
    const [input, setInput] = useState('')
    const { user } = useContext(AuthContext);

    const { id } = useParams();

    const checkGroup = async (value) => {
        try {
            let res = await API.graphql({
                query: searchGroups,
                variables: {
                    filter: { name: { eq: value } }
                }
            })
            setCheckResult(res.data.searchGroups.total)

        } catch (error) {
            console.logf(error)
        }
    }

    const handleCreateGroup = async () => {
        try {
            let res = await API.graphql({
                query: createGroup,
                variables: {
                    input: {
                        approved: true,
                        name: input,
                        schoolId: id,
                        groupOwnerId: user.id
                    }
                }
            })
            toast.success(`${input} group created`)
            handleClose()
        } catch (error) {
            console.log(error)
        }
    }



    return (
        <div>
            <div className='d-flex justify-content-end mb-3'>
                <button className='btn btn-primary btn-sm' onClick={handleShow}>Add Group</button>
            </div>
            <div>
                <TeamList dependency={show} />
            </div>
            <Modal show={show} onHide={handleClose} >
                <Modal.Header>
                    <h2 className='m-0'>Create Group</h2>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label htmlFor='school_name' className='form-label'>Group Name</label>
                        <input type={'text'} className='form-control' id='school_name' onChange={(e) => { setInput(e.target.value); checkGroup(e.target.value) }} />
                        {checkResult ?
                            <span className='text-danger'>{'Group name already exists'}</span> : ''
                        }
                    </div>
                    <div className='mt-5'>
                        <div className='d-flex justify-content-end gap-3'>
                            <button type='button' className='btn btn-white' onClick={() => setShow(false)}>Cancel</button>
                            <button type='submit' className='btn btn-primary' disabled={checkResult} onClick={handleCreateGroup}>Create</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>

    )
}

export default SchoolTeams
