import { API } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { updateCampaign } from '../../graphql/mutations'
import { getCampaign } from '../../graphql/queries'
import { toast } from 'react-toastify'


const PitchedCampaigns = ({ approved }) => {

    const [data, setData] = useState([]);

    const { id } = useParams()
    const navigate = useNavigate();

    const handleGetCampaign = async () => {
        try {
            let res = await API.graphql({
                query: getCampaign,
                variables: {
                    id
                }
            })
            res.data.getCampaign.description = JSON.parse(res?.data?.getCampaign?.description)
            res.data.getCampaign.plan = JSON.parse(res?.data?.getCampaign?.plan)
            console.log('res', res)
            setData(res?.data?.getCampaign)
        } catch (error) {
            console.log(error)
        }
    }

    const handleUpdateCampaign = async () => {
        try {
            await API.graphql({
                query: updateCampaign,
                variables: {
                    input: {
                        id,
                        approved: true
                    }
                }
            })
            toast.success('Approved Campaign')
            navigate(-1)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        handleGetCampaign()
    }, [])

    return (
        <div className='container-fluid'>
            <div className="header ">
                <div className="header-body">
                    <div className="row align-items-center">
                        <div className="col">
                            <h2 className="header-title">
                                <i className='fe fe-arrow-left fw-bold me-3' onClick={() => { navigate(-1) }}></i>
                                Pitched Campaign Details
                            </h2>
                        </div>
                        {!data.approved &&
                            <div className='col-auto '>
                                <button className='btn btn-danger me-4'>Reject</button>
                                <button className='btn btn-success' onClick={() => {
                                    handleUpdateCampaign()
                                }}>Approve</button>
                            </div>
                        }
                    </div>

                </div>
            </div>
            <div>
                <div className='card p-3'>
                    <p className='text-muted mb-2'>What problem do you want to solve?</p>
                    <p className='mb-2'>{data.category === "OTHER" ? data.otherCategory : data.category}</p>
                </div>
                <div className='card p-3'>
                    <p className='text-muted mb-2'>What do you want to solve in {data.category === "OTHER" ? data.otherCategory : data.category} ?</p>
                    <p className='mb-2'>{data?.description?.campaignGoal}</p>
                </div>
                <div className='card p-3'>
                    <p className='text-muted mb-2'>Where do you see the problems the most?</p>
                    <div className='my-2'>
                        <p className='text-muted mb-2'>Place</p>
                        <p className='mb-2'>{data?.description?.location}</p>
                    </div>
                    <div className='my-2'>
                        <p className='text-muted mb-2'>Description</p>
                        <p className='mb-2'>{data?.description?.locationDescription}</p>
                    </div>
                </div>
                <div className='card p-3'>
                    <p className='text-muted mb-2'>Where do you see the problems the most?</p>
                    <div className='row row-cols-1 row-cols-lg-3 g-auto mt-3'>
                        <div className='col my-2 text-center '>
                            <img src={`${data?.description?.topViewImage}`} width={'200px'} />
                            <p className='mt-2'>Top view of the problem</p>
                        </div>
                        <div className='col my-2 text-center'>
                            <img src={`${data?.description?.rightViewImage}`} width={'200px'} />
                            <p className='mt-2'>Right side view of the problem</p>
                        </div>
                        <div className='col my-2 text-center'>
                            <img src={`${data?.description?.leftViewImage}`} width={'200px'} />
                            <p className='mt-2'>Left side of the problem</p>
                        </div>
                    </div>
                </div>
                <div className='card p-3'>
                    <p className='text-muted mb-2'>What is your plan? What do you want to do solve the problem?</p>
                    <p className='mb-2'>{data?.description?.solvingProblems}</p>
                </div>
                {/* <div className='card p-3'>
                    <div className='my-2'>
                        <p className='text-muted mb-2'>What do you want to solve?</p>
                        <p className='mb-2'>I'd love my school to be a part of it</p>
                    </div>
                    <div className='my-2'>
                        <p className='text-muted mb-2'>Your school name</p>
                        <p className='mb-2'>School name</p>
                    </div>

                </div> */}
                <div className='card p-3'>
                    <p className='text-muted mb-2'>When does your challenge begin?</p>
                    <div className='my-2'>
                        <p className='text-muted mb-2'>Date</p>
                        <p className='mb-2'>{data?.description?.startedOn?.split(' ')[0]}</p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default PitchedCampaigns
