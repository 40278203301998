import React from 'react'
import { useState, useEffect } from 'react';
import Pagination from '../../components/pagination';
import { API } from 'aws-amplify';
import { customListGroups } from '../../customGraphql/queries'
import { getAvatarUrl } from '../../helpers/index'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';



const TeamRequests = () => {

    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [apiData, setApiData] = useState([]);

    const navigate = useNavigate();


    const getRequestData = async () => {
        try {
            let response = await API.graphql({
                query: customListGroups,
                variables: {
                    filter: { approved: { eq: false } }
                }
            })
            handleData(response?.data?.listGroups?.items)
        } catch (error) {
            console.log(error)
        }
    }

    const handleData = (data) => {
        let creators = [];
        data.forEach(team => {
            if (creators.findIndex(c => c?.id === team?.owner?.id) < 0)
                creators.push(team?.owner)
        })

        let report = [];
        for (const creator of creators) {
            let creatorObj = {};
            creatorObj.owner = creator;
            creatorObj.teams = data.filter(team => team?.owner?.id === creator?.id);
            report.push(creatorObj)
        }
        setApiData(report)
        handleSearchResult(report)
    }

    const handleSearchInput = ({ target }) => {
        setSearchInput(target.value.toLowerCase())
    }

    useEffect(() => {
        getRequestData()
    }, [])

    useEffect(() => {
        handleSearchResult(apiData)
    }, [searchInput])

    const handleSearchResult = (data) => {
        // let result = data.filter(item =>
        //     item.studentCreated?.toLowerCase().includes(searchInput) ||
        //     item.name?.toLowerCase().includes(searchInput))
        // setFilteredData(result)
        setFilteredData(data)
    }


    return (
        <div>
            <div className="card" id="contactsList">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <form>
                                <div className="input-group input-group-flush input-group-merge input-group-reverse">
                                    <input className="form-control list-search" type="search" placeholder="Search" onChange={handleSearchInput} />
                                    <span className="input-group-text">
                                        <i className="fe fe-search"></i>
                                    </span>
                                </div>
                            </form>
                        </div>
                        <div className="col-auto">
                            <div className="dropdown">
                                <button className="btn btn-sm btn-white" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
                                    <i className="fe fe-sliders me-1"></i> Filter <span className="badge bg-primary ms-1 d-none">0</span>
                                </button>
                                <form className="dropdown-menu dropdown-menu-end dropdown-menu-card">
                                    <div className="card-header">
                                        <h4 className="card-header-title">
                                            Filters
                                        </h4>
                                        <button className="btn btn-sm btn-link text-reset d-none" type="reset">
                                            <small>Clear filters</small>
                                        </button>
                                    </div>
                                    <div className="card-body">
                                        <div className="list-group list-group-flush mt-n4 mb-4">
                                            <div className="list-group-item">
                                                <div className="row">
                                                    <div className="col">
                                                        <small>Title</small>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="choices" data-type="select-one" tabIndex="0" role="listbox" aria-haspopup="true" aria-expanded="false">
                                                            <select className="form-select form-select-sm form-control" name="item-title" data-choices="{&quot;searchEnabled&quot;: false}" hidden="" tabIndex="-1" data-choice="active">
                                                                <option value="*" data-custom-properties="[object Object]">Any</option>
                                                                <option value="*" data-custom-properties="[object Object]">Designer</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="list-group-item">
                                                <div className="row">
                                                    <div className="col">
                                                        <small>Lead scrore</small>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="choices" data-type="select-one" tabIndex="0" role="listbox" aria-haspopup="true" aria-expanded="false">
                                                            <select className="form-select form-select-sm form-control" name="item-title" data-choices="{&quot;searchEnabled&quot;: false}" hidden="" tabIndex="-1" data-choice="active">
                                                                <option value="*" data-custom-properties="[object Object]">Any</option>
                                                                <option value="*" data-custom-properties="[object Object]">Designer</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="btn w-100 btn-primary" type="submit">
                                            Apply filter
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="table-responsive">
                    <table className="table table-sm table-hover card-table">
                        <thead >
                            <tr className='align-middle text-center'>
                                <th >
                                    <div className="text-muted">Student Created</div>
                                </th>
                                <th >
                                    <div className="text-muted ">School Name</div>
                                </th>
                                <th>
                                    <div className="text-muted" >Requested Team Names</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="list fs-base ">
                            {displayData?.map((item) => {
                                return (
                                    <tr key={item?.owner?.id} className='cursor-pointer' onClick={() => { navigate(`/teams/requests/${item?.owner?.id}`) }}>
                                        <td>
                                            <div className='text-center'>{item?.owner?.firstName}</div>
                                        </td>
                                        <td className='text-center'>
                                            {/* {item.schoolName} */}Some School
                                        </td>
                                        <td>
                                            <div className='text-center'>
                                                {item.teams.slice(0, 3).map((e) => <div key={e.id}>{e.name.charAt(0).toUpperCase() + e.name.slice(1)}</div>)}
                                                <span className='text-primary'>{(item?.teams?.length > 3) && item?.teams?.length - 3 + ' more'}</span>
                                            </div>
                                        </td>
                                    </tr>

                                )
                            })}

                        </tbody>
                    </table>
                </div>
                <Pagination PageData={filteredData} setDisplayData={setDisplayData} />
            </div>


        </div >)
}

export default TeamRequests
