import React from 'react'
import { Link } from 'react-router-dom';
import { useState } from 'react';
import TeamRequests from './teamRequests';
import TeamList from './teamList';

const Teams = () => {
    const [display, setDisplay] = useState(1)
    return (
        <div className='container-fluid'>
            <div className="header ">
                <div className="header-body">
                    <div className="row align-items-center">
                        <div className="col">
                            <h2 className="header-title">
                                Teams
                            </h2>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col">
                            <ul className="nav nav-tabs nav-overflow header-tabs">
                                <li className="nav-item">
                                    <Link className={`nav-link ${(display === 1) ? 'active' : ''}`} onClick={() => { setDisplay(1) }}>
                                        Team Requests
                                        {/* <span className="badge rounded-pill bg-secondary-soft">10</span> */}
                                    </Link>
                                </li>
                                <li className="nav-item ">
                                    <Link className={`nav-link ${(display === 2) ? 'active' : ''}`} onClick={() => { setDisplay(2) }}>
                                        Team List
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {(display === 1) ? <TeamRequests /> : <TeamList />}
            </div>
        </div>
    )
}

export default Teams
