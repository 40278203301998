import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Build from './build';
import PlayTheInfinateGame from './playTheInfinateGame';
import Question from './question';
import Report from './report';
import Research from './research';
import ScrappyDesign from './scrappyDesign';


const CheckSVG = ({ status }) => {

    return <>{
        status === 'Inprogress' ?
            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                <g id="Group_10273" data-name="Group 10273" transform="translate(-920 -367)">
                    <g id="Group_10272" data-name="Group 10272">
                        <circle id="Ellipse_527" data-name="Ellipse 527" cx="22.5" cy="22.5" r="22.5" transform="translate(920 367)" fill="#2c7be5" />
                    </g>
                    <circle id="Ellipse_531" data-name="Ellipse 531" cx="9.5" cy="9.5" r="9.5" transform="translate(933 380)" fill="#fff" />
                </g>
            </svg>
            : status === 'completed' ?
                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                    <g id="Group_10271" data-name="Group 10271" transform="translate(-405 -367)">
                        <circle id="Ellipse_525" data-name="Ellipse 525" cx="22.5" cy="22.5" r="22.5" transform="translate(405 367)" fill="#2c7be5" />
                        <path id="Icon_awesome-check" data-name="Icon awesome-check" d="M6.663,18.918.287,12.543a.981.981,0,0,1,0-1.387L1.674,9.769a.981.981,0,0,1,1.387,0l4.295,4.295,9.2-9.2a.981.981,0,0,1,1.387,0l1.387,1.387a.981.981,0,0,1,0,1.387L8.05,18.918A.981.981,0,0,1,6.663,18.918Z" transform="translate(417.383 377.609)" fill="#fff" />
                    </g>
                </svg> :
                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                    <g id="Group_10271" data-name="Group 10271" transform="translate(-405 -367)">
                        <circle id="Ellipse_525" data-name="Ellipse 525" cx="22.5" cy="22.5" r="22.5" transform="translate(405 367)" fill="#B6C1D1" />
                        <path id="Icon_awesome-check" data-name="Icon awesome-check" d="M6.663,18.918.287,12.543a.981.981,0,0,1,0-1.387L1.674,9.769a.981.981,0,0,1,1.387,0l4.295,4.295,9.2-9.2a.981.981,0,0,1,1.387,0l1.387,1.387a.981.981,0,0,1,0,1.387L8.05,18.918A.981.981,0,0,1,6.663,18.918Z" transform="translate(417.383 377.609)" fill="#fff" />
                    </g>
                </svg>
    }
    </>
}

const AddChallenge = () => {

    const [step, setStep] = useState(1);
    const [inputImage, setInputImage] = useState({})

    const navigate = useNavigate();



    const handleStep = (payload) => {
        if (step > 1 && payload === -1) {
            setStep((prev) => prev - 1)
        }
        if (step < 5 && payload === 1) {
            setStep((prev) => prev + 1)
        }
    }

    return (
        <div>
            <div className='container-fluid'>
                <div className="header">
                    <div className="header-body">
                        <div className="row">
                            <div className="col">
                                <h2 className="header-title">
                                    <i className='fe fe-arrow-left fw-bold me-3' onClick={() => { navigate(-1) }}></i>
                                    Add Challenge
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container-fluid'>
                    <div>
                        <div className="row mb-5">
                            <h3>Challenge Title *</h3>
                        </div>
                        <div className="row">
                            <div className="col">
                                <span className='fw-semibold'>
                                    English
                                </span>
                                <div className="form-floating">
                                    <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                                </div>
                            </div>
                            <div className="col">
                                <span className='fw-semibold'>
                                    Hindi
                                </span>
                                <div className="form-floating">
                                    <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                                </div>
                            </div>
                            <div className="col">
                                <span className='fw-semibold'>
                                    Marathi
                                </span>
                                <div className="form-floating">
                                    <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                                </div>
                            </div>
                            <div className="col">
                                <span className='fw-semibold'>
                                    Kannada
                                </span>
                                <div className="form-floating">
                                    <textarea className="form-control" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="row my-4">
                                <h3>Cover Image *</h3>
                            </div>

                            <div className='d-flex justify-content-between my-4 p-1 py-2' style={{ border: 'dashed 1px #D2DDEC' }}>
                                <div>{inputImage[0]?.name || 'Upload Image'}</div>
                                <label className='btn btn-primary btn-sm' htmlFor='image' >
                                    <input type='file' multiple={true} id='image' name='image' accept=' image/*' onChange={(e) => { setInputImage(e.target.files) }} /> Upload
                                </label>
                            </div>
                        </div>
                        <div>
                            <div className="row my-4">
                                <h3>Points Configuration *</h3>
                            </div>
                            <div className='row'>
                                <div className="col">
                                    <label className='col-form-label'>Scrappy Stars for Team Leader</label>
                                    <input type={'number'} className='form-control' />
                                </div>
                                <div className="col">
                                    <label className='col-form-label'>Scrappy Stars for Team Leader</label>
                                    <input type={'number'} className='form-control' />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='my-5 position-relative '>
                        <div className='d-flex justify-content-between progressbar'>
                            <div>
                                <div >
                                    <CheckSVG status={step === 1 ? 'Inprogress' : 'completed'} />
                                </div>
                                <div className='mt-3 '>
                                    <h4>Research</h4>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <CheckSVG status={step === 2 ? 'Inprogress' : step > 2 ? 'completed' : false} />
                                </div>
                                <div className='mt-3 '>
                                    <h4>Design</h4>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <CheckSVG status={step === 3 ? 'Inprogress' : step > 3 ? 'completed' : false} />
                                </div>
                                <div className='mt-3'>
                                    <h4>Build</h4>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <CheckSVG status={step === 4 ? 'Inprogress' : step > 4 ? 'completed' : false} />
                                </div>
                                <div className='mt-3'>
                                    <h4>Report</h4>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <CheckSVG status={step === 5 ? 'Inprogress' : step > 5 ? 'completed' : false} />
                                </div>
                                <div className='mt-3'>
                                    <h4>Play the infinate game</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    {step === 1 && <Research />}
                    {step === 2 && <ScrappyDesign />}
                    {step === 3 && <Build />}
                    {step === 4 && <Report />}
                    {step === 5 && <PlayTheInfinateGame />}
                </div>
                <div className='d-flex justify-content-end pb-5'>
                    <button className='btn btn-outline-primary px-5 mx-4' onClick={() => { handleStep(-1) }}>Prev</button>
                    <button className='btn btn-primary btn-md px-5' onClick={() => { handleStep(1) }}>{step < 5 ? 'Next' : 'Finish'}</button>
                </div>
            </div>
        </div >
    )
}

export default AddChallenge
