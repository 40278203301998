import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import ChallengesSubmittedTable from './challengesSubmittedTable'
import ChallengesApprovedTable from './challengeApprovedTable';

const Challenges = () => {
    const navigate = useNavigate();
    const [display, setDisplay] = useState(1)

    return (

        <div className='container-fluid'>
        <div className="header ">
            <div className="header-body">
                <div className="row align-items-center">
                    <div className="col">
                        <h2 className="header-title">
                        Challenges submissions
                        </h2>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col">
                        <ul className="nav nav-tabs nav-overflow header-tabs">
                            <li className="nav-item">
                                <Link className={`nav-link ${(display === 1) ? 'active' : ''}`} onClick={() => { setDisplay(1) }}>
                                    Active submissions
                                    {/* <span className="badge rounded-pill bg-secondary-soft">10</span> */}
                                </Link>
                            </li>
                            <li className="nav-item ">
                                <Link className={`nav-link ${(display === 2) ? 'active' : ''}`} onClick={() => { setDisplay(2) }}>
                                   Approved submissions
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className='container-fluid'>
            {(display === 1) ?  <ChallengesSubmittedTable /> : <ChallengesApprovedTable />   }
        </div>
    </div>

    )
}

export default Challenges
