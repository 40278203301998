import React from 'react'
import { useEffect, useState } from 'react'

const Pagination = ({ PageData, setDisplayData }) => {
    const [currentPage, setCurrentPage] = useState(1);
    // const [dataPerPage, setDataPerPage] = useState(5);
    const [totalPages, setTotalPages] = useState([]);
    const [pageNumbers, setPageNumbers] = useState(5);

    let dataPerPage = 10;

    useEffect(() => {
        handleTotalPages(PageData)
    }, [PageData]);


    const handleTotalPages = (data) => {
        let pages = [];
        for (let i = 1; i <= Math.ceil(data.length / dataPerPage); i++) {
            pages.push(i)
        }
        setTotalPages(pages);
    }

    useEffect(() => {
        handleDisplayData();
        setCurrentPage(1)
    }, [totalPages])

    const handlePage = (payload) => {
        if (currentPage >= 2 && payload === -1) {
            setCurrentPage((prev) => prev - 1)
        } else if (currentPage < totalPages[totalPages.length - 1] && payload === 1) {
            setCurrentPage((prev) => prev + 1)
        }
    }

    useEffect(() => {
        handleDisplayData();
        if (currentPage <= pageNumbers - 5) {
            handleShowPageNumbers(-5)
        } else if (currentPage > pageNumbers) {
            handleShowPageNumbers(5)
        }
    }, [currentPage])

    const handleShowPageNumbers = (payload) => {
        if (totalPages.length > pageNumbers && payload === 5) {
            setPageNumbers((prev) => prev + 5)
        } else if (pageNumbers > 5 && payload === -5) {
            setPageNumbers((prev) => prev - 5)
        }
    }

    const handleDisplayData = () => {
        setDisplayData(PageData.slice((currentPage * dataPerPage) - dataPerPage, currentPage * dataPerPage))

    }


    return (
        <div>
            <div className="card-footer d-flex justify-content-between">
                <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
                    <li className="page-item cursor-pointer" onClick={() => { handlePage(-1) }}>
                        <div className="page-link ps-0 pe-4 border-end ">
                            <i className="fe fe-arrow-left me-1"></i> Prev
                        </div>
                    </li>
                </ul>

                <ul className="list-pagination pagination pagination-tabs card-pagination ">
                    <li className="page-item">
                        <div className="page-link " onClick={() => handleShowPageNumbers(-5)}>
                            <i className="fe fe-chevrons-left"></i>
                        </div>
                    </li>
                    {totalPages.slice(pageNumbers - 5, pageNumbers).map((e) => (
                        <li className={`cursor-pointer ${(currentPage === e) ? 'active' : ''}`} onClick={() => { setCurrentPage(e) }} key={e}>
                            <span className='page'>{e}</span>
                        </li>))}
                    <li className="page-item">
                        <div className="page-link" onClick={() => handleShowPageNumbers(5)}>
                            <i className="fe fe-chevrons-right"></i>
                        </div>
                    </li>
                </ul>

                <ul className="list-pagination-next pagination pagination-tabs card-pagination">
                    <li className="page-item cursor-pointer" onClick={() => { handlePage(1) }} >
                        <div className="page-link ps-4 pe-0 border-start">
                            Next <i className="fe fe-arrow-right ms-1"></i>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Pagination
