import { API } from 'aws-amplify';
import React from 'react'
import { getChallengeActivity } from '../../graphql/queries';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DropdownCard from '../campaigns/DropdownCard';
import { handleGetGroupMembers } from '../../helpers/index';


const ChallengeInfoPage = () => {
    const [challengeData, setChallengeData] = useState([]);
    const { id } = useParams()
    const navigate = useNavigate();

    const handleGetSubmittedChallenge = async () => {
        try {
            let res = await API.graphql({
                query: getChallengeActivity,
                variables: {
                    id
                }
            })
            res.data.getChallengeActivity.research = JSON.parse(res?.data?.getChallengeActivity?.research);
            res.data.getChallengeActivity.build = JSON.parse(res?.data?.getChallengeActivity?.build)
            res.data.getChallengeActivity.design = JSON.parse(res?.data?.getChallengeActivity?.design)
            setChallengeData(res.data.getChallengeActivity)
            console.log('res', res.data.getChallengeActivity)
        } catch (error) {
            console.log(error)
        }
    }

    const handleUpdateActivity = () => {
        handleGetGroupMembers(challengeData)
        navigate(-1);
    }


    useEffect(() => {
        handleGetSubmittedChallenge()
    }, [])

    return (
        <div className='container-fluid'>
            <div className="header ">
                <div className="header-body">
                    <div className="row align-items-center">
                        <div className="col">
                            <h2 className="header-title">
                                <i className='fe fe-arrow-left fw-bold me-3' onClick={() => { navigate(-1) }}></i>
                                Challenge Activity Details
                            </h2>
                        </div>
                        {!challengeData?.approved &&
                            <div className='col-auto '>
                                <button className='btn btn-danger me-4'>Reject</button>
                                <button className='btn btn-success' onClick={() => {
                                    handleUpdateActivity();
                                }}>Approve</button>
                            </div>
                        }
                    </div>

                </div>
            </div>
            <DropdownCard heading='Research'>
                <div>
                    <div className='card p-3'>
                        <p className='text-muted mb-2'>Upload a map pin of where you want to build your vertical farm</p>
                        <img src={`${challengeData?.research?.mapImage}`} width={'200px'} />
                    </div>
                    <div className='card p-3'>
                        <p className='text-muted mb-2'>If it’s in your school or nearby,search for your school first {challengeData?.category === "OTHER" ? challengeData?.otherCategory : challengeData?.category} ?</p>
                        <p className='mb-2'>{challengeData?.research?.schoolId}</p>
                    </div>
                    <div className='card p-3'>
                        <p className='text-muted mb-2'>Give us good directions?</p>
                        <div className='my-2'>
                            <p className='mb-2'>{challengeData?.research?.directions}</p>
                        </div>
                    </div>
                    <div className='card p-3'>
                        <p className='text-muted mb-2'>Who we met?</p>
                        <div className='my-2'>
                            <p className='text-muted mb-2'>Name</p>
                            <p className='mb-2'>{challengeData?.research?.name}</p>
                        </div>
                        <div className='my-2'>
                            <p className='text-muted mb-2'>Age</p>
                            <p className='mb-2'>{challengeData?.research?.age}</p>
                        </div>
                        <div className='my-2'>
                            <p className='text-muted mb-2'>What they do</p>
                            <p className='mb-2'>{challengeData?.research?.whatTheyDo}</p>
                        </div>
                        <div className='my-2'>
                            <p className='text-muted mb-2'>Scrappy Skill</p>
                            <p className='mb-2'>{challengeData?.research?.skill}</p>
                        </div>
                        <div className='my-2'>
                            <p className='text-muted mb-2'>Why they’d like to have an urban vertical urban farm here to play the game</p>
                            <p className='mb-2'>{challengeData?.research?.desc1}</p>
                        </div>
                        <div className='my-2'>
                            <p className='text-muted mb-2'>Three leafy greens, vegetables, fruit they’d like us to grow & why</p>
                            <p className='mb-2'>{challengeData?.research?.desc2}</p>
                        </div>
                        <div >
                            <p className='text-muted mb-2'>Upload the drawings of three things they’d like to eat, that you’ll grow</p>
                            <div className='row row-cols-1 row-cols-lg-3 g-auto mt-3'>
                                <div className='col my-2 text-center '>
                                    <img src={`${challengeData?.research?.image1}`} width={'200px'} />
                                    <p className='mt-2'>Image-1</p>
                                </div>
                                <div className='col my-2 text-center'>
                                    <img src={`${challengeData?.research?.image2}`} width={'200px'} />
                                    <p className='mt-2'>Image-2</p>
                                </div>
                                <div className='col my-2 text-center'>
                                    <img src={`${challengeData?.research?.image3}`} width={'200px'} />
                                    <p className='mt-2'>Image-3</p>
                                </div>
                                <div className='col my-2 text-center'>
                                    <img src={`${challengeData?.research?.image4}`} width={'200px'} />
                                    <p className='mt-2'>Image-4</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DropdownCard>
            <DropdownCard heading='Design'>
                <div>
                    <div className='card p-3'>
                        <p className='text-muted mb-2'>Our farm is this big</p>
                        <div className='my-2'>
                            <p className='text-muted mb-2'>Length</p>
                            <p className='mb-2'>{challengeData?.design?.length}</p>
                        </div>
                        <div className='my-2'>
                            <p className='text-muted mb-2'>Width</p>
                            <p className='mb-2'>{challengeData?.design?.width}</p>
                        </div>
                        <div className='my-2'>
                            <p className='text-muted mb-2'>Height</p>
                            <p className='mb-2'>{challengeData?.design?.height}</p>
                        </div>
                        <div className='my-2'>
                            <p className='text-muted mb-2'>Our farm has this many levels, shelves </p>
                            <p className='mb-2'>{challengeData?.design?.shelves}</p>
                        </div>
                        <div className='my-2'>
                            <p className='text-muted mb-2'>Our farm has this many planters, pots </p>
                            <p className='mb-2'>{challengeData?.design?.pots}</p>
                        </div>
                    </div>
                    <div className='my-4 p-3 border rounded'>
                        <p className='text-muted mb-2'>The structure of our farm is made of these sustainable materials</p>
                        <div className='my-2'>
                            <p className='text-muted mb-2'>Sky Farm Construction</p>
                            <p className='mb-2'>{challengeData?.design?.desc1}</p>
                        </div>
                        <div className='my-2'>
                            <p className='text-muted mb-2'>Pot, planter, hanging basket</p>
                            <p className='mb-2'>{challengeData?.design?.desc2}</p>
                        </div>
                    </div>
                    <div className='my-4 p-3 border rounded'>
                        <p className='text-muted mb-2'>The structure of our farm is made of these not sustainable but upcycled materials</p>
                        <div className='my-2'>
                            <p className='text-muted mb-2'>Sky Farm Construction</p>
                            <p className='mb-2'>{challengeData?.design?.desc3}</p>
                        </div>
                        <div className='my-2'>
                            <p className='text-muted mb-2'>Pot, planter, hanging basket</p>
                            <p className='mb-2'>{challengeData?.design?.desc4}</p>
                        </div>
                    </div>
                    <div className='my-4 p-3 border rounded'>
                        <p className='text-muted mb-2'>Veggies by season</p>
                        {challengeData?.design?.vegetables?.map((vegetable) => (
                            <div>
                                <div className='my-2'>
                                    <p className='text-muted mb-2'>Name of the vegetable</p>
                                    <p className='mb-2'>{vegetable?.name}</p>
                                </div>
                                <div className='my-2'>
                                    <p className='text-muted mb-2'>Months</p>
                                    <p className='mb-2'>{vegetable?.month}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='my-4 p-3 border rounded'>
                        <p className='text-muted mb-2'>Fruits by season</p>
                        {challengeData?.design?.fruits?.map((fruit) => (
                            <div>
                                <div className='my-2'>
                                    <p className='text-muted mb-2'>Name of the vegetable</p>
                                    <p className='mb-2'>{fruit?.name}</p>
                                </div>
                                <div className='my-2'>
                                    <p className='text-muted mb-2'>Months</p>
                                    <p className='mb-2'>{fruit?.month}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </DropdownCard>
            <DropdownCard heading='Build'>
                <div>
                    <div className='my-4 p-3 border rounded'>
                        <p className='text-muted mb-2'>Have you built your farm?</p>
                        <div className='my-2'>
                            <p className='text-muted mb-2'>Uploaded Image</p>
                            <img src={`${challengeData?.build?.images}`} width={'200px'} />
                        </div>
                        <div className='my-2'>
                            <p className='text-muted mb-2'>Field Notes</p>
                            <video controls width="250">
                                <source src={`${challengeData?.build?.video}`} type="video/webm" />
                            </video>
                        </div>
                    </div>
                    <div className='border rounded p-3 my-3'>
                        <p className='text-muted mb-2'>Our Vertical Form</p>
                        <div className='row row-cols-1 row-cols-lg-3 g-auto mt-3'>
                            <div className='col my-2 text-center '>
                                <img src={`${challengeData?.build?.farmFile1}`} width={'200px'} height={'200px'} />
                                <p className='mt-2'>Image</p>
                            </div>
                            <div className='col my-2 text-center'>
                                <img src={`${challengeData?.build?.farmFile2}`} width={'200px'} height={'200px'} />
                                <p className='mt-2'>Fiel Notes</p>
                            </div>
                        </div>
                    </div>
                    <div className='border rounded p-3 my-3'>
                        <p className='text-muted mb-2'>Each Level/Stages</p>
                        <div className='row row-cols-1 row-cols-lg-3 g-auto mt-3'>
                            <div className='col my-2 text-center '>
                                <img src={`${challengeData?.build?.stageFile1}`} width={'200px'} height={'200px'} />
                                <p className='mt-2'>Image</p>
                            </div>
                            <div className='col my-2 text-center'>
                                <img src={`${challengeData?.build?.stageFile2}`} width={'200px'} height={'200px'} />
                                <p className='mt-2'>Fiel Notes</p>
                            </div>
                        </div>
                    </div>
                    <div className='border rounded p-3 my-3'>
                        <p className='text-muted mb-2'>Upcycled Materials</p>
                        <div className='row row-cols-1 row-cols-lg-3 g-auto mt-3'>
                            <div className='col my-2 text-center '>
                                <img src={`${challengeData?.build?.materialFile1}`} width={'200px'} height={'200px'} />
                                <p className='mt-2'>Image</p>
                            </div>
                            <div className='col my-2 text-center'>
                                <img src={`${challengeData?.build?.materialFile2}`} width={'200px'} height={'200px'} />
                                <p className='mt-2'>Fiel Notes</p>
                            </div>
                        </div>
                    </div>
                    <div className='border rounded p-3 my-3'>
                        <p className='text-muted mb-2'>Other sustainable things we designed </p>
                        <div className='row row-cols-1 row-cols-lg-3 g-auto mt-3'>
                            <div className='col my-2 text-center '>
                                <img src={`${challengeData?.build?.sustainableFile1}`} width={'200px'} height={'200px'} />
                                <p className='mt-2'>Image</p>
                            </div>
                            <div className='col my-2 text-center'>
                                <img src={`${challengeData?.build?.sustainableFile2}`} width={'200px'} height={'200px'} />
                                <p className='mt-2'>Fiel Notes</p>
                            </div>
                        </div>
                    </div>
                    <div className='border rounded p-3 my-3'>
                        <p className='text-muted mb-2'>When we’ll have our first sky farm crop </p>
                        <div className='row row-cols-1 row-cols-lg-3 g-auto mt-3'>
                            <div className='col my-2 text-center '>
                                <img src={`${challengeData?.build?.cropFile1}`} width={'200px'} height={'200px'} />
                                <p className='mt-2'>Image</p>
                            </div>
                            <div className='col my-2 text-center'>
                                <img src={`${challengeData?.build?.cropFile2}`} width={'200px'} height={'200px'} />
                                <p className='mt-2'>Fiel Notes</p>
                            </div>
                        </div>
                    </div>
                </div>
            </DropdownCard>

        </div>

    )
}

export default ChallengeInfoPage
