import React from 'react'

const PlayTheInfinateGame = () => {
    return (
        <div>
            <div className='card p-4'>
                <div>
                    <div className='d-flex justify-content-between mb-5'>
                        <h3 className='m-0'>Play the infinite game </h3>
                    </div>
                </div>
                <div>
                    <div className='d-flex justify-content-between w-50'>
                        <div className='primary'>
                            <h3>Upload Images</h3>
                        </div>
                        <div className="form-check form-switch">
                            <input className="form-check-input bg-danger" type="checkbox" role="switch" />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default PlayTheInfinateGame
