import React from 'react'

import { useState } from 'react';



const DropdownCard = ({ heading, children, bgColor }) => {
    const [show, setShow] = useState(false);

    return (<>
        <div className='card'>
            <div className='card-header cursor-pointer' onClick={() => { setShow(!show) }} >
                <div><h3 className='m-0'>{heading}</h3></div>
                <i className={`fe fe-chevron-${!show ? 'down' : 'up'} fs-1`}></i>
            </div>
            {show &&
                <div className='card-body'>
                    {children}
                </div>}
        </div>
        {/* <div className='border w-25'>
            <div className='nav-item'>
                <a className='nav-link  collapsed' href='#Challenge-Management' data-bs-toggle='collapse' role='button' aria-expanded='false' aria-controls='Challenge-Management'>
                    <i className='fe fs-2'> </i> Challenge Management
                </a>
                <div className='collapse ' id='Challenge-Management'>
                    <div className='nav nav-lg flex-column'>
                        <div className='nav-item'>
                            Something
                        </div>
                        <div className='nav-item'>
                            Manage Campaigns
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
    </>
    )
}

export default DropdownCard
