import React from 'react'
import { useNavigate } from 'react-router'
import CustomCarousel from './customCarousel'
import { PostData } from '../data/postData'
import { createActivityFeed, updateChallengeActivity } from '../graphql/mutations';
import { toast } from 'react-toastify';
import { API } from 'aws-amplify';

const UnPublishedCard = ({ item }) => {

    const navigate = useNavigate();

    const handleCreateActivity = async () => {
        try {
            let response = await API.graphql({
                query: "createActivityFeed",
                variables: {
                    input: {
                        challengeId: item.challengeId,
                        studentgroupId: item.studentgroupId,
                        userId: item.studentId,
                        type: 'STUDENT',
                        content: item.content

                    }
                }
            });

            toast.success('Posted', {
                position: toast.POSITION.TOP_RIGHT
            }
            )
            handleUpdateChallengeActivity()
        } catch (error) {
            console.log(error)
        }
    }

    const handleUpdateChallengeActivity = async () => {
        try {
            let response = await API.graphql({
                query: updateChallengeActivity,
                variables: {
                    input: {
                        id: item.id,
                        status: 'APPROVED'
                    }
                }
            })
        } catch (error) {
            console.log(error);
        }
    }

    const setBg = () => {
        let backgroundImage = ['card_header-1.png', 'card_header-2.png']
        const randomImage = Math.floor(Math.random() * backgroundImage.length)

        return backgroundImage[randomImage]
    }

    return (
        <>
            <div className="card">
                <div className='card-header text-center bg-cover' style={{ backgroundImage: `url(/img/${setBg()})` }}>
                    <h3 className='mb-0'>{item?.challenge?.name}</h3 >
                </div>
                <div className="card-body">
                    <div className="mb-3">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <div className="avatar">
                                    <img src={'https://cdn2.psychologytoday.com/assets/styles/manual_crop_1_91_1_1528x800/public/2020-08/shutterstock_1731284125_0.jpg?itok=89UrdUt_'} alt="..." className="avatar-img rounded-circle" />
                                </div>
                            </div>
                            <div className="col ms-n2">
                                <h4 className="mb-1">
                                    {item?.studentGroup?.name}
                                    {/* {post.team_name} */}
                                </h4>
                                <p className="card-text small text-muted">
                                    {/* Some public School */}
                                    {/* <span className="fe fe-clock"></span> */}
                                    {/* <time dateTime="2018-05-24">4hr ago</time> */}
                                    {item.school?.name}
                                </p>
                            </div>
                            <div className="col-auto">
                                <div className="dropdown">
                                    <div className="dropdown-ellipses dropdown-toggle text-mute" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fe fe-more-vertical"></i>
                                    </div>
                                    <div className="dropdown-menu dropdown-menu-end">
                                        <div className="dropdown-item" onClick={handleCreateActivity}>
                                            Publish
                                        </div>
                                        <div className="dropdown-item" onClick={() => {
                                            navigate(`/activity-feed/edit-post/${item.id}`)
                                        }}>
                                            Edit
                                        </div>
                                        <div className="dropdown-item">
                                            Delete
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    {/* {post.posts.length <= 1 ?
                        <div >
                            <p className="mb-3">
                                I've been working on shipping the latest version of Launchday. The story I'm trying to focus on is something like "You're launching soon and need to be 100% focused on your product. Don't lose precious days designing, coding, and testing a product site. Instead, build one in minutes."
                            </p>

                            <p className="mb-4">
                                What do you y'all think? Would love some feedback from <a href="#!" className="badge bg-primary-soft">@Ab</a> or <a href="#!" className="badge bg-primary-soft">@Adolfo</a>?
                            </p>
                            <div className="text-center p-5">
                                <img src={post.posts[0].image} alt="..." className="img-fluid rounded w-100" />
                            </div>
                        </div> : */}
                    <CustomCarousel item={item} />
                    {/* } */}





                </div>
            </div >

        </>
    )
}

export default UnPublishedCard;
