import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { UserTableData } from '../../data/userData';

import { Formik } from 'formik';
import * as Yup from 'yup';
import Pagination from '../../components/pagination';
import { API } from 'aws-amplify';
import Select from 'react-select';
import { listUsersWithSchools } from '../../customGraphql/queries';
import { listSchools } from '../../graphql/queries';

const UserTable = () => {
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [schoolOptions, setSchoolOptions] = useState([]);
    const [show, setShow] = useState(false);
    const [modalData, setModalData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        schools: []
    });


    const formSchema = Yup.object().shape({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email'),
        phone: Yup.string().min(10, "Phone number is not valid").max(10, "Phone number is not valid").required('Required'),
        role: Yup.string().nullable().required('Required'),
        schools: Yup.array()
    })

    const handleClose = () => { setShow(false) }
    const handleShow = () => setShow(true);

    useEffect(() => {
        handleSearchResult(UserTableData)
    }, [searchInput])

    useEffect(() => {
        handleListUsers()
    }, [])

    const handleListUsers = async () => {
        try {
            let res = await API.graphql({
                query: listUsersWithSchools,
                variables: {
                    filter: { role: { ne: 'STUDENT' } }
                }
            })
            setUserData(res.data.listUsers.items)
        } catch (error) {
            console.log('err', error)
        }
    }

    const handleSearchResult = (data) => {
        let result = data.filter(item =>
            item.name.toLowerCase().includes(searchInput) ||
            item.email.toLowerCase().includes(searchInput))
        setFilteredData(result)

    }

    const handleSearchInput = ({ target }) => {
        setSearchInput(target.value.toLowerCase())
    }

    const handleListSchools = async () => {
        try {
            let res = await API.graphql({
                query: listSchools,
                variables: {
                    filter: { approved: { eq: true } }
                }
            })
            let options = res?.data?.listSchools?.items.map((school) => ({ value: school?.id, label: school?.name }));
            setSchoolOptions(options)
        } catch (error) {
            console.log(error)
        }
    }


    const handleModalData = (user) => {
        handleListSchools();
        let userCopy = { ...user }
        userCopy.phone = userCopy.phone.slice(3);
        userCopy.schools = userCopy?.schools?.items?.map((item) => ({ value: item?.school?.id, label: item?.school?.name }))
        setModalData(userCopy)
    }

    const handleformData = (form) => {
        console.log('form', form)
        setModalData('')
        handleClose()

    }


    return (
        <div>
            <div className='card' id='contactsList'>
                <div className='card-header'>
                    <div className='row align-items-center'>
                        <div className='col'>
                            <form>
                                <div className='input-group input-group-flush input-group-merge input-group-reverse'>
                                    <input className='form-control list-search' type='search' placeholder='Search' onChange={handleSearchInput} />
                                    <span className='input-group-text'>
                                        <i className='fe fe-search'></i>
                                    </span>
                                </div>
                            </form>
                        </div>
                        <div className='col-auto'>
                            <div className='dropdown'>
                                <button className='btn btn-sm btn-white' type='button' data-bs-toggle='dropdown' data-bs-auto-close='outside' aria-haspopup='true' aria-expanded='false'>
                                    <i className='fe fe-sliders me-1'></i> Filter <span className='badge bg-primary ms-1 d-none'>0</span>
                                </button>
                                <form className='dropdown-menu dropdown-menu-end dropdown-menu-card'>
                                    <div className='card-header'>
                                        <h4 className='card-header-title'>
                                            Filters
                                        </h4>
                                        <button className='btn btn-sm btn-link text-reset d-none' type='reset'>
                                            <small>Clear filters</small>
                                        </button>
                                    </div>
                                    <div className='card-body'>
                                        <div className='list-group list-group-flush mt-n4 mb-4'>
                                            <div className='list-group-item'>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <small>Title</small>
                                                    </div>
                                                    <div className='col-auto'>
                                                        <div className='choices' data-type='select-one' tabIndex='0' role='listbox' aria-haspopup='true' aria-expanded='false'>
                                                            <select className='form-select form-select-sm form-control' name='item-title' data-choices='{&quot;searchEnabled&quot;: false}' hidden='' tabIndex='-1' data-choice='active'>
                                                                <option value='*' data-custom-properties='[object Object]'>Any</option>
                                                                <option value='*' data-custom-properties='[object Object]'>Designer</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='list-group-item'>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <small>Lead scrore</small>
                                                    </div>
                                                    <div className='col-auto'>
                                                        <div className='choices' data-type='select-one' tabIndex='0' role='listbox' aria-haspopup='true' aria-expanded='false'>
                                                            <select className='form-select form-select-sm form-control' name='item-title' data-choices='{&quot;searchEnabled&quot;: false}' hidden='' tabIndex='-1' data-choice='active'>
                                                                <option value='*' data-custom-properties='[object Object]'>Any</option>
                                                                <option value='*' data-custom-properties='[object Object]'>Designer</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button className='btn w-100 btn-primary' type='submit'>
                                            Apply filter
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='table-responsive'>
                    <table className='table table-sm table-hover table-nowrap card-table'>
                        <thead >
                            <tr className='align-middle text-center'>
                                <th >
                                    <div className='text-muted '>Full Name</div>
                                </th>
                                <th >
                                    <div className='text-muted '>Email ID</div>
                                </th>
                                <th>
                                    <div className='text-muted' >Phone No</div>
                                </th>
                                <th>
                                    <div className='text-muted' >Role</div>
                                </th>
                                <th>
                                    <div className='text-muted' >Action</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className='list fs-base'>
                            {userData
                                .map((user) => {
                                    return (
                                        <tr key={user.id}>
                                            <td>
                                                <div className='text-center'>{user.firstName} {user.lastName}</div>
                                            </td>
                                            <td>
                                                <div className='text-center'>
                                                    {user.email || ''}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-center'>
                                                    {user.phone}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-center'>{user.role || 'School Admin'}</div>
                                            </td>
                                            <td className='text-center text-muted'>
                                                {/* <div className='item-score badge bg-success-soft '>{e.status ? 'active' : 'inactive'}</div> */}
                                                <div className='dropdown'>
                                                    <div className='dropdown-ellipses dropdown-toggle text-mute' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                                                        <i className='fe fe-more-vertical'></i>
                                                    </div>
                                                    <div className='dropdown-menu dropdown-menu-end'>
                                                        <div className='dropdown-item' onClick={() => { handleModalData(user); handleShow() }}>
                                                            Edit
                                                        </div>
                                                        <div className='dropdown-item'>
                                                            Delete
                                                        </div>
                                                    </div>

                                                </div>
                                            </td>
                                        </tr>

                                    )
                                })}

                        </tbody>
                    </table>
                </div>
                <Pagination PageData={filteredData} setDisplayData={setDisplayData} />
                {/* <div className="card-footer d-flex justify-content-between">
                    <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
                        <li className="page-item" onClick={() => { handlePage(-1) }}>
                            <div className="page-link ps-0 pe-4 border-end">
                                <i className="fe fe-arrow-left me-1"></i> Prev
                            </div>
                        </li>
                    </ul>
                    <ul className="list-pagination pagination pagination-tabs card-pagination">
                        {totalPages.map((e) => (
                            <li className={(currentPage === e) ? 'active' : ''} onClick={() => { setCurrentPage(e) }} key={e}>
                                <span className='page'>{e}</span>
                            </li>))}
                    </ul>
                    <ul className="list-pagination-next pagination pagination-tabs card-pagination">
                        <li className="page-item" onClick={() => { handlePage(1) }} >
                            <div className="page-link ps-4 pe-0 border-start">
                                Next <i className="fe fe-arrow-right ms-1"></i>
                            </div>
                        </li>
                    </ul>
                </div> */}
            </div>
            <Modal show={show} onHide={handleClose} >
                <Modal.Header>
                    <h2 className='m-0'>Edit User</h2>
                </Modal.Header>
                <Modal.Body>
                    <Formik enableReinitialize={true} initialValues={modalData} validationSchema={formSchema} onSubmit={handleformData}>
                        {({ setFieldValue, handleChange, handleSubmit, values, errors }) => (
                            <form onSubmit={handleSubmit}>
                                <div className='row mb-3 form-group'>
                                    <div className='col'>
                                        <label htmlFor='firstName' className='form-label'>First Name</label>
                                        <input type={'text'} className='form-control' id='firstName' value={values.firstName} onChange={handleChange} />
                                        <span className='text-danger'>{errors.firstName}</span>
                                    </div>
                                    <div className='col'>
                                        <label htmlFor='lastName' className='form-label'>Last Name</label>
                                        <input type={'text'} className='form-control' id='lastName' value={values.lastName} onChange={handleChange} />
                                        <span className='text-danger'>{errors.lastName}</span>
                                    </div>
                                </div>
                                <div className='mb-3 form-group'>
                                    <label htmlFor='email' className='form-label'>Email address</label>
                                    <input type={'text'} className='form-control' id='email' name={'email'} value={values.email} onChange={handleChange} />
                                    <span className='text-danger'>{errors.email}</span>
                                </div>
                                <div className='mb-3 form-group'>
                                    <label htmlFor='phone' className='form-label'>Phone Number</label>
                                    <input type={'text'} className='form-control' id='phone' name={'phone'} value={values.phone} onChange={handleChange} />
                                    <span className='text-danger'>{errors.phone}</span>
                                </div>
                                <div className='mb-3 form-group'>
                                    <label htmlFor='role' className='form-label'>Role</label>
                                    <select className='form-control' as='select' name='role' value={values.role || 'SCHOOL_ADMIN'} onChange={handleChange}>
                                        <option>Select Role</option>
                                        <option value='ADMIN'>Admin</option>
                                        <option value='SCHOOL_ADMIN'>School Admin</option>
                                    </select>
                                </div>
                                {values.role === null &&
                                    <div className='col'>
                                        <label htmlFor='medium' className='form-label'>Schools <span className='text-danger'>*</span></label>
                                        <Select
                                            isMulti
                                            id='schools'
                                            options={schoolOptions}
                                            defaultValue={values.schools}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={(e) => { setFieldValue('schools', e) }}
                                        />
                                        <span className='text-danger'>{errors.medium}</span>
                                    </div>}
                                <div className='mt-5'>
                                    <div className='d-flex justify-content-end gap-3'>
                                        <button type='button' className='btn btn-white' onClick={() => setShow(false)}>Cancel</button>
                                        <button type='submit' className='btn btn-primary' >Submit</button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default UserTable
