import React from 'react'
import { useState } from 'react';
import { Storage } from 'aws-amplify'


const Question = ({ item, handleChange, handleRemoveQuestion }) => {

    const handleInput = (e, type, option) => {
        if (type && option) {
            item.question[type] = item.question[type] || {}
            item.question[type][option] = item.question[type][option] || {}
            item.question[type][option][e.target.name] = e.target.value;
        } else if (type && !option) {
            item.question[type] = item.question[type] || {}
            item.question[type][e.target.name] = e.target.value;
        } else {
            item.question[e.target.name] = e.target.value;
        }
        handleChange(item)
    }

    const handleOptions = (e) => {
        item[e.target.name] = e.target.value;
        if (e.target.name === 'question_type') {
            item.question = {}
        } else if (e.target.name === 'answer_type' && item.question.multiple) {
            delete item.question.multiple
        }
        handleChange(item)
    }

    const handleUploadImage = async (file) => {
        const stored = await Storage.put(`challenge-${Math.random().toString(36).substring(2, 15)}.${file.name.split('.')[1]}`, file, { contentType: file.type });
        const url = await Storage.get(stored.key, { level: 'public' })
        let ImageUrl = url.split('?')[0]
        item.question.image = ImageUrl
        handleChange(item)
    }


    return (
        <div className='mb-5'>
            <div className='d-flex justify-content-between mb-3'>
                <h3 className='m-0'>Question No {item.question_no}</h3>
                <div className='d-flex '>
                    <select defaultValue={item.question_type} className="form-select form-select-sm" name={'question_type'} onChange={handleOptions}>
                        <option value='0' >Question Type</option>
                        <option value={'normal'}>Normal Question</option>
                        <option value="image">Question With Image</option>
                        <option value="heading">Question With Heading</option>
                    </select>
                    <select defaultValue={item.answer_type} className="form-select form-select-sm mx-3" name={'answer_type'} onChange={handleOptions}>
                        <option value='0' >Answer Type</option>
                        <option value="short">Short Answer</option>
                        <option value="long">Long Answer</option>
                        <option value="multiple">Multiple Choice</option>
                        <option value="image">Image Upload</option>
                    </select>
                    <div className='d-flex justify-content-center align-items-center badge bg-danger-soft' onClick={handleRemoveQuestion}>
                        <i className='fe fe-trash-2 fs-2 text-danger'></i>
                    </div>
                </div>
            </div>
            {item.question_type === 'normal' &&
                <div className="row">
                    <div className="col">
                        <div>
                            <textarea className='form-control' rows='2' placeholder='English' name={'english'} onChange={handleInput}></textarea>
                        </div>
                    </div>
                    <div className="col">
                        <div>
                            <textarea className='form-control' rows='2' placeholder='Hindi' name={'hindi'} onChange={handleInput}></textarea>
                        </div>
                    </div>
                    <div className="col">
                        <div>
                            <textarea className='form-control' rows='2' placeholder='Marathi' name={'marathi'} onChange={handleInput}></textarea>
                        </div>
                    </div>
                    <div className="col">
                        <div>
                            <textarea className='form-control' rows='2' placeholder='Kannada' name={'kannada'} onChange={handleInput}></textarea>
                        </div>
                    </div>
                </div>}

            {item.question_type === 'image' &&
                (<div>
                    <div className='d-flex justify-content-between my-4 p-1 py-2' style={{ border: 'dashed 1px #D2DDEC' }}>
                        <div>{item?.question?.image || ' Upload Image'}</div>
                        <label className='btn btn-primary btn-sm' htmlFor='upload_image' >
                            <input type='file' id='upload_image' accept=' image/*' onChange={(e) => { handleUploadImage(e.target.files[0]) }} /> Upload
                        </label>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div>
                                <textarea className='form-control' rows='2' placeholder='English' name={'english'} onChange={handleInput}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <div>
                                <textarea className='form-control' rows='2' placeholder='Hindi' name={'hindi'} onChange={handleInput}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <div>
                                <textarea className='form-control' rows='2' placeholder='Marathi' name={'marathi'} onChange={handleInput}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <div>
                                <textarea className='form-control' rows='2' placeholder='Kannada' name={'kannada'} onChange={handleInput}></textarea>
                            </div>
                        </div>
                    </div>
                </div>)}
            {item.question_type === 'heading' &&
                (<div>
                    <div>
                        <h4 className='mt-4'>Heading </h4>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div>
                                <textarea className='form-control' rows='2' placeholder='English' name={'english'} onChange={(e) => { handleInput(e, 'heading') }}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <div>
                                <textarea className='form-control' rows='2' placeholder='Hindi' name={'hindi'} onChange={(e) => { handleInput(e, 'heading') }}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <div>
                                <textarea className='form-control' rows='2' placeholder='Marathi' name={'marathi'} onChange={(e) => { handleInput(e, 'heading') }}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <div>
                                <textarea className='form-control' rows='2' placeholder='Kannada' name={'kannada'} onChange={(e) => { handleInput(e, 'heading') }}></textarea>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h4 className='mt-4'>Sub Heading </h4>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div>
                                <textarea className='form-control' rows='2' placeholder='English' name={'english'} onChange={(e) => { handleInput(e, 'subheading') }}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <div>
                                <textarea className='form-control' rows='2' placeholder='Hindi' name={'hindi'} onChange={(e) => { handleInput(e, 'subheading') }}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <div>
                                <textarea className='form-control' rows='2' placeholder='Marathi' name={'marathi'} onChange={(e) => { handleInput(e, 'subheading') }}></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <div>
                                <textarea className='form-control' rows='2' placeholder='Kannada' name={'kannada'} onChange={(e) => { handleInput(e, 'subheading') }}></textarea>
                            </div>
                        </div>
                    </div>
                </div>)
            }

            <div className='mt-4'>
                <div className='mb-3'>
                    <h3 className='m-0'>Answer Type: {item.answer_type === 'multiple' && 'Multiple Choise'}{item.answer_type === 'short' && 'Short Answer'}{item.answer_type === 'long' && 'Long Answer'}{item.answer_type === 'image' && 'Image Upload'}</h3>
                </div>
                {item.answer_type === 'multiple' && (<div>
                    <div className='d-flex mb-3'>
                        <div className='d-inline-flex align-items-center me-3'>
                            <h3>A)</h3>
                        </div>
                        <div className="row flex-grow-1">
                            <div className="col">
                                <div>
                                    <textarea className='form-control ' data-autosize='' rows='2' placeholder='English' name={'english'} onChange={(e) => { handleInput(e, 'multiple', 'a') }}></textarea>
                                </div>
                            </div>
                            <div className="col">
                                <div>
                                    <textarea className='form-control ' data-autosize='' rows='2' placeholder='Hindi' name={'hindi'} onChange={(e) => { handleInput(e, 'multiple', 'a') }}></textarea>
                                </div>
                            </div>
                            <div className="col">
                                <div>
                                    <textarea className='form-control ' data-autosize='' rows='2' placeholder='Marathi' name={'marathi'} onChange={(e) => { handleInput(e, 'multiple', 'a') }}></textarea>
                                </div>
                            </div>
                            <div className="col">
                                <div>
                                    <textarea className='form-control ' data-autosize='' rows='2' placeholder='Kannada' name={'kannada'} onChange={(e) => { handleInput(e, 'multiple', 'a') }}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex mb-3'>
                        <div className='d-inline-flex align-items-center me-3'>
                            <h3>B)</h3>
                        </div>
                        <div className="row flex-grow-1">
                            <div className="col">
                                <div>
                                    <textarea className='form-control ' data-autosize='' rows='2' placeholder='English' name={'english'} onChange={(e) => { handleInput(e, 'multiple', 'b') }}></textarea>
                                </div>
                            </div>
                            <div className="col">
                                <div>
                                    <textarea className='form-control ' data-autosize='' rows='2' placeholder='Hindi' name={'hindi'} onChange={(e) => { handleInput(e, 'multiple', 'b') }}></textarea>
                                </div>
                            </div>
                            <div className="col">
                                <div>
                                    <textarea className='form-control ' data-autosize='' rows='2' placeholder='Marathi' name={'marathi'} onChange={(e) => { handleInput(e, 'multiple', 'b') }}></textarea>
                                </div>
                            </div>
                            <div className="col">
                                <div>
                                    <textarea className='form-control ' data-autosize='' rows='2' placeholder='Kannada' name={'kannada'} onChange={(e) => { handleInput(e, 'multiple', 'b') }}></textarea>
                                </div>
                            </div>
                        </div>
                    </div><div className='d-flex mb-3'>
                        <div className='d-inline-flex align-items-center me-3'>
                            <h3>C)</h3>
                        </div>
                        <div className="row flex-grow-1">
                            <div className="col">
                                <div>
                                    <textarea className='form-control ' data-autosize='' rows='2' placeholder='English' name={'english'} onChange={(e) => { handleInput(e, 'multiple', 'c') }}></textarea>
                                </div>
                            </div>
                            <div className="col">
                                <div>
                                    <textarea className='form-control ' data-autosize='' rows='2' placeholder='Hindi' name={'hindi'} onChange={(e) => { handleInput(e, 'multiple', 'c') }}></textarea>
                                </div>
                            </div>
                            <div className="col">
                                <div>
                                    <textarea className='form-control ' data-autosize='' rows='2' placeholder='Marathi' name={'marathi'} onChange={(e) => { handleInput(e, 'multiple', 'c') }}></textarea>
                                </div>
                            </div>
                            <div className="col">
                                <div>
                                    <textarea className='form-control ' data-autosize='' rows='2' placeholder='Kannada' name={'kannada'} onChange={(e) => { handleInput(e, 'multiple', 'c') }}></textarea>
                                </div>
                            </div>
                        </div>
                    </div><div className='d-flex mb-3'>
                        <div className='d-inline-flex align-items-center me-3'>
                            <h3>D)</h3>
                        </div>
                        <div className="row flex-grow-1">
                            <div className="col">
                                <div>
                                    <textarea className='form-control ' data-autosize='' rows='2' placeholder='English' name={'english'} onChange={(e) => { handleInput(e, 'multiple', 'd') }}></textarea>
                                </div>
                            </div>
                            <div className="col">
                                <div>
                                    <textarea className='form-control ' data-autosize='' rows='2' placeholder='Hindi' name={'hindi'} onChange={(e) => { handleInput(e, 'multiple', 'd') }}></textarea>
                                </div>
                            </div>
                            <div className="col">
                                <div>
                                    <textarea className='form-control ' data-autosize='' rows='2' placeholder='Marathi' name={'marathi'} onChange={(e) => { handleInput(e, 'multiple', 'd') }}></textarea>
                                </div>
                            </div>
                            <div className="col">
                                <div>
                                    <textarea className='form-control ' data-autosize='' rows='2' placeholder='Kannada' name={'kannada'} onChange={(e) => { handleInput(e, 'multiple', 'd') }}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
            </div>
            {item.answer_type === 'image' &&
                (<div className='mt-4'>
                    <div className='w-25'>
                        <label for='image'>Upload Count</label>
                        <input type={'number'} id='image' className='form-control' name={'upload_count'} onChange={(e) => { handleInput(e) }} />
                    </div>
                </div>)}
        </div>
    )
}

export default Question
