import React from 'react'
import PublishedCard from '../../components/publishedCard'
import { PostData } from '../../data/postData'

const SchoolPosts = () => {
    return (
        <div className='mt-5'>
            {/* {PostData.map((post) => {
                return (<PublishedCard post={post} key={post.id} />)
            })} */}
        </div>
    )
}

export default SchoolPosts
