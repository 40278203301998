import { createContext, useState } from "react";

export const AuthContext = createContext();


export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState({ schools: [] });

    const handleUser = ({ id, role, schools }) => {
        if (id) {
            setUser((prev) => ({ ...prev, id }))
        }
        if (role) {
            setUser((prev) => ({ ...prev, role }))
        }
        if (schools) {
            setUser((prev) => ({ ...prev, schools }))
        }

    }

    console.log('test-2', user)

    return (
        <AuthContext.Provider value={{ user, handleUser }}>
            {children}
        </AuthContext.Provider>

    )
}